import React, { useEffect, useState } from "react";

// import "antd/dist/antd.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import { useDispatch, useSelector } from "react-redux";

import { FaTrash } from "react-icons/fa6";
import { InputGroup } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { fetchAsyncGetVendor } from "redux/vendorSlice";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";
import ClientProjectSelect from "components/ClientProjectSelect";
import { Box } from "@chakra-ui/react";


const GstInfoComponent = ({ getData, autoCount,editData,deleteMultipleGstItem, gstData,taxableAmount, expenseType ,isEdit, role}) => {
    const [addTogglePaymentIdx, setAddTogglePaymentIdx] = useState(true);
    const [companyTypeOption, setCompanyTypeOptions] = useState([]);
    const [oldCompanyName, setOldCompanyName] = useState("")
    const [gstType, setGstType] = useState("");
    const [gstPercentage, setGstPercentage] = useState(0);

    const [baseAmount, setBaseAmount] = useState("");

    const [companyTypeValue, setCompanyTypeValue] = useState([]);
    const [gstNumber, setGstNumber] = useState("");
    const [gstAmount , setGstAmount] = useState({
        cgst:'',
        sgst:'',
        igst:''
      })


  const dispatch = useDispatch(); 
  const vendorList = useSelector((state) => state.vendorData);

  useEffect(() => {
    dispatch(fetchAsyncGetVendor());
  }, []);

 

  const getCompanyByID = async(id)=>{
    await dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{

    if(res?.payload){
      const vendor = res?.payload?.vendor;
      const setData = {
        label: vendor.companyName,
        value: vendor?._id,
      };
            
      setCompanyTypeValue(setData);
      setGstNumber(vendor?.gstNo)
    }
    }).catch((err)=>{
      console.log(err)
    })
  }
  useEffect(()=>{
    if(gstData?._id){

      const {companyName,companyId,gstNumber,gstAmount,baseAmount, gstType, gstPercentage} = gstData?._id;
        if(companyId){
          getCompanyByID(companyId)
        }
        else if(companyName){
          const setData = {
            label: companyName,
            value: companyName,
          };
                
          setCompanyTypeValue(setData);
          setGstNumber(gstNumber)
        }
        if(companyName){
          setOldCompanyName(companyName)
        }
                
        setBaseAmount(baseAmount)
        setGstPercentage(gstPercentage)
        setGstType(gstType)
        setGstAmount(gstAmount)
    }
    else{
      setBaseAmount(taxableAmount)
    }

  },[gstData?._id || taxableAmount])

  const handleOnChange = async(baseAmt,company,gst,amount)=>{
    const data = {
        baseAmount: baseAmt,
        companyName: company,
        gstNumber:gstNumber,
        gstType:gstType,
        gstPercentage: gstPercentage,
        gstAmount: {
          cgst: amount?.cgst,
          sgst: amount?.sgst,
          igst: amount?.igst

        },
      };
      getData(data);
    }

    const getAllCompanyOptions = async()=>{
      let filteredVendors;
      if(expenseType === "resource"){
        filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);
      }
      else{
        filteredVendors = vendorList.filter(vendor => vendor.isVendor === true);

      }

        const companyOption = await clientProjectOptions('companyName','_id',filteredVendors) 
        setCompanyTypeOptions(companyOption);
    }

    useEffect(()=>{
      if(vendorList){
        getAllCompanyOptions()
      }
    },[vendorList])

 

  

  
 
 
    const isValidObjectId = (id) => {
      return /^[a-f0-9]{24}$/.test(id);
  };
  useEffect(() => {
  
    if(companyTypeValue && companyTypeValue?.value){
      const id = companyTypeValue?.value;
      if(isValidObjectId(id)){

        dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{
  
          if(res?.payload){
            const {gstNo} = res?.payload?.vendor;  
            setGstNumber(gstNo)
          }
          }).catch((err)=>{
            console.log(err)
          })
      }
      }
  }, [companyTypeValue]);

  const calculateGstAmount = (type, percentage) => {
    const baseAmt = Number(baseAmount);
    if (baseAmt && percentage) {
      const gstValue = (baseAmt * percentage) / 100;
      let newGstAmount = { cgst: 0, sgst: 0, igst: 0 };

      if (type === "CGST" || type === "SGST") {
        newGstAmount.cgst = gstValue / 2;
        newGstAmount.sgst = gstValue / 2;
        newGstAmount.igst = 0; 
      } else if (type === "IGST") {
        // When IGST is selected
        newGstAmount.igst = gstValue;
        newGstAmount.cgst = 0; // Half of IGST for CGST
        newGstAmount.sgst = 0; // Half of IGST for SGST
      }

      setGstAmount(newGstAmount);
      const data = {
        baseAmount: baseAmt,
        companyName: companyTypeValue,
        gstNumber:gstNumber,
        gstType:type,
        gstPercentage: percentage,
        gstAmount: newGstAmount
      };
      getData(data);
    }
  };

  return (
    <div
      className={`payment-listing_box ${addTogglePaymentIdx ? "show" : "hide"}`}
    >
      <div className="payment-list_array">
        <div className="d-flex">
        <span className="payment-details">GST Details {autoCount + 1}</span>

        </div>
        <div
          className="d-flex toggleImage-content"
          onClick={() => {
            setAddTogglePaymentIdx(!addTogglePaymentIdx);
          }}
        >
          <span className="toggleImage">
            <img
              src="https://cdn-icons-png.flaticon.com/128/4655/4655143.png"
              alt="minus"
              style={{ width: "20px", height: "35px" }}
              className={addTogglePaymentIdx ? "closed" : "open"}
            />
          </span>
        </div>
        {(role === "Admin" || role === "SuperAdmin") && (
          <div className="d-flex" onClick={() => deleteMultipleGstItem()}>
            <span>
              <FaTrash
                style={{
                  fontSize: "16px",
                  color: "red",
                  cursor: "pointer",
                }}
              />
            </span>
          </div>
        )}
      </div>
      <div
        className={`payment-inner_box ${
          addTogglePaymentIdx ? "show-content" : "hide-content"
        }`}
      >
        <Row>
          <Col>
          <Box display="flex" className="input-box" >

            <Form.Label>Base Amount</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                type="text"
                onValueChange={(e) => {
                  handleOnChange(
                    e?.value,
                    companyTypeValue,
                    gstNumber,
                  );
                  setBaseAmount(e?.value);
                }}
                name="baseAmount"
                value={baseAmount || ""}
                defaultValue={baseAmount}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
              />
            </InputGroup>
          </Box>
          </Col>
          <Col></Col>
        </Row>
       
        <Row>
          <Col>
          <Box display="flex" className="input-box" >
            <Form.Label>Company Name</Form.Label>
            <ClientProjectSelect
              options={companyTypeOption}
              placeHolder={<div>Type or select...</div>}
              onChange={(newValue) => {
                handleOnChange(baseAmount, newValue, gstNumber,gstAmount);
                setCompanyTypeValue(newValue);
              }}
              isSearchable
             
              defaultValue={companyTypeValue}
              role={role}
            />
          </Box>
            {oldCompanyName && (
          <Form.Text className="text-muted">
            <Box className="previous-text">Previous Company Name : {oldCompanyName}</Box>
          </Form.Text>
          )}
          
          </Col>
          <Col>
          <Box display="flex" className="input-box" >

            <Form.Label>GST Number</Form.Label>

            <div className="input_with-edit">
              <Form.Control
                type="text"
                name="gstNumber"
                placeholder="Company GST Number"
                disabled
                value={gstNumber}
                onChange={(e) => {
                  handleOnChange(
                    baseAmount,
                    companyTypeValue,
                    e.target.value,
                    gstAmount
                  );
                  setGstNumber(e.target.value);
                }}
              />
            
            </div>
          </Box>
          </Col>
        </Row>
        <Row>
          <Col>
          <Box display="flex" className="input-box" >
            <Form.Label>GST Type</Form.Label>
            <Form.Select
              className="custom--dropdown-container"
              value={gstType}
              onChange={(e) => {
                setGstType(e.target.value);
                calculateGstAmount(e.target.value, gstPercentage);
              }}
            >
              <option value="">Select GST Type</option>
              <option value="IGST">IGST</option>
              <option value="SGST">SGST + CGST</option>
            </Form.Select>
            </Box>
          </Col>
          <Col>
          <Box display="flex" className="input-box" >
            <Form.Label>GST Percentage (%)</Form.Label>
            <Form.Select
              className="custom--dropdown-container"
              value={gstPercentage}
              onChange={(e) => {
                const percentage = Number(e.target.value);
                setGstPercentage(percentage);
                calculateGstAmount(gstType, percentage);
              }}
            >
              <option value="0">0%</option>
              <option value="5">5%</option>
              <option value="12">12%</option>
              <option value="18">18%</option>
              <option value="28">28%</option>
            </Form.Select>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
          <Box display="flex" className="input-box" >
            <Form.Label>IGST</Form.Label>
            {gstType === 'IGST' ?
            
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                type="text"
                
                name="igst"
                value={gstAmount?.igst || ""}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
            :
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                type="text"
                
                name="igst"
                value={gstAmount?.igst || 0}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
            }
            </Box>
          </Col>

          {gstType === 'SGST' ?
          <Col>
          <Box display="flex" className="input-box" >

            <Form.Label>SGST</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                name="sgst"
                type="text"
               
                value={gstAmount?.sgst || ""}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
          </Box>
          </Col>
          :
          <Col>
           <Box display="flex" className="input-box" >

            <Form.Label>SGST</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                name="sgst"
                type="text"
               
                value={gstAmount?.sgst || 0}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
            </Box>
          </Col>
          }
         
        </Row>
        <Row>
        {gstType === 'SGST' ?
          <Col>
           <Box display="flex" className="input-box" >

            <Form.Label>CGST</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                name="gst"
                type="text"
               
                value={gstAmount?.cgst || ""}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
            </Box>
          </Col>
          :
          <Col>
          <Box display="flex" className="input-box" >

            <Form.Label>CGST</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                name="cgst"
                type="text"
               
                value={gstAmount?.cgst || 0}
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
          </Box>
          </Col>
          }

          <Col>
          <Box display="flex" className="input-box" >

            <Form.Label>Total GST</Form.Label>
            <InputGroup>
              <InputGroup.Text>
                <span className="rupee-symbol">₹</span>{" "}
              </InputGroup.Text>
              <CurrencyFormat
                customInput={Form.Control}
                type="text"
                name="total_gst"
                value={ gstType === 'IGST' ?  Number(gstAmount?.igst) :
               
                    Number(gstAmount?.sgst) +
                    Number(gstAmount?.cgst) || ""
                }
                thousandSeparator={true}
                decimalScale={2}
                fixedDecimalScale={true}
                disabled
              />
            </InputGroup>
          </Box>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default GstInfoComponent;
