import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Select,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Form from "react-bootstrap/Form";
// Custom components
import BarChart from "components/charts/BarChart";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart, MdStackedLineChart } from "react-icons/md";
import { getDifferenceFromPrevVal } from "jsFunctions/helperFunctions";
import { createListForSingleOption } from "components/allForm/createableSelect";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetSubCategory } from "redux/formOptions";
import { fetchAsyncGetMinorSubCategory } from "redux/formOptions";
import LineChart from "components/charts/LineChart";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import CustomGraphSelect from "./CustomGraphSelect";

const CreateOption = ({array})=>{
  return (

    array && array.length > 0 && array.map((item,index)=>{
      return (

        <option key={index} value={item?.value}>{item?.label}</option>
      )
    })
  )
    }
  
export default function WeeklyExpense(props) {
  const { ...rest } = props;
  const dispatch = useDispatch();
  const [option, setOption] = useState([]);
  const [value, setValue] = useState([]);
  const [valueDifference, setValueDifference] = useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#22b378", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  const formOptionsList1 = useSelector((state) => state.formOptions);
  const [formOptionsList, setFormOptionsList] = useState([])

  const [clientOption, setClientOption] = useState([]);
  const [merchentOption, setMerchentOPtion] = useState([]);
  const [officeTypeOption, setOfficeTypeOption] = useState([]);
  const [majorTypeOption, setMajorTypeOption] = useState([]);
  const [minorTypeOption, setMinorTypeOption] = useState([]);
  const [minorSubCategoryOption, setMinorSubCategoryOption] = useState([]);
  const [activeType, setActiveType] = useState('bar');
  const [resourceTypeOption, setResourceTypeOption] = useState([]);
  const [projectTypeOption, setProjectTypeOption] = useState([]);

  const [poNoResource, setPoNoResource] = useState([])
  const [receiverOption, setReceiverOption] =useState([])

  const clientData = useSelector((state) => state.clientData);
  const vendorList = useSelector((state) => state.vendorData);

  const fetchPOResource = ()=>{
    dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            value: item._id,
            label: poNumberFormate(item.poNo),
          });
        });
        setPoNoResource(resourcePoNo);
      }
    });
  }
  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === props?.type)
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

 
  useEffect(() => {
   
    getAllClientOptions()


    let officeTypeList = createListForSingleOption(
      formOptionsList,
      "office-type"
    );
    let expenseList = createListForSingleOption(formOptionsList, "expense");
    let receiverList = createListForSingleOption(formOptionsList, "receiver");

   
    setOfficeTypeOption(officeTypeList)
    setMajorTypeOption(expenseList)
    setReceiverOption(receiverList);

    if(props?.type === 'tds'){
      fetchPOResource()
    }
  }, [formOptionsList]);


  useEffect(() => {
    setOption([]);
    setValueDifference(null);
    setOption(props.optionBarChart);
    setValue(props.valuesBarChart);
  }, [props.valuesBarChart, props.optionBarChart]);

  useEffect(() => {
    if (value.length > 0 && option.length > 0) {
      const diff = getDifferenceFromPrevVal(value);
      let getOption = barChartOptionsConsumption(option, diff);
      setValueDifference(getOption);
    }
  }, [value]);

  useEffect(() => {
    const id = props?.filterData?.majorId
    if (id) {
      dispatch(fetchAsyncGetSubCategory(id)).then(
        (response) => {
          if (response.payload) {
            const minorOptions = response.payload || []
            const minorOpt = minorOptions.map((item)=>{
              return {
                ...item,
                id:item?._id
              }
            })
            setMinorTypeOption(minorOpt);
          }
        }
      );
    }
  }, [props?.filterData?.majorId]);

  // Set SubCategory for selected minorype value
  useEffect(() => {
    const id = props?.filterData?.minorId

    if (id) {
      dispatch(fetchAsyncGetMinorSubCategory(id)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload || [];
            const minorSub = minorSubOption.map((item)=>{
              return {
                ...item,
                id:item?._id
              }
            })
            setMinorSubCategoryOption(minorSub );
          }
        }
      );
    }
  }, [props?.filterData?.minorId]);

  const getAllClientOptions = async()=>{
    if(clientData){

      const clientOption = await clientProjectOptions('clientName','_id',clientData) 
      setClientOption(clientOption);
    }
  }

  const getAllProjectOptions = async(clientId)=>{
    const client = clientData.find(client => client._id === clientId);
    if(client){

      const projects = client?.projects || []
      if(projects){
        const projectOption = await clientProjectOptions('projectName','_id',projects) 
        setProjectTypeOption(projectOption);
       
      }
     
    }
  }

   // Set MinorCategory for selected majorType value
   useEffect(() => {
    if (props?.filterData?.clientId && props?.filterData?.clientId) {
      getAllProjectOptions(props?.filterData?.clientId)
    }
    else{
      setProjectTypeOption([])
    }
  }, [props?.filterData?.clientId]);

  const getAllCompanyOptions = async()=>{
    // const filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);
      const companyOption = await clientProjectOptions('companyName','_id',vendorList) 
      setResourceTypeOption(companyOption);
      setMerchentOPtion(companyOption);
  }

  useEffect(()=>{
    if(vendorList){
      getAllCompanyOptions()
    }
  },[vendorList])

  return (
    <Card
      align="center"
      direction="column"
      w="130%"
      h="680px"
      justifyContent="space-between"
      p={0}
      // overflowX={{ sm: "scroll", lg: "hidden" }}
      {...rest}
      className="weekly-expense-graph"

    >
      <Flex 
       className="weekly-graph-flex"
       alignItems="center"
       justifyContent="space-between"

       w="100%"
      >        
        <Text me={20}>
          {props?.heading}
        </Text> 
        <Form.Select
          size="sm"
          className="dashboardSelect"
          disabled={props?.filterData?.viewType === "yearly"}
          onChange={(e) => props.changeYear(e.target.value)}
          defaultValue="2024-2025"
        >
          <option value="">Select Financial Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
          <option value="2025-2026">2025-2026</option>
        </Form.Select>
        <Select
          fontSize="sm"
          variant="subtle"
          width="unset"
          fontWeight="700"
          defaultValue={props?.filterData?.viewType}
          name="viewType"
          onChange={(e) => {
            props.handleFilterViewType(e);
          }}
        >
          <option value="monthly">Monthly</option>
          <option value="yearly">Yearly</option>
        </Select>

        <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>
      {(props?.type !== 'tds' && props?.type !== 'directTax') ?
        <>
      <Flex align="center" w="100%" px="0px" py="0px">
       
       {props?.type === 'internal' &&
        <CustomGraphSelect
        options={officeTypeOption}
        placeHolder={<div>Select Office type</div>}
        name="officeId"
        onChange={(newValue) => {props.handleFilter({
          name:'officeId',
          value:newValue?.value,
          id:newValue?.id
        })}}                 
        isSearchable
        defaultValue={props?.filterData?.office}
      />
        
        }
       

            <CustomGraphSelect
              options={majorTypeOption}
              placeHolder={<div>Select Major type</div>}
              name="majorId"
              onChange={(newValue) => {props.handleFilter({
                name:'majorId',
                value:newValue?.value,
                id:newValue?.id
              })}}                 
              isSearchable
              defaultValue={props?.filterData?.majorId}
            />
           <CustomGraphSelect
              options={minorTypeOption}
              placeHolder={<div>Select Minor type</div>}
              name="minorId"
              onChange={(newValue) => {props.handleFilter({
                name:'minorId',
                value:newValue?.value,
                id:newValue?.id
              })}}                 
              isSearchable
              defaultValue={props?.filterData?.minorId}
            />
       
        {
          props?.type !== 'internal' &&
          <CustomGraphSelect
              options={minorSubCategoryOption}
              placeHolder={<div>Select Minor Sub type</div>}
              name="minorSubCategoryId"
              onChange={(newValue) => {props.handleFilter({
                name:'minorSubCategoryId',
                value:newValue?.value,
                id:newValue?.id
              })}}                 
              isSearchable
              defaultValue={props?.filterData?.minorSubCategoryId}
            />
         
        }
      </Flex>
      <Flex align="center" w="100%" px="0px" py="0px" mb={1}>
        
       {props?.type === 'internal' &&
        <CustomGraphSelect
        options={minorSubCategoryOption}
        placeHolder={<div>Select Minor Sub type</div>}
        name="minorSubCategoryId"
        onChange={(newValue) => {props.handleFilter({
          name:'minorSubCategoryId',
          value:newValue?.value,
          id:newValue?.id
        })}}                 
        isSearchable
        defaultValue={props?.filterData?.minorSubCategoryId}
      />

        
       }
       
        
        {props?.type === 'resource' ?
         <CustomGraphSelect
         options={resourceTypeOption}
         placeHolder={<div>Select Resource type</div>}
         name="resourceId"
         onChange={(newValue) => {props.handleFilter({
           name:'resourceId',
           value:newValue?.value,
           id:newValue?.value
         })}}                 
         isSearchable
         defaultValue={props?.filterData?.resourceId}
       />
         
        :
        <CustomGraphSelect
        options={merchentOption}
        placeHolder={<div>Select Merchant type</div>}
        name="merchantId"
        onChange={(newValue) => {props.handleFilter({
          name:'merchantId',
          value:newValue?.value,
          id:newValue?.value
        })}}                 
        isSearchable
        defaultValue={props?.filterData?.merchantId}
      />
       
        }

      <CustomGraphSelect
        options={clientOption}
        placeHolder={<div>Select Client Name</div>}
        name="clientId"
        onChange={(newValue) => {props.handleFilter({
          name:'clientId',
          value:newValue?.value,
          id:newValue?.value
        })}}                 
        isSearchable
        defaultValue={props?.filterData?.clientId}
      />
       
        {(props?.type === 'resource'  || props?.type === 'external') &&
         <CustomGraphSelect
         options={projectTypeOption}
         placeHolder={<div>Select Project Name</div>}
         name="projectId"
         onChange={(newValue) => {props.handleFilter({
           name:'projectId',
           value:newValue?.value,
           id:newValue?.value
         })}}                 
         isSearchable
         defaultValue={props?.filterData?.projectId}
       />

        
       }
      </Flex>
        </>
        :
        
          props?.type === 'tds' ?
        
          <Flex align="center" w="130%" px="0px" py="0px">
       
       <CustomGraphSelect
         options={resourceTypeOption}
         placeHolder={<div>Select Resource</div>}
         name="resourceId"
         onChange={(newValue) => {props.handleFilter({
           name:'resourceId',
           value:newValue?.value,
           id:newValue?.value
         })}}                 
         isSearchable
         defaultValue={props?.filterData?.resourceId}
       />
       <CustomGraphSelect
         options={poNoResource}
         placeHolder={<div>Reference Resource PO</div>}
         name="referenceResourcePO"
         onChange={(newValue) => {props.handleFilter({
           name:'referenceResourcePO',
           value:newValue?.value,
           id:newValue?.value
         })}}                 
         isSearchable
         defaultValue={props?.filterData?.referenceResourcePO}
       />
       
       
       
       
     </Flex>
        :
        <Flex align="center" w="130%" px="0px" py="0px">
       
       <CustomGraphSelect
         options={receiverOption}
         placeHolder={<div>Reference Receiver Name</div>}
         name="receiverId"
         onChange={(newValue) => {props.handleFilter({
           name:'receiverId',
           value:newValue?.value,
           id:newValue?.id
         })}}                 
         isSearchable
         defaultValue={props?.filterData?.receiverId}
       />
        
        
        
      </Flex>
      }

      <Box h="500px" mt="auto">
        {valueDifference !== null && option.length > 0 && (
          activeType === 'bar' ?
          <BarChart
            chartData={barChartDataConsumption(value)}
            chartOptions={valueDifference}
            selectExpYear={props?.selectExpYear}
            viewType={props?.filterData?.viewType}
          />
          :

          <LineChart
            chartData={barChartDataConsumption(value)}
            chartOptions={valueDifference}
          />
          
        )}
      </Box>
      
    </Card>
  );
}
