/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import { Badge, Box, Button, IconButton, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";

import { useLocation, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { fetchAsyncGetVendor } from "redux/vendorSlice";
import { AiFillDelete, AiFillEye } from "react-icons/ai";
import { fetchAsyncDeleteVendor } from "redux/vendorSlice";
import VendorInfoModel from "components/models/VendorInfoModal";
import VendorsAddNew from "components/allForm/Vendors/vendorsAddNew";
import { RiBankCardFill } from "react-icons/ri";
import { performSearch } from "components/navbar/searchUtils";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import SpinnerLoader from "components/Spinner/SpinnerLoader";

const EmbrillVendor = () => {
  
  const dispatch = useDispatch();

  const [vendorListData, setVendorListData] = useState([]);


  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  
  const searchValue = useSelector((state) => state.searchValue); // Access the dateRange slice
  const {searchText} = searchValue;
  
  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Vendor');
      if(roleObj){
        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  
  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
   
    {
      Header: "Contact Person",
      accessor: "contactPerson",
    },
    {
        Header: "email",
        accessor: "email",
    },
    {
      Header: "GST Number",
      accessor: "gstNumber",
    },
    {
      Header: "Pan Number",
      accessor: "panNumber",
    },
    {
      Header: "Bank Account",
      accessor: "bankAccount",
    },
    {
      Header: "Vendor",
      accessor: "isVendor",
    },
    {
      Header: "Consultant",
      accessor: "isConsultant",
    },
  
    {
      Header: "Action",
      accessor: "action",
    },
    
  ];
  const [openVendorModal, setOpenVendorModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [vendorEditData, setVendorEditData] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [openVendorInfoModel, setOpenVendorInfoModel] = useState(false);
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const handleOpenVendorModal = () => {
    setOpenVendorModal(true);
  };


  const handleCloseVendorModal = () => {
    setOpenVendorModal(false);
    setOpenEditModal(false);
    setVendorEditData(null);
    handlerRefreshData();
  };

  const handlerRefreshData = useCallback(
    () => {
      performSearch(dispatch, location, searchText, "", "");
    },
    [location, dispatch,searchText]
  );


  
  const vendorList = useSelector((state) => state.vendorData);

  const deleteVendor = (data) => {
    dispatch(fetchAsyncDeleteVendor(data._id)).then(() => {
      handlerRefreshData();
    });
  };
  
  useEffect(() => {
    if (vendorList && vendorList.length > 0) {
        const tableData = vendorList.map((data, index) => {
            return {
              _id:data._id,
              no: index + 1,
              
              companyName:(
                <span 
                  style={{ cursor: "pointer",color:'blue'}}
                >
                  {data?.companyName}
                </span>
              ),
              contactPerson: data?.contactPerson,
              email:data?.email,
              gstNumber:data?.gstNo,
              isVendor:(
                <span
                className={
                  data?.isVendor
                    ? "checkmark"
                    : "crossmark"
                }
              ></span>
              ),
              isConsultant:(
                <span
                className={
                  data?.isConsultant
                    ? "checkmark"
                    : "crossmark"
                }
              ></span>
              ),
              bankAccount: (
                <div class="position-relative" >
            <RiBankCardFill size={25} />
            <span class="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
            {data?.bankDetails && data?.bankDetails?.length ? data?.bankDetails?.length : 0}
            </span>
            </div>
              ),
             
             
              panNumber:data?.panNo,
              action: (
                <div className="d-flex">
             <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleEditClick(e,data._id)
                }}
              />
              <AiFillEye
                className="me-2"
                color="#000D55"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedItem(data);
                  setOpenVendorInfoModel(true);
                }}
              />
                  {role === "Admin" ? (
                    <AiFillDelete
                      style={{ cursor: "pointer",marginLeft:'10px' }}
                      size={16}
    
                      onClick={(e) => {
                        e.preventDefault();
                        Swal.fire({
                          title: `Are you sure? you want to delete Vendor <span style="color: #d33">Company Name : ${data?.companyName && data?.companyName}</span>.`,
                          text:'This action cannot be undone.',
                          icon: "warning",
                          confirmButtonColor: "#3085d6",
                          cancelButtonColor: "#d33",
                          confirmButtonText: "Yes, delete",
                          showCancelButton: true,
                          customClass: {
                            container: "sweet_containerImportant",
                            confirmButton: "sweet_confirmbuttonImportant",
                          },
                        }).then((result) => {
                          if (result.isConfirmed) {
                            deleteVendor(data);
                          }
                        });
                      }}
                    />
                  ):'No Access'}
                </div>
              ),
            };
          });
      setVendorListData(tableData);
    } else {
      setVendorListData([]);
    }
  }, [vendorList]);

  const navigate = useNavigate();

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = vendorList.filter((item)=> item?._id === _id)
    // setOpenEditModal(true);
    setVendorEditData(data[0]);
    // handleOpenVendorModal();
    navigate(`/vendor/${_id}`);
  }

  const handleEditClick = (e,_id)=>{
    e.stopPropagation();
    const data = vendorList.filter((item)=> item?._id === _id)
    setOpenEditModal(true);
    setVendorEditData(data[0]);
    handleOpenVendorModal();
  }

  const closeVendorInfoModel = () => {
    setOpenVendorInfoModel(false);
    setSelectedItem({});
  };

useEffect(() => {
  setLoading(true); 
  dispatch(fetchAsyncGetVendor())
    .then(() => {
      setLoading(false); // Stop loading after fetching is complete
    })
    .catch((error) => {
      console.error("Error fetching data:", error);
      setLoading(false); // Stop loading in case of error
    });
  }, []);
  const textColor = useColorModeValue("secondaryGray.900", "white");


   // If loading, return a spinner
   if (loading) {
    return (
      <SpinnerLoader/>
    );
  }
  return (
    <>
      <Box display="flex" className="mx-0" justifyContent="space-between">
      <Box display="flex">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
          mx={2}
        >
          Vendors
        </Text>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleOpenVendorModal}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
            
        
         
        </Box>
     
      
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={vendorListData}
          type={"Vendors"}
          role={role}
          expenseType = 'vendors'
          openInfoModal = {handleRowClick}

        />
      </Box>
      {openVendorModal && (
        <VendorsAddNew
          openModal={openVendorModal}
          closeModal={handleCloseVendorModal}
          isEditForm={openEditModal}
          editItem={vendorEditData}
          role={role}
          refreshEditData={handlerRefreshData}

        />
      )}
      {openVendorInfoModel && (
        <VendorInfoModel
          openInfoModal={openVendorInfoModel}
          closeInfoModal={closeVendorInfoModel}
          selectedData={selectedItem}
          role={role}
        />
      )}
    </>
  );
};

export default EmbrillVendor;
