import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Swal from "sweetalert2";
import axios from 'axios';
import VenderBankInfo from "./venderBankInfo";
import { fetchAsyncAddVendor } from "redux/vendorSlice";
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncDeleteVendorBank } from "redux/vendorSlice";
import { fetchAsyncUpdateVendor } from "redux/vendorSlice";
import { FaPlusCircle } from "react-icons/fa";

const VendorsAddNew = ({
  openModal,
  closeModal,
  isEditForm = false,
  editItem,
}) => {
  const finalRef = useRef(null);
  const [editData, setEditData] = useState(editItem)
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [isVendor, setIsVendor] = useState(false);
  const [isConsultant, setIsConsultant] = useState(false);
  
  const [formData, setFormData] = useState({
    companyName: '',
    contactPerson: '',
    email: '',
    phone: '',
    gstNo: '',
    panNo: '',
    bankDetails: [],
  });

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  const MAX_PAN_LENGTH = 10;
  const MAX_GST_LENGTH = 15;
  const MAX_IFSC_LENGTH = 11; 
  const PHONE_REGEX = /^[0-9]{10}$/;
  const GST_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z][0-9A-Z][Z][0-9A-Z]$/; // Sample regex for GST
  const PAN_REGEX = /^[A-Z]{5}[0-9]{4}[A-Z]$/; // Sample regex for PAN
  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const IFSC_REGEX = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
  const ACCOUNT_NUMBER_REGEX = /^[0-9]{9,18}$/;

  const validate = () => {
    const newErrors = {};
    
    // Validate each field
    if (!formData.companyName) newErrors.companyName = "Company Name is required";
    if (!formData.contactPerson) newErrors.contactPerson = "Contact Person is required";
    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
      newErrors.email = "Email format is invalid.";
    }
    if (formData.phone && !PHONE_REGEX.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
   if (formData.gstNo && !GST_REGEX.test(formData.gstNo)) {
      newErrors.gstNo = "GST Number format is invalid.";
    }
   if (formData?.panNo && !PAN_REGEX.test(formData.panNo)) {
      newErrors.panNo = "PAN Number format is invalid.";
    }

   // Validate bank details
  formData.bankDetails.forEach((bankDetail, index) => {
   
    if (!bankDetail.accountNumber) {
      newErrors[`bankDetails.${index}.accountNumber`] = "Account Number is required.";
    } else if (!ACCOUNT_NUMBER_REGEX.test(bankDetail.accountNumber)) {
      newErrors[`bankDetails.${index}.accountNumber`] = "Account Number must be between 9 to 18 digits.";
    }
    if (!bankDetail.ifscCode) {
      newErrors[`bankDetails.${index}.ifscCode`] = "IFSC Code is required.";
    } else if (!IFSC_REGEX.test(bankDetail.ifscCode)) {
      newErrors[`bankDetails.${index}.ifscCode`] = "IFSC Code format is invalid.";
    }
    });
    
    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0 
  };

  const toCamelCase = (value) => {
    return value
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "panNo" || name === "gstNo") {
      formattedValue = value.toUpperCase();
      if(name === "panNo" &&  formattedValue.length > MAX_PAN_LENGTH ) {
       return 
      }
      if(name === "gstNo" &&  formattedValue.length > MAX_GST_LENGTH ) {
        return 
      }
     
       
    } else if (name === "email") {
      formattedValue = value.toLowerCase(); 
    } else{
      formattedValue = toCamelCase(value); 

    }

    if (name === "phone") {
      if (!formattedValue || PHONE_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, phone: "" })); 
      } else {
        setErrors((prev) => ({ ...prev, phone: "Phone number must be 10 digits." }));
      }
    }
    if (name === "email") {
      if (!formattedValue || EMAIL_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, email: "" })); 
      }else{
        setErrors((prev) => ({ ...prev, email: "Email format is invalid." })); 

      }
    }
    if (name === "gstNo") {
      if (!formattedValue || GST_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, gstNo: "" })); 
      } 
      else{
        setErrors((prev) => ({ ...prev, gstNo: "GST Number format is invalid." })); 

      }
    }
    if (name === "panNo") {
      if (!formattedValue || PAN_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, panNo: "" })); 
      } 
      else{
        setErrors((prev) => ({ ...prev, panNo: "PAN Number format is invalid." })); 
      }
    }

    // Update formData state
    if (name.startsWith("bankDetails.")) {
      const bankIndex = name.split(".")[1];
      const fieldName = name.split(".")[2];
     
      if (fieldName === "ifscCode") {
        formattedValue = value.toUpperCase();
        if(formattedValue.length > MAX_IFSC_LENGTH ) {
          return 
         }
      }
       // Handle error reset for accountNumber and ifscCode
    if (fieldName === "accountNumber" || fieldName === "ifscCode") {
    
      setErrors((prev) => ({
        ...prev,
        [`bankDetails.${bankIndex}.${fieldName}`]: "", // Clear error on valid input
      }));
    }
      setFormData((prev) => ({
        ...prev,
        bankDetails: prev.bankDetails.map((bankDetail, index) => {
          if (index === parseInt(bankIndex)) {
            if (fieldName.startsWith("bankAddress.")) {
              const addressFieldName = fieldName.split(".")[2];
              return {
                ...bankDetail,
                bankAddress: {
                  ...bankDetail.bankAddress,
                  [addressFieldName]: formattedValue,
                },
              };
            } else {
              return {
                ...bankDetail,
                [fieldName]: formattedValue,
              };
            }
          }
          return bankDetail;
        }),
      }));
    } else {
      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    }
  };

  const fetchBranchDetails = async (index) => {
    const { ifscCode, micrCode } = formData.bankDetails[index];
    const codeToFetch = ifscCode || micrCode;
    if (!codeToFetch) return;

    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${codeToFetch}`);
      const { BANK, BRANCH, ADDRESS, CITY, STATE, SWIFT } = response.data;

      setFormData((prev) => ({
        ...prev,
        bankDetails: prev.bankDetails.map((bankDetail, i) => {
          if (i === index) {
            return {
              ...bankDetail,
              bankName: toCamelCase(BANK),
              branchName: toCamelCase(BRANCH),
              swiftCode: SWIFT,
              bankAddress: {
                city: toCamelCase(CITY),
                state: toCamelCase(STATE),
              },
            };
          }
          return bankDetail;
        }),
      }));
    } catch (error) {
      console.error("Error fetching branch details:", error);
      Swal.fire("Error", "Could not fetch branch details. Please check the IFSC or MICR code.", "error");
    }
  };

  const handleBlur = (index) => {
    if (IFSC_REGEX.test(formData.bankDetails[index].ifscCode)) {
      fetchBranchDetails(index);
    } else {
      setFormData((prev) => ({
        ...prev,
        bankDetails: prev.bankDetails.map((bankDetail, i) => {
          if (i === index) {
            return {
              ...bankDetail,
              bankName: "",
              branchName: "",
              swiftCode: "",
              bankAddress: {
                city: "",
                state: "",
              },
            };
          }
          return bankDetail;
        }),
      }));
    }
  };

  const handleAddVendor = (data)=>{
    dispatch(fetchAsyncAddVendor(data))
      .then((res) => {
        if (res?.payload) {
          const vendorDetails = res?.payload?.vendor;
          const vendor = vendorDetails?.isVendor;
          const consultant = vendorDetails?.isConsultant;
          setFormData(vendorDetails)
          setIsVendor(vendor);
          setIsConsultant(consultant)
          addToast("vendor created successfully!", { appearance: "success" });
          closeModal();
         }
         else{
          addToast(res?.error?.message, { appearance: "error" });

         }
  
      })
      .catch((error) => {
        addToast("Not Added", { appearance: "error" });
      });
  }
  const handleUpdateVendor = (data)=>{
    dispatch(fetchAsyncUpdateVendor({id:editData?._id,data:data}))
      .then((res) => {
        if (res?.payload) {
          const vendorDetails = res?.payload?.vendor;
          const vendor = vendorDetails?.isVendor;
          const consultant = vendorDetails?.isConsultant;
          setFormData(vendorDetails)
          setIsVendor(vendor);
          setIsConsultant(consultant)
          addToast("vendor details updated successfully!", { appearance: "success" });
          closeModal();
         }
  
      })
      .catch((err) => {
        addToast("Not Added", { appearance: "error" });
      });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validate()) {
      const updatedFormData = {
        ...formData,
        isVendor,      
        isConsultant,
      };
     if(!isEdit){
      handleAddVendor(updatedFormData)
     }else{
      handleUpdateVendor(updatedFormData)
     }
    }
  };

  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: "You will lose all current changes",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };

  const addBankDetail = () => {
    setFormData((prev) => ({
      ...prev,
      bankDetails: [
        ...prev.bankDetails,
        {
          bankName: '',
          branchName: '',
          ifscCode: '',
          swiftCode: '',
          accountNumber: '',
          accountType: '',
          bankAddress: {
            city: '',
            state: '',
          },
        },
      ],
    }));
    
  };

  useEffect(()=>{
    if(editData){

      setFormData({
        companyName: editData?.companyName || "",
      contactPerson: editData?.contactPerson || "",
      email: editData?.email || "",
      phone: editData?.phone || "",
      gstNo: editData?.gstNo || "",
      panNo: editData?.panNo || "",
      bankDetails: editData?.bankDetails
      })
      const vendor = editData?.isVendor;
      const consultant = editData?.isConsultant;
      setIsVendor(vendor);
      setIsConsultant(consultant)
    }
  },[editData])


  const removeBankDetail = (index) => {
  
    if(isEdit){
      Swal.fire({
        title: `Are you sure? you want to delete.`,
        text:'This action cannot be undone.',
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(fetchAsyncDeleteVendorBank(editData?._id,index)).then((res) => {
            if(res?.payload){
              addToast("Bank detail deleted successfully!", { appearance: "success" });
              setFormData((prev) => ({
                ...prev,
                bankDetails: prev.bankDetails.filter((bankDetail, i) => i !== index),
              }));
            }
          });        }
      });
     
    }
    else{
      setFormData((prev) => ({
        ...prev,
        bankDetails: prev.bankDetails.filter((bankDetail, i) => i !== index),
      }));
    }
  };

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
        top="0" // Position it at the top
        margin="0 auto" // Center it horizontally
        bottom="0"
        className="add-form-modal-content"
        >
        <div className="fixed-header">
          <ModalHeader>{isEditForm ? "Edit" : "Add"} Vendor Details</ModalHeader>
          <ModalCloseButton />
          </div>
          <ModalBody>
            <Form onSubmit={handleSubmit} className="addForm">
              <div className="form-card">
                <h5>Personal Details</h5>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Company Name <span className="span-star">*</span></Form.Label>
                    

                    <Form.Control
                      type="text"
                      name="companyName"
                      placeholder="Enter Company Name"
                      value={formData.companyName}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!formData.companyName && !!errors.companyName}
                    />
                    </Box>
                    <p className="form_error"> {!formData.companyName && errors.companyName}</p>

                   
                  </Col>
                
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Contact Person <span className="span-star">*</span></Form.Label>
                   

                    <Form.Control
                      type="text"
                      name="contactPerson"
                      placeholder="Enter Contact Person"
                      value={formData.contactPerson}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!formData.contactPerson && !!errors.contactPerson}
                    />
                    </Box>
                    <p className="form_error">{!formData.contactPerson && errors.contactPerson}</p>

                  
                  </Col>
                </Row>
                <Row>
                
                
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Email</Form.Label>

                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!!errors.email}
                    />
                  </Box>
                    
                  <p className="form_error">{errors?.email}</p>

                  </Col>

                <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Phone Number</Form.Label>

                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter Phone Number"
                      value={formData.phone}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!!errors.phone}
                    />
                    </Box>
                    <p className="form_error">{errors?.phone}</p>

                  </Col>
                </Row>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>GST Number</Form.Label>

                    <Form.Control
                      type="text"
                      name="gstNo"
                      placeholder="Enter GST Number"
                      value={formData.gstNo}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!!errors.gstNo}
                    />
                    </Box>
                    <p className="form_error">{errors?.gstNo}</p>

                  </Col>
                 
                <Col>
                <Box display="flex" className="input-box">
                    <Form.Label>PAN Number </Form.Label>

                    <Form.Control
                      type="text"
                      name="panNo"
                      placeholder="Enter PAN Number"
                      value={formData.panNo}
                      onChange={(e)=>handleChange(e)}
                      isInvalid={!!errors.panNo}
                    />
                    </Box>
                    <p className="form_error">{errors?.panNo}</p>
                  </Col>
                </Row>
                
                <Row>
                <Col>
                <Box display="flex">
                      <Form.Label>Type Of Vendor</Form.Label>
                      <Box className="d-flex mx-5">

                      <Form.Check
                        type="checkbox"
                        name="isVendor"
                        label="Vendor"
                         className="mx-2"
                        checked={isVendor}
                        onChange={(e) => setIsVendor(e.target.checked)}
                      />
                   
                      <Form.Check
                        type="checkbox"
                        name="isConsultant"
                        className="mx-3"
                        label="Consultant"
                        checked={isConsultant}
                        onChange={(e) => setIsConsultant(e.target.checked)}
                      />
                      </Box>
                      </Box>
                    </Col>
                </Row>
              </div>
              
            
              {formData?.bankDetails && formData?.bankDetails?.length > 0 && formData?.bankDetails.map((bankDetail, index) => (
               <VenderBankInfo
               bankDetail={bankDetail}
               index ={index}
               removeBankDetail={removeBankDetail}
               handleChange={handleChange}
               handleBlur={handleBlur}
               errors={errors} 
               companyName={formData?.companyName}
               />
              ))}
             <div className="form-card mt-4">

             <Box display="flex" alignItems="center" onClick={addBankDetail}>
                            <h5>Add Bank</h5>
                           
                            <Button
                              className="add-project mt-2"
                             
                            >
                              <FaPlusCircle
                                className="add-project-icon"
                                size={15}
                              />
                            </Button>
                          </Box>
             </div>
            
              <ModalFooter className="mt-4">
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="brand"
                  style={{ background: "#191063" }}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default VendorsAddNew;