import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";

export const getDirectTaxExcelData = (directTaxList) => {
    let list = JSON.parse(JSON.stringify(directTaxList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        'Order PO Number': data.poNo && poNumberFormate(data.poNo),
        'Current Order Date':
          data.orderDate && moment(data.orderDate).format('DD-MMM-YYYY'),
          "Current Order Month": data.orderMonth && poMonthFormate(data.orderMonth),

        'Receiver Name': data.receiverId?.value,
        'Total Tax': data.totalTax,
        "Remarks": data.remarks,
        
        "Requested Amount":`₹ ${data.paymentInfo?.approvalAmount  ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,

        "Is It Refund Amount?": data?.refundInfo?.isRefund ? "true" : "false",
        "Refund Payee": data?.refundInfo?.payee || "",
        "Refund Amount": `₹ ${data?.refundInfo?.amount ? formatAmountIndianRupees(data?.refundInfo.amount ) : Number(0).toFixed(2)}`,
        "Refund Bank": data?.refundInfo?.bank || "",
        "Refund Reference Number": data?.refundInfo?.referenceNumber || "",
        "Refund Date": data?.refundInfo?.refundDate && moment(data?.refundInfo?.refundDate).format("DD-MMM-YYYY"),
        
      };
      
      if (data?.paymentInfo?.list?.length > 0) {
        data.paymentInfo.list.forEach((element, index) => {
          content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount  ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
          content[`Payment ${index + 1} Bank`] = element?.bankId?.value;
          content[`Payment ${index + 1} Payee`] = element?.payeeId?.value;
          content[`Payment ${index + 1} Date`] = element?.paymentDate &&
            moment(element?.paymentDate).format("DD-MMM-YYYY");
  
         
        });
      }
     
      content['Space Added'] = '--------------';

      content["Created AT"] = data.createdAt ? moment(data.createdAt).format("DD-MMM-YYYY") : '';

      return content;
    });
    return exportData;
  };