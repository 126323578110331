// handling option could be editable
export const createOption = (label) => ({
  label,
  value : label,
});

export const createOptionWithID = (data) => ({
  label:data?.label ? data?.label : data?.value,
  value : data?.label ? data?.label : data?.value,
  id:data?._id ? data?._id : ""
});

export const createListForSingleOption = (optionsList, type) => {
  if (optionsList.length === 0) {
    return [];
  }
  let data = optionsList
    .filter((item) => item.type === type)
    .map((data) => createOptionWithID(data));
  return data;
};
