import React, { useCallback, useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Button,
  ModalHeader,
  ModalOverlay,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td, 
  Box,
} from "@chakra-ui/react";
import { Col, Row } from "react-bootstrap";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { dateCompare } from "jsFunctions/dateTimeFormat";
import { fetchAsyncResourcePONum } from "redux/tdsSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { useDispatch, useSelector } from "react-redux";
import { currencyFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import SpentAmountTable from "./dataChangeTable/spentAmount";
import GstAmountTable from "./dataChangeTable/gstAmountTable";
import RefundAmountTable from "./dataChangeTable/refundAmountTable";

const TdsDataChangeModal = ({
  openModal,
  closeModal,
  editData,
  currentData,
  isConfirmed,
  type,
  role
}) => {
    let getcurrectData = {};
    for (const pair of currentData?.entries()) {
      if (pair[0] === "payInfo") {
        getcurrectData[pair[0]] = JSON.parse(pair[1]);
      } else {
        getcurrectData[pair[0]] = pair[1];
      }
    }
    
    const vendorList = useSelector((state) => state.vendorData);

    const [currentSpentInfo, setCurrentSpentInfo] = useState([])
    const [editSpentInfo, setEditSpentInfo] = useState([])

    const [currentRefundInfo, setCurrentRefundInfo] = useState([])
    const [editRefundInfo, setEditRefundInfo] = useState([])

  const [currentPo, setCurrentPo] = useState('')
  const [editPo, setEditPo] = useState('')
  const dispatch = useDispatch();



  const fetchResourcePo = (referenceResourcePO,type)=>{
     dispatch(fetchAsyncResourcePONum()).then((res) => {
      if (res.payload) {
        const resourcePoNo = [];
        res.payload.forEach((item, index) => {
          resourcePoNo.push({
            _id: item._id,
            poNo: poNumberFormate(item.poNo),
          });
        });
        const array = resourcePoNo && resourcePoNo?.length > 0 && resourcePoNo.filter((item)=> item._id == referenceResourcePO)
        const obj = array && array?.length && array[0]
        
        const pono = obj && obj?.poNo ? obj?.poNo : '';
        if(type === 'current'){
          setCurrentPo(pono)
        }
        if(type === 'edited'){
          setEditPo(pono)
        }
      
      }
    
    });
  }


  useEffect(()=>{
    if(getcurrectData?.refResourcePONum){
      fetchResourcePo(getcurrectData?.refResourcePONum,'edited')
    }
    if(editData?.referenceResourcePO){
      fetchResourcePo(editData?.referenceResourcePO,'current')
    }
    if(getcurrectData?.payInfo){
      const spentData =  getcurrectData?.payInfo || [];
      const filterOutData = spentData?.map((item) => {
          return item;
      });
      setCurrentSpentInfo(filterOutData);
    }
    if(editData?.paymentInfo?.list){
      const currSpentData = getcurrectData?.payInfo || [];
      const spentData = editData?.paymentInfo?.list || [];
    
      // Calculate the difference and add dummy objects
      const dummObjectCount = currSpentData.length - spentData.length;
      let filterOutData = spentData;
      if(dummObjectCount >= 0){

        filterOutData = [...spentData, ...Array(dummObjectCount).fill({})];
      }      
      setEditSpentInfo(filterOutData);
    }

    if(getcurrectData?.refundInfo){
      const refundData =  getcurrectData?.refundInfo ? JSON.parse(getcurrectData?.refundInfo) : []
      const filterOutData = refundData?.map((item) => {
         return item       
     });
     setCurrentRefundInfo(filterOutData)
    }
    if (editData?.refundInfo?.list) {
      const refundData = editData?.refundInfo?.list || [];
      const currrefundData = getcurrectData?.refundInfo ? JSON.parse(getcurrectData?.refundInfo) : [];
    
      const dummObjectCount = currrefundData.length - refundData.length;
    
      const filterOutData = [
        ...refundData,  
        ...Array(dummObjectCount).fill({})  
      ];
    
      setEditRefundInfo(filterOutData);  
    }

  },[])   


  const fetchResourceName = useCallback((vendorId) => {
    const filteredVendors = vendorList.filter(vendor => vendor.isConsultant === true);

    const vendor = filteredVendors.find((vendor) => vendor._id === vendorId);
    
    if (vendor) {
      return vendor.companyName; 
    } else {
      return ""; 
    }
  }, [vendorList]);
  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        size="xl"
        className="modal-main data-change-model"
        scrollBehavior={"inside"}
        
      >
        <ModalOverlay />
        <ModalContent 
         top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
        className="data-change-content info-model"
>
          <ModalHeader className="expense-modal-header data-change-modal-header">
            <>
              {/* Compare fields */}
              <Row className="modal-header-row">
                <Col>
                  <h6>
                    Current
                  </h6>
                </Col>
                
                <Col>
                  <h6 >
                    Edited
                  </h6>
                </Col>
              </Row>
            </>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="mt-0">
            <Row>
              <Col className="row-col">
                <div className="row-table">
                  <Row className="rowC">
                        <h4 className="row-heading">
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(editData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>Order Date : {dateTimeFormat(editData.orderDate)}</Col>
                  </Row>
                 
                    <Row className="rowC">
                        <Col className={`${editData?.referenceResourcePO !== getcurrectData?.refResourcePONum  ? 'data_compare_red' : '' }`}>Reference Resource PO  : {currentPo}</Col>
                      </Row>
                  <Row className="rowC">
                        <Col className={`${editData?.resourceId?._id !== getcurrectData?.resourceId  ? 'data_compare_red' : '' }`}>Resource Name : {fetchResourceName(editData?.resourceId?._id)}</Col>
                    </Row>
                  <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData?.panNumber  ? 'data_compare_red' : '' }`}>Pan Number : {editData?.panNumber}</Col>
                    </Row>
                
                      
                     
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          TDS Information:
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.totalBillingAmount != getcurrectData.totalAmount  ? 'data_compare_red' : '' }`}>
                          <Box display="flex">Total Amount : {formatAmountInINR(parseFloat(editData?.totalBillingAmount || 0))}</Box>
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_red' : '' }`}>
                          TDS Percent : {editData?.tdsPercent}
                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsAmount != getcurrectData.tdsAmount  ? 'data_compare_red' : '' }`}>
                        <Box display="flex"> TDS Amount : {(formatAmountInINR(parseFloat(parseFloat(editData?.tdsAmount || 0))))} </Box>
                        </Col>
                      </Row>
                      
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          Payment Information : 
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_red' : '' }`}>
                            <Box display="flex">
                            Requested Amount : {formatAmountInINR(editData?.paymentInfo?.approvalAmount)}
                            </Box>
                        </Col>
                      </Row>

                     <SpentAmountTable editSpentInfo={editSpentInfo} currentSpentInfo={currentSpentInfo} type="current" />
                 
                 
               

                    

                     {(role === "Manager" || role === "Admin") &&
                     <>
                      <Row className="rowC">
                        <h4
                          className="row-heading" 
                        >
                          Refund Information : 
                        </h4>
                      </Row>
                      <RefundAmountTable editRefundInfo={editRefundInfo} currentRefundInfo={currentRefundInfo} type="current"/>
                     </>
}
                  
                     
                      
                  
                  
                   
                </div>
              </Col>
              <Col className="row-col">
                <div className="row-table">
                <Row className="rowC">
                        <h4
                         className="row-heading"
                        >
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(getcurrectData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>
                      Order Date : {dateTimeFormat(getcurrectData.orderDate)}
                    </Col>
                  </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.referenceResourcePO !== getcurrectData?.refResourcePONum  ? 'data_compare_green' : '' }`}>Reference Resource PO : {editPo}</Col>
                      </Row>
                  <Row className="rowC">
                        <Col className={`${editData?.resourceId?._id !== getcurrectData?.resourceId  ? 'data_compare_green' : '' }`}>Resource Name : {fetchResourceName(getcurrectData?.resourceId)}</Col>
                    </Row>
                  <Row className="rowC">
                        <Col className={`${editData?.panNumber !== getcurrectData.panNumber  ? 'data_compare_green' : '' }`}>Pan Number : {getcurrectData?.panNumber}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          TDS Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.totalBillingAmount != getcurrectData.totalAmount  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">Total Amount : {formatAmountInINR(parseFloat(getcurrectData.totalAmount || 0))}</Box></Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsPercent != getcurrectData.tdsPercent  ? 'data_compare_green' : '' }`}>TDS Percent : {getcurrectData?.tdsPercent}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.tdsAmount != getcurrectData.tdsAmount  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">TDS Amount : {formatAmountInINR(parseFloat(getcurrectData?.tdsAmount || 0))}</Box></Col>
                      </Row>
                     
                  
                     
                      
                     
                      
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          Payment Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">

                        Requested Amount : {formatAmountInINR(parseFloat(getcurrectData?.approvalAmount))}
                        </Box>
                        </Col>
                      </Row>
                
                    <SpentAmountTable editSpentInfo={editSpentInfo} currentSpentInfo={currentSpentInfo} type="edited" />
                 
              
                 
                    {(role === "Manager" || role === "Admin") &&
                    <>
                  <Row className="rowC">
                        <h4
                          className="row-heading" 
                        >
                          Refund Information : 
                        </h4>
                      </Row>
                      <RefundAmountTable editRefundInfo={editRefundInfo} currentRefundInfo={currentRefundInfo} type="edited"/>
                    </>
                  }
                  
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="border-2 p-0 pt-4 pb-2">
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                isConfirmed();
                closeModal();
              }}
            >
              ok
            </Button>
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                closeModal();
              }}
            >
              cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default TdsDataChangeModal;
