import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
} from "@chakra-ui/react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Swal from "sweetalert2";
import axios from 'axios';
import { useDispatch } from "react-redux";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncAddEmbrillProfile } from "redux/embrillProfileSlice";
import { fetchAsyncUpdateEmbrillProfile } from "redux/embrillProfileSlice";
import ExcelComponent from "../seprateFormComponent/excelComponent";
import { fetchAsyncUploadExcel } from "redux/embrillProfileSlice";
import { fetchAsyncDeleteExcel } from "redux/embrillProfileSlice";
import { FaPlusCircle } from "react-icons/fa";

const EmbrillProfileAddNew = ({
  openModal,
  closeModal,
  isEditForm = false,
  editItem,
  role
}) => {
  const finalRef = useRef(null);

  const [formData, setFormData] = useState({
    contactPerson: '',
    email: '',
    phone: '',
    bankName: '',
    branchName: '',
    ifscCode: '',
    accountNumber: '',
  });

  const [excelData, setExcelData] = useState([])

  const [errors, setErrors] = useState({});
  const [editData, setEditData] = useState(editItem)
  const [isEdit, setIsEdit] = useState(isEditForm)
  const dispatch = useDispatch();
  const { addToast } = useToasts();

  


  const MAX_IFSC_LENGTH = 11; 
  const PHONE_REGEX = /^[0-9]{10}$/;

  const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  const IFSC_REGEX = /^[A-Z]{4}[0][A-Z0-9]{6}$/;
  const ACCOUNT_NUMBER_REGEX = /^[0-9]{9,18}$/;


  const addExcelDetail = () => {
    setExcelData((prev) => ([
        ...prev,
        {
         excelType:"",
         file:""
        },
      ]
   ));
  };

  const handelDeleteExcelDetail = (index) => {
    setExcelData((prev) => (
      prev.filter((excel, i) => i !== index)
    ));
  };

  useEffect(()=>{
    if(editData){
      setFormData({
      contactPerson: editData?.contactPerson || "",
      email: editData?.email || "",
      phone: editData?.phone || "",
      accountNumber: editData?.accountNumber || "",
      ifscCode: editData?.ifscCode || "",
      bankName: editData?.bankName || "",
      branchName: editData?.branchName || ""
    })
    if(editData?.excelData){
      console.log(editData?.excelData,'excel')
      setExcelData(editData?.excelData)
    }
    }
  },[editData])

  const validate = () => {
    const newErrors = {};
    
    // Validate each field
   
    if (formData.email && !EMAIL_REGEX.test(formData.email)) {
      newErrors.email = "Email format is invalid.";
    }
    if (formData.phone  && !PHONE_REGEX.test(formData.phone)) {
      newErrors.phone = "Phone number must be 10 digits.";
    }
    
   
    if (!formData.accountNumber) {
      newErrors.accountNumber = "account number is required";
    } else if (!ACCOUNT_NUMBER_REGEX.test(formData.accountNumber)) {
      newErrors.accountNumber = "Account Number must be between 9 to 18 digits.";
    }

    if (!formData.ifscCode) {
      newErrors.ifscCode = "IFSC Code is required";
    } else if (!IFSC_REGEX.test(formData.ifscCode)) {
      newErrors.ifscCode = "IFSC Code format is invalid.";
    }

    

    setErrors(newErrors);
    
    return Object.keys(newErrors).length === 0;
  };

  const toCamelCase = (value) => {
    return value
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === "panNo" || name === "gstNo" || name === "ifscCode") {
      formattedValue = value.toUpperCase();
     
      if(name === "ifscCode" &&  formattedValue.length > MAX_IFSC_LENGTH ) {
        return 
      }
       
    } else if (name === "email") {
      formattedValue = value.toLowerCase(); 
    } else {
      formattedValue = toCamelCase(value); 
    }

    if (name === "phone") {
      if (!formattedValue || PHONE_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, phone: "" })); 
      } else {
        setErrors((prev) => ({ ...prev, phone: "Phone number must be 10 digits." }));
      }
    }
    if (name === "email") {
      if (!formattedValue || EMAIL_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, email: "" })); 
      }else{
        setErrors((prev) => ({ ...prev, email: "Email format is invalid." })); 
      }
    }
   
    if (name === "ifscCode") {
      if (!formattedValue || IFSC_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, ifscCode: "" })); 
      } 
      else{
        setErrors((prev) => ({ ...prev, ifscCode: "IFSC Code format is invalid." })); 
      }
    }
    if (name === "accountNumber") {
      if (!formattedValue || ACCOUNT_NUMBER_REGEX.test(formattedValue)) {
        setErrors((prev) => ({ ...prev, accountNumber: "" })); 
      } 
      else{
        setErrors((prev) => ({ ...prev, accountNumber: "Account Number must be between 9 to 18 digits." })); 
      }
    }

      setFormData((prev) => ({
        ...prev,
        [name]: formattedValue,
      }));
    
  };

  
  const fetchBranchDetails = async () => {
    const { ifscCode, micrCode } = formData;
    const codeToFetch = ifscCode || micrCode;
    if (!codeToFetch) return;

    try {
      const response = await axios.get(`https://ifsc.razorpay.com/${codeToFetch}`);
      const { BANK, BRANCH } = response.data;

      setFormData((prev) => ({
        ...prev,
        bankName: toCamelCase(BANK),
        branchName: toCamelCase(BRANCH),
      }));
    } catch (error) {
      console.error("Error fetching branch details:", error);
      setFormData((prev) => ({
        ...prev,
        bankName: "",
        branchName: "",
      }));
      // Swal.fire("Error", "Could not fetch branch details. Please check the IFSC or MICR code.", "error");
    }
  };

  const handleBlur = () => {
    if (IFSC_REGEX.test(formData.ifscCode)) {
      fetchBranchDetails();
    } else {
      setFormData((prev) => ({
        ...prev,
        bankName: "",
        branchName: "",
        
      }));
    }
  };

  const handleAddVendor = (data)=>{
    dispatch(fetchAsyncAddEmbrillProfile(data))
      .then((res) => {
        if (res?.payload) {
          const embrillProfileDetails = res?.payload?.embrillProfile;
          setFormData(embrillProfileDetails)
          addToast("embrill Profile details created successfully!", { appearance: "success" });
          // closeModal();
          setIsEdit(true)
          setEditData(embrillProfileDetails)
         }
  
      })
      .catch((err) => {
        addToast("Not Added", { appearance: "error" });
      });
  }
  const handleUpdateVendor = (data)=>{
    dispatch(fetchAsyncUpdateEmbrillProfile({id:editData?._id,data:data}))
      .then((res) => {
        if (res?.payload) {
          const embrillProfileDetails = res?.payload?.embrillProfile;
          setFormData(embrillProfileDetails)
          addToast("embrill profile details updated successfully!", { appearance: "success" });
          closeModal();
         }
  
      })
      .catch((err) => {
        addToast("Not Added", { appearance: "error" });
      });
  }
  
  const handleSubmit = (e) => {
    e.preventDefault();
   
    if (validate()) {
     if(!isEdit){

      handleAddVendor(formData)
     }else{
      handleUpdateVendor(formData)
     }
    }
  };

  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: "You will lose all current changes",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeModal();
      }
    });
  };

  const addNewExcelOnEdit = (file, index) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (editData) {
        
          let formData = new FormData();
          formData.append("excelType", excelData[index]?.excelType);
          formData.append("index", index);
          formData.append("file", file);
          formData.append("accountNumber", formData?.accountNumber || editData?.accountNumber);

         
        

          dispatch(
            fetchAsyncUploadExcel({ id: editData._id, formData })
          ).then((res) => {
            if(res?.payload){
              const newExcelData = res?.payload?.embrillProfile?.excelData
              setExcelData(newExcelData)
            }
            // refreshEditData();
          });
        }
      }
    });
  };

  const handleExcelFile=(file,index)=>{
    const newExcelData = [...excelData];
    newExcelData[index].file = file;
    setExcelData(newExcelData);
  }

  const handleExcelType=(type,index)=>{
    setExcelData(prevExcelData => {
      const newExcelData = [...prevExcelData];
      newExcelData[index] = {
          ...newExcelData[index],
          excelType: type
      };
      return newExcelData;
  });
  }
  const handleDeleteExcelOnEdit = (key,spId,index) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const sendData = {
          id: editData._id,
          key: key,
          index:index,
          spId:spId || ""
        };
        dispatch(fetchAsyncDeleteExcel(sendData)).then((res) => {
          const newExcelData = res?.payload?.embrillProfile?.excelData
          setExcelData(newExcelData);
        });
      }
    });
  };


  const handleDelete = (index)=>{
    setExcelData((prev) => (prev.filter((excel, i) => i !== index)
    ));
  }

  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
        top="0" // Position it at the top
        margin="0 auto" // Center it horizontally
        bottom="0"
        className="add-form-modal-content"
        >
        <div className="fixed-header">
          <ModalHeader>{isEditForm ? "Edit" : "Add"} Embrill Profile Details</ModalHeader>
          <ModalCloseButton />
          </div>
          <ModalBody>
            <Form onSubmit={handleSubmit} className="addForm">

              <div className="form-card">
                <h5>Bank Details</h5>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Account Number <span className="span-star">*</span></Form.Label>
                    <Form.Control
                      type="text"
                      name="accountNumber"
                      placeholder="Enter Account Number"
                      value={formData.accountNumber}
                      onChange={handleChange}
                      isInvalid={!!errors.accountNumber}
                    />
                    </Box>
                    <p className="form_error"> { errors.accountNumber}</p>

                   
                  </Col>
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>IFSC Code <span className="span-star">*</span></Form.Label>
                    

                    <Form.Control
                      type="text"
                      name="ifscCode"
                      placeholder="Enter IFSC Code"
                      value={formData.ifscCode}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.ifscCode}
                    />
                    </Box>
                      <p className="form_error"> {errors.ifscCode}</p>

                   
                  </Col>
                </Row>
                <Row>
                  
                  <Col>
                  <Box display="flex" className="input-box">
                    <Form.Label>Bank Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="bankName"
                      placeholder="Enter Bank Name"
                      value={formData.bankName}
                      onChange={handleChange}
                    />
                   </Box>
                  </Col>
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Branch Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="branchName"
                      placeholder="Enter Branch Name"
                      value={formData.branchName}
                      onChange={handleChange}
                    />
                   </Box>
                  </Col>
                </Row>
               
               
               
              </div>
              <div className="form-card mt-3">
                <h5>Bank Contact Person Details</h5>
                <Row>
                 
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Contact Person Name</Form.Label>
                    <Form.Control
                      type="text"
                      name="contactPerson"
                      placeholder="Enter Name"
                      value={formData.contactPerson}
                      onChange={handleChange}
                    />
                  </Box>
                  </Col>
                  <Col></Col>
                </Row>
                <Row>
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      name="email"
                      placeholder="Enter Email"
                      value={formData.email}
                      onChange={handleChange}
                      isInvalid={!!errors.email}
                    />
                    </Box>
                    <p className="form_error">{errors.email}</p>

                   
                  </Col>
                  <Col>
                  <Box display="flex" className="input-box">

                    <Form.Label>Phone Number</Form.Label>
                    <Form.Control
                      type="text"
                      name="phone"
                      placeholder="Enter Phone Number"
                      value={formData.phone}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      isInvalid={!!errors.phone}
                    />
                    </Box>
                    <p className="form_error"> {errors.phone}</p>

                  </Col>
                </Row>
               
              </div>
              {isEdit &&
              <div className="form-card mt-3">
              <Box display="flex" alignItems="center">

              <h5>Payment Details</h5>
               <Button className="add-project mt-2" onClick={addExcelDetail}
               >
                     <FaPlusCircle 
                     className="add-project-icon"
                     size={15}
                     />
                     
                </Button>
              </Box>
              {excelData && excelData?.length > 0 && excelData.map((excel, index) => (
               <ExcelComponent 
               key={`invoiceData`}
               expenseType={'embrill-profile'}
               getExcel={(file) =>
                 handleExcelFile(file,index)
               }
               selectedFile={excel}
               excelData={excelData}
               handleExcelType={(type)=>handleExcelType(type,index)}
               excelType = {excel?.excelType}
              
               handleDeleteOnEdit={(key,spId) => {
                handleDeleteExcelOnEdit(key,spId,index);
               }}
               handleDelete={()=>{handleDelete(index)}}
               isEditing={isEdit}
               addNewExcelOnEdit={(file) => {
                addNewExcelOnEdit(file, index);
               }}
               role = {role}
               editData={editData}
              />
              ))}
              <Row>
              <Col>
              
              </Col>
              <Col></Col>
              </Row>
              </div >
              }
              <ModalFooter className="mt-3">
                <Button
                  type="submit"
                  className="submit-btn"
                  variant="brand"
                  style={{ background: "#191063" }}
                >
                  Submit
                </Button>
              </ModalFooter>
            </Form>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default EmbrillProfileAddNew;
