/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { modalAnatomy as parts } from "@chakra-ui/anatomy";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Tooltip,
  Box,
} from "@chakra-ui/react";
import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import "rsuite/dist/rsuite.min.css";
import {
  createMultiStyleConfigHelpers,
  defineStyle,
} from "@chakra-ui/styled-system";
import { useFormik } from "formik";
import { createListForSingleOption } from "./createableSelect";
import { createOption } from "./createableSelect";
import { useDispatch, useSelector } from "react-redux";
import PaymentInfoComponent from "./paymentInfoComponent";
import { fetchAsyncAddInternalExpense } from "redux/internalSlice";
import moment from "moment";
import { useToasts } from "react-toast-notifications";
import { fetchAsyncGetPONumInternalExpense } from "redux/internalSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import Swal from "sweetalert2";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import CustomSelect from "components/CustomSelect";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncUpdateInternalExpense } from "redux/internalSlice";
import { fetchAsyncDeleteInternalPdf } from "redux/internalSlice";
import { fetchAsyncUploadInternalPdf } from "redux/internalSlice";
import { fetchAsyncAddInternalInvoiceRemark } from "redux/internalSlice";
import { fetchAsyncGetSubCategory } from "redux/formOptions";
import { fetchAsyncCreateSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteSubCategory } from "redux/formOptions";
import { fetchAsyncGetPONumTDSExpense } from "redux/tdsSlice";
import calculateTds from "jsFunctions/calculateTds";
import { fetchAsyncCreateMinorSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteMinorSubCategory } from "redux/formOptions";
import { fetchAsyncGetMinorSubCategory } from "redux/formOptions";
import { fetchAsyncSendRequestApprovalTotalPayAmount } from "redux/internalSlice";
import { fetchAsyncSendRequestUpdateApprovalTotalPayAmount } from "redux/internalSlice";
import { fetchAsyncApproveTotalPayAmount } from "redux/internalSlice";
import { fetchAsyncRejectTotalPayAmount } from "redux/internalSlice";
import { checkTotalApprovedAmountWithSpentAmount } from "jsFunctions/helperFunctions";
import { checkApprovalAmount } from "jsFunctions/helperFunctions";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { fetchAsyncEditSubCategory } from "redux/formOptions";
import { fetchAsyncEditMinorSubCategory } from "redux/formOptions";
import { fetchAsyncDeleteInternalPaymentListItem } from "redux/internalSlice";
import { checkSpentAmountisEmpty } from "jsFunctions/helperFunctions";
import { fetchAsyncGetTDSExpenseByRefrencePO } from "redux/tdsSlice";
import { InputGroup, OverlayTrigger, Popover, Tab, Tabs } from "react-bootstrap";
import getINRTotal from "jsFunctions/getINRAmount";
// import { Option } from "antd/es/mentions";
import CurrencyFormat from 'react-currency-format';
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { fetchAsyncGetGstCompanyExpense } from "redux/resourceSlice";
import { internalFormValidation } from "validation";
// modal style
import ClientProjectSelect from "components/ClientProjectSelect";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import GstInfoComponent from "./gstInfoComponent";
import { fetchAsyncDeleteInternalGstListItem } from "redux/internalSlice";
import RefundInfoListComponent from "./refundListInfoComponent";
import { fetchAsyncDeleteInternalRefundListItem } from "redux/internalSlice";
import { fetchAsyncGetClient } from "redux/clientProjectSlice";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";
import QuotationForm from "./seprateFormComponent/quatationForm";
import { FaCheck, FaDollarSign, FaFileUpload, FaInfo, FaPlusCircle } from "react-icons/fa";
import { fetchAsyncGetAllQuotation } from "redux/quotationSlice";
import { fetchAsyncGetVendor } from "redux/vendorSlice";
import DownloadExcelFile from "./DownloadExcel/downloadExcel";
import InternalDataChangeModal from "components/models/DataChange/internalDataChangeModal";
import { calculateTotalGst } from "jsFunctions/calculateGst";
import SpinnerLoader from "components/Spinner/SpinnerLoader";
import { getOptionDataOnEdit } from "jsFunctions/calculateGst";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const xl = defineStyle({
  px: "6",
  py: "2",
  fontSize: "xl",
});

const sm = defineStyle({
  fontSize: "sm",
  py: "6",
});

const sizes = {
  xl: definePartsStyle({ header: sm, dialog: xl }),
};

export const modalTheme = defineMultiStyleConfig({
  sizes,
});
const InternalAddNew = ({
  openInternalModal,
  closeInternalModal,
  isEditForm = false,
  editItem,
  refreshEditData,
  activeList,
  role
}) => {
  const formOptionsList1 = useSelector((state) => state.formOptions);
  const subCategoryList1 = useSelector((state) => state.subCategory);

  const [poPrefix, setPoPrefix] = useState({
    prefix: "PR-I-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [formOptionsList, setFormOptionsList] = useState([])
  const [subCategoryList, setSubCategoryList] = useState([])
  const [isEdit, setIsEdit] = useState(isEditForm)
  const [editData, setEditData] = useState(editItem)

  const [clientOPtion, setClientOption] = useState([]);
  const [merchentOption, setMerchentOPtion] = useState([]);
  const [minorTypeOption, setMinorTypeOption] = useState([]);
  const [officeTypeOption, setOfficeTypeOption] = useState([]);

  const [expenseOption, setExpeneOption] = useState([]);
  const [agencyOption, setAgencyOPtion] = useState([]);
  const [minorSubCategoryOption, setMinorSubCategoryOption] = useState([]);
  const [currencyOption, setCurrencyOption] = useState([]);
  const [totalAmount, setTotalAmount] = useState('');
  const [INRVal, setINRVal] = useState();
  const [convertedVal, setConvertedVal] = useState("");
  const [getPoMonth, setGetPoMonth] = useState(moment().format("yyyy-MM"));
  const [generatedNum, setGeneratedNum] = useState("PR-I-");

  const [isFreight, setIsFreight] = useState(false);
  const [clientValue, setClientValue] = useState("");
  const [minorTypeValue, setMinorTypeValue] = useState("");
  const [officeTypeValue, setOfficeTypeValue] = useState("");

  const [merchantValue, setMerchantValue] = useState("");
  const [expenseValue, setExpenseValue] = useState("");
  const [agencyValue, setAgencyValue] = useState("");
  const [minorSubCategoryValue, setMinorSubCategoryValue] = useState("");

  const [approveOrRejectRemarks, setApproveOrRejectRemarks] = useState('')
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [invoiceDate, setInvoiceDate] = useState(new Date());
  const [reimbursePaymentDate, setReimbursePaymentDate] = useState(new Date());
  const [currencyValue, setCurrencyValue] = useState("");
  const [totalPaymentAmount, setTotalPaymentAmount] = useState(0);
  const [approvalAmount, setApprovalAmount] = useState('');
  const [remarksValue, setRemarksValue] = useState('');
  const [multipleAddGstInfo, setMultipleAddGstInfo] = useState([""]);
  const [taxableAmount, setTaxableAmount] = useState("");
  const [isLoading, setIsLoading] = useState(false)

  const [errors, setErrors] = useState({})
  const [oldMerchantName, setOldMerchantName] = useState("")

  const [sendPayAmountRequest, setSendPayAmountRequest] = useState(false);
  const [openQuotation, setOpenQuotation] = useState(false)

  const [quotationData, setQuotationData] = useState({
    remarks:"",
    amount:"",
    files:""
  })



  const [spentAmount, setSpentAmount] = useState('');
  const [remainingAmount, setRemainingAmount] = useState()
  const [allQuotation,setAllQuotation] = useState([])

  const [totalSpentAmount, setTotalSpentAmount] = useState(0)
  const [totalRefundAmount, setTotalRefundAmount] = useState(0)

  const [multipleInvoiceData, setMultipleInvoiceData] = useState([""]);
  const [multipleAddPaymentInfo, setMultipleAddPaymentInfo] = useState([""]);
  const [multipleAddRefundInfo, setMultipleAddRefundInfo] = useState([""]);


  // data  change modal
  const [compareDataModal, setCompareDataModal] = useState(false);
  const [comparingData, setComparingData] = useState(null);

  // TDS

  const [isTds, setIsTds] = useState(false);
  const [isGst, setIsGst] = useState(!editData?.paymentInfo?.totalPayAmountIsApproved);

  const [amountTotal, setAmountTotal] = useState('');
  const [tdsAmount, setTdsAmount] = useState(0);
  const [editTDSAmount, setEditTDSAmount] = useState(true);
  const [tdsPercent, setTdsPercent] = useState(10);
  const [editPercentNum, setEditPercentNum] = useState(true);
  const [isGenerateTDSPo, setIsGenerateTDSPo] = useState(false);
  const [generatedTDSpoNum, setGeneratedTDSpoNum] = useState("PR-T-");
  const [fullTdsData, setFullTdsData] = useState({})
  const [tdsPoPrefix, setTdsPoPrefix] = useState({
    prefix: "PR-T-",
    month: new Date().getMonth() + 1,
    year: new Date().getFullYear(),
    number: 1,
  });
  const [totalPayAmountIsApproved, setTotalPayAmountIsApproved] = useState(false);
  const currUser = useSelector((state) => state.auth.currUser);
  const clientData = useSelector((state) => state.clientData);
  const vendorList = useSelector((state) => state.vendorData);

  const { addToast } = useToasts();
  const dispatch = useDispatch();

  useEffect(() => {
    setGeneratedNum(poNumberFormate(poPrefix));
  }, [poPrefix]);

  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === 'internal')
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(()=>{
    if(subCategoryList1 && subCategoryList1?.length > 0){
      const internalSubCategoryList = subCategoryList1.filter((item)=> item.expenseType === 'internal')
      setSubCategoryList(internalSubCategoryList)
    }
  },[subCategoryList1])

  useEffect(() => {
    dispatch(fetchAsyncGetClient())
    dispatch(fetchAsyncGetVendor());

  }, []);


  const getAllMerchantOptions = async()=>{
    const filteredVendors = vendorList.filter(vendor => vendor.isVendor === true);

      const companyOption = await clientProjectOptions('companyName','_id',filteredVendors) 
      setMerchentOPtion(companyOption);
  }
  useEffect(()=>{
    if(vendorList){
      getAllMerchantOptions()
    }
  },[vendorList])
  useEffect(() => {
    if (!isEdit && getPoMonth) {
      const dataSplit = getPoMonth.split("-");
      dispatch(
        fetchAsyncGetPONumInternalExpense({
          month: dataSplit[1],
          year: dataSplit[0],
        })
      )
        .then((res) => {
          let newNumber = 1;
          if (res.payload?.[0]?.poNo) {
            newNumber = res.payload?.[0]?.poNo.number + 1;
          }
          setPoPrefix((prev) => {
            return {
              prefix: prev.prefix,
              month: dataSplit[1],
              year: dataSplit[0],
              number: newNumber,
            };
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [getPoMonth]);


  useEffect(()=>{
    if(!totalPayAmountIsApproved && taxableAmount){

      setAmountTotal(taxableAmount)
     
    }
  },[taxableAmount])


  const initialValues = {
    poNo: "",
    orderMonth: null,
    orderDate: null,
    clientName: "",
    projectName: "",
    merchantName: "",
    remarks: "",
    expenseType: "",
    isExpense: false,
    agency: "",
    trackingNum: editData?.expense?.trackingNum ? editData?.expense?.trackingNum : "",
    totalPayAmount: 0,
    approvalAmount: 0,
    spentAmount:0,
    payee: "",
    paymentAmount: 0,
    bank: "",
    referenceNo: "",
    paymentdate: null,
    
    invoiceNum: editData?.reimbursementInfo?.invoiceNum || "",
    invoiceDate: null,
    isReimburse:
      editData?.reimbursementInfo?.isReimbursement?.isReimburs || false,
    isRefund:
      editData?.refundInfo?.isRefund|| false,
    reimbursePaymentDate: null,
    currency: "",
    reimburseAmount: 0,
    conversionRate: 0,
    amountInINR: 0,
    invoice: "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validationSchema: internalFormValidation,

    onSubmit: (values, { resetForm }) => {
      
      if (!clientValue  || !minorTypeValue || !merchantValue || !expenseValue || !officeTypeValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          clientValue:'Client name is required!',
          minorTypeValue:'Minor type is required!',
          merchantValue:'Merchant name is required!',
          expenseValue:'Major type is required!',
          officeTypeValue:'Office is required!',
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        })     
        addToast("Please fill all mandatory fields", { appearance: "error" });
  
       return;
      }
      
      // const checkSpent = checkSpentAmountisEmpty(
      //   multipleAddPaymentInfo,
      // );
      // if (!checkSpent.status) {
      //   addToast(checkSpent.msg, { appearance: "error" });
      //   return;
      // }
      const checkApproval = checkApprovalAmount(
        approvalAmount,
      );
      if (!checkApproval.status) {
        setErrors({
          ...errors,
          approvalAmount:checkApproval.msg
        })         
        return;
      }
      const filteredMultipleAddPaymentInfo = multipleAddPaymentInfo.filter((item) => item?.amount);

      const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
      const checkValid = checkTotalApprovedAmountWithSpentAmount(
        total_pay_amount,
        filteredMultipleAddPaymentInfo
      );
      if (!checkValid.status) {
        setErrors({
          ...errors,
          approvalAmount:checkValid.msg
        })        
        return;
      }
      if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
        return;
      }
      let formData = new FormData();
      formData.append("poNo", JSON.stringify(poPrefix));
      formData.append("orderMonth", getPoMonth);
      formData.append("orderDate", selectedDate);
      formData.append("clientName", clientValue.value || "");
      formData.append("minorType", minorTypeValue.value);
      formData.append("officeType", officeTypeValue.value);
      formData.append("minorTypeCategory", minorSubCategoryValue.value ? minorSubCategoryValue.value : '');
      formData.append("merchantId", merchantValue.value || "");
      formData.append("remarks", remarksValue);
      formData.append("expenseType", expenseValue.value);
      formData.append("officeId", officeTypeValue?.id || "");
      formData.append("majorId", expenseValue.id || "");
      formData.append("minorId", minorTypeValue?.id || "");
      formData.append("minorSubCategoryId", minorSubCategoryValue?.id || "");
      formData.append("agencyId", agencyValue?.id || "");

      formData.append("isExpense", isFreight);
      formData.append("agency", agencyValue.value);
      formData.append("trackingNum", values.trackingNum);
      formData.append("totalPaymentAmount", totalPaymentAmount);
      formData.append("taxableAmount", taxableAmount);

      formData.append("approvalAmount", approvalAmount);

      formData.append("spentAmount", spentAmount);

      const filteredMultipleAddPaymentInfoPay = filteredMultipleAddPaymentInfo.map((item)=>{
        if(item?.invoice){
          return {
            ...item,
            invoice: item?.invoice.filter(entry => 'Key' in entry)
          }
        }
        else{
          return item
        }
      });
      
      formData.append("payInfo", JSON.stringify(filteredMultipleAddPaymentInfoPay));
      // TDS data
      formData.append("isTDS", isTds);
      formData.append("isGST", isGst);

      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
      formData.append("isTDSPO", isGenerateTDSPo);
      formData.append("TDSPONum", JSON.stringify(tdsPoPrefix));
      formData.append("invoiceNum", values.invoiceNum);
      

      formData.append("invoiceDate", invoiceDate);
      formData.append("isReimburs", values.isReimburse);
      formData.append("reimbursementPaymentDate", reimbursePaymentDate);
      currencyValue?.value && formData.append("currency", currencyValue?.value);
      formData.append("currencyId", currencyValue?.id || "");

      totalAmount && formData.append("reimburseAmount", totalAmount);
      INRVal && formData.append("conversionRate", INRVal);
      convertedVal && formData.append("amountInINR", convertedVal);
      formData.append("gstInfo", JSON.stringify(multipleAddGstInfo));
      formData.append("isRefund", values.isRefund);
      
      const filteredMultipleAddRefundInfoPay = multipleAddRefundInfo.filter((item) => item?.amount);

      formData.append("refundInfo", JSON.stringify(filteredMultipleAddRefundInfoPay));

      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
        formData.append('quotation_remarks', quotationData?.remarks);
       formData.append('quotation_amount', quotationData?.amount);
       Array.from(quotationData?.files).forEach((file) => {
           formData.append('quotation_files', file);
       });

     }
      if (isEdit) {
        setComparingData(formData);
      } else {
        multipleInvoiceData.forEach((element, i) => {
          formData.append(`file${i}`, element);
        });

        dispatch(fetchAsyncAddInternalExpense(formData)).then((res) => {
          if (res.payload) {
            addToast("Successfully Added", { appearance: "success" });
            closeInternalModal();
            resetForm();
          }
        });
      }
    },
  });

  const finalRef = useRef(null);

  const getAllClientOptions = async()=>{
    if(clientData){

      const clientOption = await clientProjectOptions('clientName','_id',clientData) 
      setClientOption(clientOption);
    }
  }
  // form list option
 
  useEffect(() => {
    getAllClientOptions()

    let officeTypeList = createListForSingleOption(
      formOptionsList,
      "office-type"
    );
    
    let agencyList = createListForSingleOption(formOptionsList, "agency");
    let expenseList = createListForSingleOption(formOptionsList, "expense");
    let currencyList = createListForSingleOption(formOptionsList, "currency");

    setOfficeTypeOption(officeTypeList)

    setAgencyOPtion(agencyList);
    setExpeneOption(expenseList);
    setCurrencyOption(currencyList);

  }, [formOptionsList]);

  useEffect(() => {
    // fill data in form on edit
    if (isEdit && editData) {
      if (formOptionsList?.length > 0 || formOptionsList1?.length > 0  ) {
        editFormProfiledData(formOptionsList, editData);
      }
      getAllQuotation(editData?._id)
      setPoPrefix(editData.poNo);
      setSelectedDate(new Date(editData.orderDate));
      setGetPoMonth(
        `${editData.poNo.year}-${
          editData.poNo.month < 9
            ? "0" + editData.poNo.month
            : editData.poNo.month
        }`
      );

      if(editData?.merchantName){
        setOldMerchantName(editData?.merchantName)
      }
      if(editData?.merchantId && editData?.merchantId?._id){
        getMerchantByID(editData?.merchantId?._id)
      }
      else if(editData?.merchantName){
        const setData = {
          label: editData?.merchantName,
          value: editData?.merchantName,
        };
        setMerchantValue(setData);
      }
     
      setIsFreight(editData?.expense?.isExpense || false);
      if (editData.paymentInfo?.totalPayAmount) {
        setTotalPaymentAmount(editData.paymentInfo?.totalPayAmount);
      }
      if (editData.paymentInfo?.approvalAmount) {
        setApprovalAmount(editData.paymentInfo?.approvalAmount);
      }
      if (editData.paymentInfo?.taxableAmount) {
        setTaxableAmount(editData.paymentInfo?.taxableAmount);
      }
      if (editData.paymentInfo?.spentAmount) {
        setSpentAmount(editData.paymentInfo?.spentAmount);
      }
      if (editData.paymentInfo?.list && editData.paymentInfo?.list.length > 0) {
        setMultipleAddPaymentInfo(editData.paymentInfo?.list);
      }
      if (editData.refundInfo?.list && editData.refundInfo?.list.length > 0) {
        setMultipleAddRefundInfo(editData.refundInfo?.list);
      }
      if (editData.gstApplicable?.gstId && editData.gstApplicable?.gstId?.length > 0) {
        setMultipleAddGstInfo(editData.gstApplicable?.gstId);
      }
      if (editData.paymentInfo?.payAmountApproveIsRequest) {
        setSendPayAmountRequest(true)
      }
      if (editData?.remarks) {
        setRemarksValue(editData?.remarks)
      }
      if (editData?.approveRejectRemarks) {
        setApproveOrRejectRemarks(editData?.approveRejectRemarks)
      }
    
      
       if(editData?.paymentInfo?.totalPayAmountIsApproved){
        setTotalPayAmountIsApproved(true)
      }
     
     
      if (editData.reimbursementInfo?.invoiceDate) {
        setInvoiceDate(new Date(editData.reimbursementInfo.invoiceDate));
      }
      if(editData.tdsApplicable?.tdsPercent) {

        setTdsPercent(editData.tdsApplicable?.tdsPercent);
      }
      if (editData.reimbursementInfo?.isReimbursement) {
        const { paymentDate, amount, conversionRate, amountInINR } =
          editData.reimbursementInfo?.isReimbursement;
        paymentDate && setReimbursePaymentDate(new Date(paymentDate));
        setTotalAmount(amount);
        setINRVal(conversionRate);
        setConvertedVal(amountInINR);
        const currencyData = getOptionDataOnEdit(
          formOptionsList,
          editData.reimbursementInfo?.isReimbursement?.currency,
          editData?.reimbursementInfo?.isReimbursement?.currencyId?._id,
          "currency"
        );
        if (currencyData) {
          setCurrencyValue({
            label: currencyData.label,
            value: currencyData.value,
            id: currencyData?._id
          });
        }
      }
      if (editData?.gstApplicable && editData?.gstApplicable?.isGST) {
        setIsGst(editData?.gstApplicable?.isGST);
      }
     
      
     

      if(editData?._id){
        dispatch(fetchAsyncGetTDSExpenseByRefrencePO(editData?._id)).then((res)=>{
          const tdsData = res?.payload?.tdsExpense
          if (res?.payload && tdsData) {
            if (tdsData?.referenceResourcePO) {
              setIsTds(editData.tdsApplicable?.isTDS);
              setAmountTotal(tdsData?.totalBillingAmount);
              setTdsAmount(tdsData?.tdsAmount);
              setTdsPercent(tdsData?.tdsPercent);
              setIsGenerateTDSPo(true);
              setGeneratedTDSpoNum(poNumberFormate(tdsData?.poNo));
            }
            setFullTdsData(tdsData);
          } else {
            if (editData.tdsApplicable?.isTDS) {
              setIsTds(editData.tdsApplicable?.isTDS);
              editData.tdsApplicable?.totalAmount &&
                setAmountTotal(editData.tdsApplicable?.totalAmount);
              editData.tdsApplicable?.totalTDS &&
                setTdsAmount(editData.tdsApplicable?.totalTDS);
              editData.tdsApplicable?.tdsPercent &&
                setTdsPercent(editData.tdsApplicable?.tdsPercent);
            }
            
            
          }
        }).catch((err)=>{
          console.log(err)
        })
      }
     
     
      if (editData.invoice?.length > 0) {
        setMultipleInvoiceData([...editData.invoice]);
      } else {
        setMultipleInvoiceData([]);
      }
    }
  }, [isEdit, editData, formOptionsList]);
  // create option
  useEffect(() => {
    if(currencyValue && currencyValue?.value !== 'INR'){
      setConvertedVal(getINRTotal(totalAmount, INRVal));
    }
    else{
      setConvertedVal(totalAmount);
      setINRVal(1)
    }
  }, [INRVal, totalAmount, currencyValue]);

  const handleInvoiceChange = (data) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === data?.id) {

        let invoice = []

        if(item?.invoice){
           if (item?.invoice?.length < 5) {
          invoice = [
            ...item?.invoice,
            { data: "", invNumber: data.data }
          ]
        }
        else{
          invoice = item?.invoice
        }
        }
        else{
          invoice = [
            { data: "", invNumber: data.data }
          ]
        }

        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const closeComparedDataModal = () => {
    setCompareDataModal(false);

    setComparingData(null);
  };
  const handleUpdateInvoiceData = (invoiceData, index) => {
    const updateList = multipleInvoiceData.map((item, i) => {
      if (i === index) {
        return invoiceData;
      } else return item;
    });
    setMultipleInvoiceData([...updateList]);
  };

  const handelDeleteInvoice = (index,id) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === id) {

        let invoice = []
        if(item?.invoice){

          invoice = item?.invoice.filter((item, i) => i !== index)
        }
        
        return {...item,invoice};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };
  const handleMultiplePayInfo = (data) => {
    setMultipleAddPaymentInfo([
      ...multipleAddPaymentInfo,
      { data: "", paydata: data.data ,invoice:[]},
    ]);
  };
  const handleUpdateMultiplePayData = (payData, index) => {
    const updateList = multipleAddPaymentInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddPaymentInfo([...updateList]);
  };


  const handleMultipleRefundInfo = (data) => {
    setMultipleAddRefundInfo([
      ...multipleAddRefundInfo,
      { data: "", paydata: data.data },
    ]);
  };
  const handleUpdateMultipleRefundData = (payData, index) => {
    const updateList = multipleAddRefundInfo.map((item, i) => {
      if (i === index) {
        return {...item,...payData};
      } else return item;
    });
    setMultipleAddRefundInfo([...updateList]);
  };
  const handleUpdateMultipleGstData = async(gstData, index) => {
    let gstNum = ""
    const companyId = gstData?.companyName?.value
    if (companyId) {
      await dispatch(fetchAsyncGetVendorByID(companyId)).then((res)=>{

        if(res?.payload){
          const {gstNo} = res?.payload?.vendor;  
          gstNum = gstNo;
        }
        }).catch((err)=>{
          console.log(err)
        })
    }
    
    const gstFullData = {
      baseAmount:gstData?.baseAmount,
      companyId:companyId,
      gstNumber:gstNum,
      gstAmount:gstData?.gstAmount || {},
      gstType: gstData?.gstType,
      gstPercentage: gstData?.gstPercentage
    }
    
    const updateList = multipleAddGstInfo.map((item, i) => {
      if (i === index) {
        return {...item,gstFullData};
      } else return item;
    });
    

    setMultipleAddGstInfo([...updateList]);
  };
   // payment info function
   const handleMultipleGstInfo = (data) => {
    setMultipleAddGstInfo([
      ...multipleAddGstInfo,
      ""
    ]);
  };

  const handleAmountApproveBtn = (values)=>{   
  if(!isEdit){

    if ((sendPayAmountRequest && !clientValue)  || !minorTypeValue || (sendPayAmountRequest && !merchantValue) || !expenseValue || !officeTypeValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
      setErrors({
        ...errors,
        clientValue:sendPayAmountRequest ? 'Client name is required!':"",
        minorTypeValue:'Minor type is required!',
        merchantValue:sendPayAmountRequest ? 'Merchant name is required!': "",
        expenseValue:'Major type is required!',
        officeTypeValue:'Office is required!',
        minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
      })

      addToast("Please fill all mandatory fields", { appearance: "error" });
      return;
    }
  }
  
  const checkApproval = checkApprovalAmount(
    approvalAmount,
  );
  if (!checkApproval.status) {
    setErrors({
      ...errors,
      approvalAmount:checkApproval.msg
    }) 
    return;
  }

  if(approvalAmount <= totalPaymentAmount && remainingAmount >= 0 ){
    const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)

    const checkValid = checkTotalApprovedAmountWithSpentAmount(
      total_pay_amount,
      multipleAddPaymentInfo
    );
    if (!checkValid.status) {
      setErrors({
        ...errors,
        approvalAmount:checkValid.msg
      })      
      return;
    }
  }
  if(remainingAmount < 0 && approvalAmount <= totalPaymentAmount){
    return;
  }
    let formData = new FormData();
    
    formData.append("poNo", JSON.stringify(poPrefix));
    formData.append("orderMonth", getPoMonth);
    formData.append("orderDate", selectedDate);
    formData.append("clientName", clientValue.value || "");
    formData.append("minorType", minorTypeValue.value);
    formData.append("officeType", officeTypeValue.value);
    formData.append("minorTypeCategory", minorSubCategoryValue.value);
    formData.append("merchantId", merchantValue.value || "");
    formData.append("remarks", remarksValue);
    formData.append("expenseType", expenseValue.value);
    formData.append("isExpense", isFreight);
    formData.append("agency", agencyValue.value);
    formData.append("trackingNum", values.trackingNum);
    formData.append("totalPaymentAmount", totalPaymentAmount);
    formData.append("taxableAmount", taxableAmount);

    formData.append("approvalAmount", approvalAmount);
    formData.append("officeId", officeTypeValue.id || "");
    formData.append("majorId", expenseValue.id || "");
    formData.append("minorId", minorTypeValue.id || "");
    formData.append("minorSubCategoryId", minorSubCategoryValue.id || "");
    formData.append("agencyId", agencyValue?.id || "");

    formData.append("isTDS", isTds);
    formData.append("isGST", isGst);
   
      amountTotal && formData.append("totalAmount", amountTotal);
      formData.append("tdsPercent", tdsPercent);
      !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
      formData.append("isTDSPO", isGenerateTDSPo);
      formData.append("TDSPONum", JSON.stringify(tdsPoPrefix));
      formData.append("invoiceNum", values.invoiceNum);
      formData.append("invoiceDate", invoiceDate);
      formData.append("isReimburs", values.isReimburse);
      formData.append("reimbursementPaymentDate", reimbursePaymentDate);
      currencyValue?.value && formData.append("currency", currencyValue?.value);
      formData.append("currencyId", currencyValue?.id || "");

      totalAmount && formData.append("reimburseAmount", totalAmount);
      INRVal && formData.append("conversionRate", INRVal);
      formData.append("isRefund", values.isRefund);
      formData.append("gstInfo", JSON.stringify(multipleAddGstInfo));


      convertedVal && formData.append("amountInINR", convertedVal);


      if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
         formData.append('quotation_remarks', quotationData?.remarks);
        formData.append('quotation_amount', quotationData?.amount);
        Array.from(quotationData?.files).forEach((file) => {
            formData.append('quotation_files', file);
        });

      }
    if(isEdit && editData){
      setIsLoading(true)

      dispatch(fetchAsyncSendRequestUpdateApprovalTotalPayAmount
        ({ 
        id: editData._id,
        data:formData
       })).then(
        (res) => {
          if (res.payload && res.payload.updatedData) {
            setIsEdit(true)
            setEditData(res.payload.updatedData)
            setSendPayAmountRequest(true)
            addToast(`Resend updated request for approval for expense ${generatedNum} !`, { appearance: "success" });
            setIsLoading(false)
            closeInternalModal('pending');
          }
          else{
            addToast(res?.error.message, { appearance: "error" });
            setIsLoading(false)
          }
        }).catch((err)=>{
          addToast(err.message, { appearance: "error" });
          setIsLoading(false)
        })
    }
    else{
      setIsLoading(true)
      dispatch(fetchAsyncSendRequestApprovalTotalPayAmount(formData)).then((res) => {
          if (res.payload) {
            addToast(`Send request for approval for expense ${generatedNum} !`, { appearance: "success" });
            setIsEdit(true)
            setEditData(res.payload)
            setSendPayAmountRequest(true)
            setIsLoading(false)
            closeInternalModal('pending');
          }
          else{
            addToast(res?.error.message, { appearance: "error" });
            setIsLoading(false)
          }
         
        }).catch((err)=>{
          addToast(err.message, { appearance: "error" });
          setIsLoading(false)
        })
    }
    
    
    }
  




  // create new optionFunction
  const createNewFormOption = (data, type, subCategory = false) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        subCategory,
        expenseType:'internal'

      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
            id: res.payload?._id
          };
          

          
          if (type === "office-type") {
            setOfficeTypeValue(setData);
          }
         

         
          if (type === "currency") {
            setCurrencyValue(setData);
          }
          if (type === "expense") {
            setExpenseValue(setData);
            setMinorTypeValue("");
            setMinorSubCategoryValue("")
          }

          if (type === "agency") {
            setAgencyValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",
            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Ok",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {});
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Ok",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {});
      });
  };

  const deleteFromOption = (option, type) => {
    
      let data = formOptionsList.find(
        (item) => item._id === option.id && item.type === type
      );
    
    
    if (data?._id) {
      dispatch(fetchAsyncGetSubCategory(data._id)).then(
        (response) => {
          if (response.payload) {
            const minorOption = response.payload || []
          
            if (minorOption && minorOption?.length > 0) {
              addToast(`Please delete all related Minor Type first`, { appearance: "error" });
            }
            else{
              dispatch(fetchAsyncDeleteFormOptions(data._id)).then((res)=>{
                console.log(res,'response')
              });
            }
          }
        }
      );
    }
   
  };

  const editFromOption = (option,newValue, type) => {
   
      let data = formOptionsList.find(
        (item) => item._id === option._id && item.type === type
      );
    
    
   
  
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:'internal'
      },
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
          id:res.payload._id
        };
       
        
        if (type === "office-type") {
          setOfficeTypeValue(setData);
        }
        
        if (type === "currency") {
          setCurrencyValue(setData);
        }

       

        if (type === "expense") {
          setExpenseValue(setData);
        }

        if (type === "agency") {
          setAgencyValue(setData);
        }
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };


  

  // TDS functions
  useEffect(() => {
    if( amountTotal && tdsPercent ){

      setTdsAmount(calculateTds(amountTotal, tdsPercent));
    }
    else{
      setTdsAmount(0)
    }
  }, [amountTotal, tdsPercent]);

  useEffect(() => {
    if (isEdit && editData) {
      if (!fullTdsData?.referenceResourcePO) {
        setGeneratedTDSpoNum(poNumberFormate(tdsPoPrefix));
      }
    } else {
      setGeneratedTDSpoNum(poNumberFormate(tdsPoPrefix));
    }
  }, [tdsPoPrefix, isGenerateTDSPo]);

  // TDS PO BY Resource
  useEffect(() => {
    const dataSplit = getPoMonth.split("-");
    const year =
      parseInt(dataSplit[1]) === 12
        ? parseInt(dataSplit[0]) + 1
        : parseInt(dataSplit[0]);
    let month = parseInt(dataSplit[1]) === 12 ? 1 : parseInt(dataSplit[1]) + 1;
    dispatch(
      fetchAsyncGetPONumTDSExpense({
        month: month,
        year: year,
      })
    )
      .then((res) => {
        if (res.payload?.[0]?.poNo) {
          if(fullTdsData?.referenceResourcePO){
            setTdsPoPrefix((prev) => {
              return {
                prefix: res.payload?.[0]?.poNo.prefix,
                month: res.payload?.[0]?.poNo.month,
                year: res.payload?.[0]?.poNo.year,
                number: res.payload?.[0]?.poNo.number,
              };
            });
          }
          else{

            setTdsPoPrefix((prev) => {
              return {
                prefix: prev.prefix,
                month: month,
                year: year,
                number: res.payload?.[0]?.poNo.number + 1,
              };
            });
          }
        } else {
          setTdsPoPrefix((prev) => {
            return {
              prefix: prev.prefix,
              month: month,
              year: year,
              number: 1,
            };
          });
        }
      })
      .catch((err) => {
        console.log("error", err);
      });
  }, [isGenerateTDSPo, getPoMonth]);

  const handleEditTDSPercentange = () => {
    setEditPercentNum(false);
  };
  const handleEditTDS = () => {
    setEditTDSAmount(false);
  };
  const handleEditSubmit = () => {
    setEditTDSAmount(true);
  };

  // Changes on Edit

  const editFormProfiledData = (formOptionsList, editData) => {
    const clientData = editData?.clientName

    if (clientData) {
      setClientValue({ label: clientData.clientName, value: clientData._id });
    }
   
    const officeData = getOptionDataOnEdit(
      formOptionsList,
      editData?.officeType,
      editData?.officeId?._id,
      "office-type"
    );
    if (officeData && !officeTypeValue) {
      setOfficeTypeValue({
        label: officeData.label,
        value: officeData.value,
        id: officeData?._id
      });
    }
    
   
    const expense = getOptionDataOnEdit(
      formOptionsList,
      editData?.expense?.majorType,
      editData?.majorId?._id,
      "expense"
    );
    if (expense && !expenseValue) {
      setExpenseValue({
        label: expense.label,
        value: expense.value,
        id: expense?._id
      });
    }
    if (editData?.expense?.isExpense) {
      const agencyData = getOptionDataOnEdit(
        formOptionsList,
        editData?.expense?.agency,
        editData?.expence?.agencyId?._id,
        "agency"
      );
      console.log(agencyData,'agency')
      if (agencyData && !agencyValue) {
        setAgencyValue({
          label: agencyData.label,
          value: agencyData.value,
          id: agencyData?._id
        });
      }
    }
  };


  const submitUpdateData = (formData, editData) => {
    setIsLoading(true)

    dispatch(
      fetchAsyncUpdateInternalExpense({ id: editData._id, data: formData })
    )
      .then((res) => {
        if (res.payload) {
          addToast(`Expense ${generatedNum} updated successfully!`, { appearance: "success" });
        }
        closeInternalModal('approved');
        formik.resetForm();
        setIsLoading(false)
      })
      .catch((error) => {
        setIsLoading(false)
        addToast("Error", { appearance: "error" });
      });
  };

  const handleCloseQuotation = ()=>{
    setOpenQuotation(!openQuotation)

    if (isEdit && editData) {
    getAllQuotation(editData?._id)
    }
  }

  const getAllQuotation = async(_id)=>{
    await dispatch(fetchAsyncGetAllQuotation(_id)).then((res)=>{

        if(res?.payload){
            const quotations = res.payload?.quotations;
            setAllQuotation(quotations)
        }
        }).catch((err)=>{
          console.log(err)
        })
}

const getMerchantByID = async(id)=>{
  await dispatch(fetchAsyncGetVendorByID(id)).then((res)=>{

  if(res?.payload){
    const vendor = res?.payload?.vendor;
    const setData = {
      label: vendor.companyName,
      value: vendor?._id,
    };
          
    setMerchantValue(setData);
  }
  }).catch((err)=>{
    console.log(err)
  })
}


 

  const handleDeleteInvoiceOnEdit = (key,listIndex,spId) => {
    Swal.fire({
      title: "Are you sure? Delete File Permanently.",
      text: `Delete File ${key}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const sendData = {
          id: editData._id,
          key: key,
          listIndex:listIndex,
          spId: spId || ""
        };
        dispatch(fetchAsyncDeleteInternalPdf(sendData)).then((res) => {
          if(res?.payload && res?.payload?.internalExpense?.paymentInfo?.list){

            setMultipleAddPaymentInfo(res?.payload?.internalExpense?.paymentInfo?.list)
            refreshEditData();
            setIsLoading(false)
            addToast(`Invoice Deleted successfully!`, { appearance: "success" });
          }
          else{
            setIsLoading(false)
            addToast(`Invoice Not Deleted!`, { appearance: "error" });
          }

        }).catch((error) => {
          addToast(`Invoice Not Deleted!`, { appearance: "error" });
          setIsLoading(false);
      });
      }
    });
  };

  const addNewInvoiceOnEdit = (file, index,listIndex) => {
    Swal.fire({
      title: "Are you sure? Upload File Directly.",
      text: `Upload - ${file.name}`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Submit",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        if (editData) {
          const lastInvoiceKey =
            editData.invoice?.length > 0 &&
            editData.invoice[editData.invoice.length - 1].Key;
         
          let formData = new FormData();
          formData.append("poNo", JSON.stringify(editData.poNo));
          formData.append("file", file);
          formData.append("merchantId", merchantValue.value || "");
          formData.append("listIndex", listIndex);
          formData.append("index", index);
          const listItem = multipleAddPaymentInfo[listIndex];
          formData.append("listItem", JSON.stringify(listItem));

          dispatch(
            fetchAsyncUploadInternalPdf({ id: editData._id, formData })
          ).then((res) => {
            if(res?.payload && res?.payload?.internalExpense?.paymentInfo?.list){
              setMultipleAddPaymentInfo(res?.payload?.internalExpense?.paymentInfo?.list)
              refreshEditData();
              setIsLoading(false)
              addToast(`Invoice Uploaded successfully!`, { appearance: "success" });
            }
            else{
              setIsLoading(false);
              addToast(`Invoice Not Uploaded!`, { appearance: "error" });

            }
          }).catch((error) => {
            addToast(`Invoice Not Uploaded!`, { appearance: "error" });
            setIsLoading(false);
        });
        }
      }
    });
  };


  const handleRemarksChange = (e)=>{
    const value = e?.target?.value;
    const encoder = new TextEncoder(); // Default is 'utf-8'
    const byteLength = encoder.encode(value).length;
    if(byteLength <=128){

      setRemarksValue(value)
      setErrors({
        ...errors,
        remarks:''
      })
    }
    else{
      setErrors({
        ...errors,
        remarks:'Remarks must be within the 128 byte limit!'
      })
    }
  }
  // Set SubCategory for selected minorype value
  useEffect(() => {
    if (minorTypeValue?.id) {
      dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.id)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload || []
            const minorSubOpt = minorSubOption.map((item)=>{
              return {
                ...item,
                id:item?._id
              }
            })
            setMinorSubCategoryOption(minorSubOpt);
            setMinorSubCategoryValue("");
            if (isEdit && editData && minorSubOpt?.length > 0) {
              const subCat = minorSubOpt.find(
                (opt) => opt.id === editData?.minorSubCategoryId?._id
              );
              if (subCat) {
                setMinorSubCategoryValue({
                  label: subCat?.label,
                  value: subCat?.value,
                  id: subCat?._id
                });
              }
            }
          }
        }
      );
    }
  }, [minorTypeValue]);

  
   // Set MinorCategory for selected majorType value
   useEffect(() => {
    if (expenseValue?.id) {
      dispatch(fetchAsyncGetSubCategory(expenseValue?.id)).then(
        (response) => {
          if (response.payload) {
            const minorOptions = response.payload ;
            const minorOpt = minorOptions.map((item)=>{
              return {
                ...item,
                id:item?._id
              }
            })
            setMinorTypeOption(minorOpt);
            
            setMinorTypeValue("");
            if (isEdit && editData && minorOpt?.length > 0) {
              const subCat = minorOpt.find(
                (opt) => opt.id === editData?.minorId?._id 
              );
              if (subCat) {
                setMinorTypeValue({
                  label: subCat?.label,
                  value: subCat?.value,
                  id: subCat?._id
                });
              }
            }
          }
        }
      );
    }
  }, [expenseValue]);


  const createMinorSubCategory = (category, minorTypeData) => {
    const option = subCategoryList.find((data) => data._id === minorTypeData?.id);
    if (!option) return;

    dispatch(fetchAsyncCreateMinorSubCategory(
      { 
        id: option._id, 
        category,
        expenseType:'internal'
 
      })).then(
      (res) => {
        if (res.payload && res.payload.value) {
          setMinorSubCategoryOption((prev) => [
            ...prev,
            {id:res.payload._id, label: res.payload.label, value: res.payload.value ,expenseType:res.payload?.expenseType},
          ]);
          setMinorSubCategoryValue({
            label: res.payload.label,
            value: res.payload.value,
            id:res.payload._id
          });
        }
      }
    );
  };

  const deleteMinorSubCategory = (option) => {
    const id = option?._id;
    dispatch(fetchAsyncDeleteMinorSubCategory(id)).then((res) => {
      if (res.payload && res.payload.value) {
        setMinorSubCategoryOption((prev) => [
          ...prev.filter((cat) => cat.value !== res.payload.value),
        ]);
      }
    });
  };

  const createSubCategory = (category, expenseData) => {

    const option = formOptionsList.find((data) => data._id === expenseData?.id);
    if (!option) return;

    dispatch(fetchAsyncCreateSubCategory(
      { 
        id: option._id, 
        category,
        expenseType:'internal'
 
      })).then(
      (res) => {
        if (res.payload && res.payload.value) {
          setMinorTypeOption((prev) => [
            ...prev,
            {id:res.payload._id, label: res.payload.label, value: res.payload.value,expenseType:res.payload?.expenseType },
          ]);
          setMinorTypeValue({
            label: res.payload.label,
            value: res.payload.value,
            id: res.payload._id
          });
        }
      }
    );
  };

  const deleteSubCategory = (option) => {
    const id = option?._id;
    const value = option?.value;
    if (id) {
      dispatch(fetchAsyncGetMinorSubCategory(id)).then(
        (response) => {
          if (response.payload) {
            const minorSubOption = response.payload || []

            if (minorSubOption  && minorSubOption?.length > 0) {
              addToast(`Please delete all related Minor Sub Category first`, { appearance: "error" });
            }
            else{
              dispatch(fetchAsyncDeleteSubCategory(id)).then((res) => {
                if (res.payload && res.payload.value) {
                  setMinorTypeOption((prev) => [
                    ...prev.filter((cat) => cat.value !== res.payload.value),
                  ]);
                }
              });
            }
          }
        }
      );
    }
   
  };

  const editSubCategory = (option,newValue) => {
    dispatch(fetchAsyncEditSubCategory({
      id:option?._id,
      newData:{
        optionValue:newValue,
        expenseType:'internal'
      },

    })).then((res)=>{
      if (res.payload && res.payload.value) {
        if (expenseValue?.id) {
          dispatch(fetchAsyncGetSubCategory(expenseValue.id)).then(
            (response) => {
              if (response.payload) {
                const minorOption = response.payload || []
                const minorOpt = minorOption.map((item)=>{
                  return {
                    ...item,
                    id:item?._id
                  }
                })
                setMinorTypeOption(minorOpt);
                if (minorOpt.length > 0) {
                  const subCat = minorOpt.find(
                    (opt) => opt.id === option?._id
                  );
                  if (subCat) {
                    setMinorTypeValue({
                      label: subCat?.label,
                      value: subCat?.value,
                      id: subCat?._id
                    });
                  }
                }
              }
            }
          );
        }
      }
    }).catch((err)=>{
      console.log(err)
    })
  };

  const editMinorSubCategory = (option,newValue) => {
    dispatch(fetchAsyncEditMinorSubCategory({
      id:option?._id,
      newData:{
        optionValue:newValue,
        expenseType:'internal'
      },

    })).then((res)=>{
      if (res.payload && res.payload.value) {
        if (minorTypeValue?.id) {
          dispatch(fetchAsyncGetMinorSubCategory(minorTypeValue.id)).then(
            (response) => {
              if (response.payload) {
                const minorSubOption = response.payload || []
                const minorSubOpt = minorSubOption.map((item)=>{
                  return {
                    ...item,
                    id:item?._id
                  }
                })
                setMinorSubCategoryOption(minorSubOpt);
                if (minorSubOpt?.length > 0) {
                  const subCat = minorSubOpt.find(
                    (opt) => opt.id === option?._id 
                  );
                  if (subCat) {
                    setMinorSubCategoryValue({
                      label: subCat?.label,
                      value: subCat?.value,
                      id: subCat?._id
                    });
                  }
                }
              }
            }
          );
        }
      }
    }).catch((err)=>{
      console.log(err)
    })
  };
  // Changes on Edit End

  // add Remark for Invoice
  const addRemarkForInvoice = (value, item,listIndex,index) => {
    dispatch(
      fetchAsyncAddInternalInvoiceRemark({
        id: editData._id,
        remarkData: { key: item.Key, remark: value, listIndex, index },
      })
    ).then((res) => {
      setMultipleAddPaymentInfo(res?.payload?.internalExpense?.paymentInfo?.list)

      refreshEditData();
    });
  };

  const closeModelForm = () => {
    Swal.fire({
      title: "Are you sure you want to close without saving?",
      text: `You will lost all current changes`,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Close",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        closeInternalModal();
      }
    });
  };

  const handleTotalPayAmountIsApproved = (values)=>{
    if(isEdit){

      if ((sendPayAmountRequest && !clientValue)  || !minorTypeValue || (sendPayAmountRequest && !merchantValue) || !expenseValue || !officeTypeValue || (minorSubCategoryOption && minorSubCategoryOption?.length && !minorSubCategoryValue)) {
        setErrors({
          ...errors,
          clientValue:sendPayAmountRequest ? 'Client name is required!':"",
          minorTypeValue:'Minor type is required!',
          merchantValue:sendPayAmountRequest ? 'Merchant name is required!': "",
          expenseValue:'Major type is required!',
          officeTypeValue:'Office is required!',
          minorSubCategoryValue:minorSubCategoryOption && minorSubCategoryOption?.length ? 'Minor sub category is required!': ''
        })
  
        addToast("Please fill all mandatory fields", { appearance: "error" });
        return;
      }
    }
    const _id = editData._id;
    let formData = new FormData();

    formData.append("poNo", JSON.stringify(poPrefix));
    formData.append("orderMonth", getPoMonth);
    formData.append("orderDate", selectedDate);
    formData.append("clientName", clientValue.value ? clientValue.value : '');
    formData.append("minorType", minorTypeValue.value);
    formData.append("officeType", officeTypeValue.value);
    formData.append("minorTypeCategory", minorSubCategoryValue.value);
    formData.append("merchantId", merchantValue.value || "");
    formData.append("remarks", remarksValue);
    formData.append("expenseType", expenseValue.value);
    formData.append("taxableAmount", taxableAmount);

    formData.append("isExpense", isFreight);
    formData.append("agency", agencyValue.value);
    formData.append("trackingNum", values.trackingNum);
    formData.append("totalPaymentAmount", totalPaymentAmount);
    formData.append("approvalAmount", approvalAmount);
    formData.append("approveRejectRemarks", approveOrRejectRemarks);
    formData.append("officeId", officeTypeValue.id || "");
    formData.append("majorId", expenseValue.id || "");
    formData.append("minorId", minorTypeValue.id || "");
    formData.append("minorSubCategoryId", minorSubCategoryValue.id || "");
    formData.append("agencyId", agencyValue?.id || "");

    formData.append("isTDS", isTds);
    formData.append("isGST", isGst);

    amountTotal && formData.append("totalAmount", amountTotal);
    formData.append("tdsPercent", tdsPercent);
    !isNaN(tdsAmount) && formData.append("totalTDS", tdsAmount);
    formData.append("isTDSPO", isGenerateTDSPo);
    formData.append("TDSPONum", JSON.stringify(tdsPoPrefix));
    formData.append("invoiceNum", values.invoiceNum);
    formData.append("invoiceDate", invoiceDate);
    formData.append("isReimburs", values.isReimburse);
    formData.append("reimbursementPaymentDate", reimbursePaymentDate);
    currencyValue?.value && formData.append("currency", currencyValue?.value);
    formData.append("currencyId", currencyValue?.id || "");

    totalAmount && formData.append("reimburseAmount", totalAmount);
    INRVal && formData.append("conversionRate", INRVal);
    formData.append("gstInfo", JSON.stringify(multipleAddGstInfo));

    formData.append("isRefund", values.isRefund);


    convertedVal && formData.append("amountInINR", convertedVal);


    if(quotationData  && (quotationData?.files?.length > 0 || quotationData?.remarks)){
      formData.append('quotation_remarks', quotationData?.remarks);
     formData.append('quotation_amount', quotationData?.amount);
     Array.from(quotationData?.files).forEach((file) => {
         formData.append('quotation_files', file);
     });

   }
      if(!editData.paymentInfo?.totalPayAmountIsApproved){
        Swal.fire({
          title: `Are You Sure? You Want To Approve Amount <span style="color: green"><span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)}</span> For <span style="color:orange">${generatedNum}</span>.`,
          text: `Please confirm to proceed with the approval.`,
          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Approve",
          showCancelButton: true,
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch(fetchAsyncApproveTotalPayAmount({ 
              id: _id,
              data:formData
             })).then(
              (res) => {
                if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsApproved) {
                  addToast(`Requested Amount for ${generatedNum} has been successfully approved.`, { appearance: "success" });
                  setIsLoading(false)
                  closeInternalModal('approved');
                }
                
              }
            );
          }
        });
       
      }
    
  }
  const handleTotalPayAmountIsRejected = ()=>{

    const _id = editData._id;
  
      if(!editData.paymentInfo?.totalPayAmountIsApproved &&  !editData.paymentInfo?.totalPayAmountIsRejected){
        Swal.fire({
          title: `Are You Sure? You Want To Reject Amount <span style='color:orange'> <span style="font-family: sans-serif"">₹</span>  ${formatAmountIndianRupees(approvalAmount)} </span> For <span style="color:orange">${generatedNum}.</span>`,
          text: `Please confirm to reject. This action cannot be undone.`,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Reject",
          showCancelButton: true,
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            setIsLoading(true)
            dispatch(fetchAsyncRejectTotalPayAmount({ 
              id: _id,
              data:{totalPayAmountIsRejected:true,approveRejectRemarks:approveOrRejectRemarks}
             })).then(
              (res) => {
                if (res.payload && res.payload.updatedData && res.payload.updatedData.paymentInfo?.totalPayAmountIsRejected) {
                  addToast(`Requested Amount for ${generatedNum} has been successfully rejected.`, { appearance: "success" });
                  setIsLoading(false)
                  closeInternalModal('rejected');
                }
                
              }
            );
          }
        });
       
      }
    
  }



  useEffect(()=>{
    if(multipleAddRefundInfo) {
      const array = multipleAddRefundInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalRefundAmount(parseFloat(sum))
        
      }
    }
    
  },[multipleAddRefundInfo])



  useEffect(()=>{
    if(multipleAddPaymentInfo) {
      const array = multipleAddPaymentInfo;
      if(array.length > 0){

        const sum = array.reduce((acc, current) => {
          if (current?.amount && current?.amount !== '') {
            return acc + parseFloat(current?.amount);
          } else {
            return acc;
          }
        }, 0);
        setTotalSpentAmount(parseFloat(sum))
        const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
        setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
      }
    }
    
  },[multipleAddPaymentInfo])

  useEffect(()=>{
    if(totalPaymentAmount){
      if(totalPaymentAmount && !editData?.tdsApplicable?.totalTDS && isTds){
        setIsTds(true)
        setAmountTotal(totalPaymentAmount)
        setTdsAmount(tdsAmount);
        setTdsPercent(tdsPercent);
      }
      else{
        if(totalPaymentAmount && editData?.tdsApplicable?.totalTDS && isTds){
          if(editData?._id){
            dispatch(fetchAsyncGetTDSExpenseByRefrencePO(editData?._id)).then((res)=>{
              const tdsData = res?.payload?.tdsExpense
              if (res?.payload && tdsData) {
                if (tdsData?.referenceResourcePO) {
                  setIsTds(editData.tdsApplicable?.isTDS);
                  setAmountTotal(tdsData?.totalBillingAmount);
                  setTdsAmount(tdsData?.tdsAmount);
                  setTdsPercent(tdsData?.tdsPercent);
                  setIsGenerateTDSPo(true);
                  setGeneratedTDSpoNum(poNumberFormate(tdsData?.poNo));
                }
                setFullTdsData(tdsData);
              } else {
                if (editData.tdsApplicable?.isTDS) {
                  setIsTds(editData.tdsApplicable?.isTDS);
                  editData.tdsApplicable?.totalAmount &&
                    setAmountTotal(editData.tdsApplicable?.totalAmount);
                  editData.tdsApplicable?.totalTDS &&
                    setTdsAmount(editData.tdsApplicable?.totalTDS);
                  editData.tdsApplicable?.tdsPercent &&
                    setTdsPercent(editData.tdsApplicable?.tdsPercent);
                }
                
                
              }
            }).catch((err)=>{
              console.log(err)
            })
          }
        }
      }
    }
    else{
      setAmountTotal(taxableAmount)
    }

  },[isTds])

  const handleQuotationData = (data)=>{
    setQuotationData(data)
  }
  useEffect(()=>{
    if(totalPaymentAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          setTotalSpentAmount(parseFloat(sum))

          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[ totalPaymentAmount])
  
  useEffect(()=>{
    if(totalPaymentAmount){
      if(multipleAddPaymentInfo) {
        const array = multipleAddPaymentInfo;
        if(array.length > 0){
  
          const sum = array.reduce((acc, current) => {
            if (current?.amount && current?.amount !== '') {
              return acc + parseFloat(current?.amount);
            } else {
              return acc;
            }
          }, 0);
          setTotalSpentAmount(parseFloat(sum))

          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount) - parseFloat(sum))
        }
        else{
          const total_pay_amount = parseFloat(totalPaymentAmount) - parseFloat(tdsAmount)
          setRemainingAmount(parseFloat(total_pay_amount))
        }
      }

    }
  },[tdsAmount])

  useEffect(()=>{
    if(!isTds){

      setAmountTotal(0)
      setTdsAmount(0)
      // setTdsPercent(10)
      setIsTds(false)

    }
  },[!isTds])

  const deleteMultipleListItem = (listIndex)=>{
      Swal.fire({
        title: "Are you sure? Delete Amount Details Permanently.",
        text: ``,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true)
          const sendData = {
            id: editData._id,
            listIndex:listIndex
          };
          dispatch(fetchAsyncDeleteInternalPaymentListItem(sendData)).then((res) => {
            setMultipleAddPaymentInfo(res?.payload?.internalExpense?.paymentInfo?.list)
            refreshEditData();
            setIsLoading(false)
          });
        }
      });
  }

  const deleteMultipleRefundListItem = (listIndex)=>{
    Swal.fire({
      title: "Are you sure? Delete Refund Amount Details Permanently.",
      text: ``,
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsLoading(true)
        const sendData = {
          id: editData._id,
          listIndex:listIndex
        };
        dispatch(fetchAsyncDeleteInternalRefundListItem(sendData)).then((res) => {
          setMultipleAddRefundInfo(res?.payload?.internalExpense?.refundInfo?.list)
          refreshEditData();
          setIsLoading(false)

        });
      }
    });
}

  const removeItemFromState = (index) => {
    // Update your local state to remove the item at the specified index
    setMultipleAddGstInfo(prevState => prevState.filter((_, i) => i !== index));
  }
  const deleteMultipleGstItem = (id,index)=>{
    if(id){
  
      Swal.fire({
        title: "Are you sure? Delete Amount Details Permanently.",
        text: ``,
        icon: "warning",
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Delete",
        showCancelButton: true,
        customClass: {
          container: "sweet_containerImportant",
          confirmButton: "sweet_confirmbuttonImportant",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          setIsLoading(true)

          dispatch(fetchAsyncDeleteInternalGstListItem(id)).then((res) => {
            refreshEditData();
            removeItemFromState(index);
            setIsLoading(false)

          });
        }
      });
    }
    else{
      removeItemFromState(index)
    }
  }

  const hasAccess = useCallback(() => {
    return (role === "Admin" || role === "Manager") && totalPayAmountIsApproved;
  }, [role, totalPayAmountIsApproved]);
  
  
  useEffect(()=>{
    if(!sendPayAmountRequest){
      if(taxableAmount && multipleAddGstInfo && multipleAddGstInfo?.length > 0){
        const gstArray = multipleAddGstInfo.map(item => {
          if (item?.gstFullData) {
              return item?.gstFullData; 
          } else {
              return { gstType: '', gstAmount: {} }; 
          }
      });      
        const totalGst = calculateTotalGst(gstArray)
        const totalRequestedAmt = parseFloat(taxableAmount) + parseFloat(totalGst)
        setApprovalAmount(totalRequestedAmt)
      }
    }
  },[taxableAmount,multipleAddGstInfo])
  
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        Amount Spent: ${200}
      </Popover.Body>
    </Popover>
  );
  return (
    <>
      <Modal
        finalFocusRef={finalRef}
        isOpen={openInternalModal}
        onClose={closeModelForm}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent
          top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
          className="add-form-modal-content"
        >
          <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
            Internal - <span className="header-pr">{generatedNum}</span>
            </ModalHeader>
            <ModalCloseButton />
          </div>
          {isLoading ?
            <SpinnerLoader/>
            :
          <ModalBody>
            <Form className="addForm" onSubmit={formik.handleSubmit}>
              <div className="form-card">
                <h5 className="mt-0">Purchase Order Details</h5>
                <Row className="mt-2">
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Number <span className="span-star">*</span>
                      </Form.Label>

                      <Form.Control
                        type="text"
                        placeholder="No"
                        value={generatedNum && generatedNum}
                        disabled
                      />
                    </Box>
                  </Col>

                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order - Month <span className="span-star">*</span>
                      </Form.Label>

                      <DatePicker
                        className="monthDate"
                        dateFormat="MM-yyyy"
                        showMonthYearPicker
                        name="orderMonth"
                        onChange={(e) => {
                          const dateTime = moment(e).format("yyyy-MM");
                          setGetPoMonth(dateTime);
                        }}
                        disabled={isEdit}
                        // defaultValue={new Date()}
                        selected={
                          getPoMonth ? new Date(moment(getPoMonth)) : ""
                        }
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Order Date <span className="span-star">*</span>
                      </Form.Label>

                      <DatePicker
                        name="orderDate"
                        className="monthDate"
                        dateFormat="dd-MMM-yyyy"
                        // disabled={isEdit}
                        onChange={(date) => {
                          setSelectedDate(date);
                        }}
                        selected={selectedDate}
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                      />
                    </Box>
                  </Col>
                  <Col className="custom-select"></Col>
                </Row>
              </div>
              <div className="form-card mt-3">
                <h5>Expense</h5>
                <Row>
                  <Col className="custom-select">
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Office <span className="span-star">*</span>
                      </Form.Label>

                      <CustomSelect
                        options={officeTypeOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setOfficeTypeValue(newValue)}
                        isSearchable
                        onCreateOption={(e) => {
                          createNewFormOption(
                            createOption(e),
                            "office-type",
                            true
                          );
                        }}
                        onDeleteOption={(option) => {
                          deleteFromOption(option, "office-type");
                        }}
                        onEditOption={(option, newValue) => {
                          editFromOption(option, newValue, "office-type");
                        }}
                        defaultValue={officeTypeValue}
                        role={role}
                        isInvalid={errors?.officeTypeValue && !officeTypeValue}
                      />
                    </Box>
                    {errors?.officeTypeValue && !officeTypeValue && (
                      <p className="form_error">{errors?.officeTypeValue}</p>
                    )}
                  </Col>
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Major Type <span className="span-star">*</span>
                      </Form.Label>

                      <CustomSelect
                        options={expenseOption}
                        placeHolder={<div>Type or select...</div>}
                        onChange={(newValue) => setExpenseValue(newValue)}
                        isSearchable
                        onCreateOption={(e) => {
                          createNewFormOption(createOption(e), "expense");
                        }}
                        onDeleteOption={(option) => {
                          deleteFromOption(option, "expense");
                        }}
                        onEditOption={(option, newValue) => {
                          editFromOption(option, newValue, "expense");
                        }}
                        defaultValue={expenseValue}
                        role={role}
                        isInvalid={errors?.expenseValue && !expenseValue}
                      />
                    </Box>
                    {errors?.expenseValue && !expenseValue && (
                      <p className="form_error">{errors?.expenseValue}</p>
                    )}
                  </Col>
                </Row>
                {expenseValue && (
                  <Row>
                    <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>
                          Minor Type <span className="span-star">*</span>
                        </Form.Label>

                        <CustomSelect
                          options={minorTypeOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setMinorTypeValue(newValue)}
                          isSearchable
                          onCreateOption={(e) => {
                            if (expenseValue) {
                              createSubCategory(
                                createOption(e),
                                expenseValue
                              );
                            } else {
                              addToast("Please Select Major Type", {
                                appearance: "error",
                              });
                            }
                          }}
                          onDeleteOption={(option) => {
                            deleteSubCategory(option);
                          }}
                          onEditOption={(option, newValue) => {
                            editSubCategory(option, newValue);
                          }}
                          defaultValue={minorTypeValue}
                          role={role}
                          isInvalid={errors?.minorTypeValue && !minorTypeValue}
                        />
                      </Box>
                      {errors?.minorTypeValue && !minorTypeValue && (
                        <p className="form_error">{errors?.minorTypeValue}</p>
                      )}
                    </Col>
                    {minorTypeValue ? (
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>
                            Minor Sub Category{" "}
                            <span className="span-star">
                              {minorSubCategoryOption &&
                              minorSubCategoryOption?.length
                                ? "*"
                                : ""}
                            </span>
                          </Form.Label>

                          <CustomSelect
                            options={minorSubCategoryOption}
                            placeHolder={<div>Type or select...</div>}
                            onChange={(newValue) =>
                              setMinorSubCategoryValue(newValue)
                            }
                            isSearchable
                            onCreateOption={(e) => {
                              if (minorTypeValue) {
                                createMinorSubCategory(
                                  createOption(e),
                                  minorTypeValue
                                );
                              } else {
                                addToast("Please Select Minor Type", {
                                  appearance: "error",
                                });
                              }
                            }}
                            onDeleteOption={(option) => {
                              deleteMinorSubCategory(option);
                            }}
                            onEditOption={(option, newValue) => {
                              editMinorSubCategory(option, newValue);
                            }}
                            defaultValue={minorSubCategoryValue}
                            role={role}
                            isInvalid={
                              errors?.minorSubCategoryValue &&
                              !minorSubCategoryValue
                            }
                          />
                        </Box>
                        {errors?.minorSubCategoryValue &&
                          !minorSubCategoryValue && (
                            <p className="form_error">
                              {errors?.minorSubCategoryValue}
                            </p>
                          )}
                      </Col>
                    ) : (
                      <Col></Col>
                    )}
                  </Row>
                )}
                {minorTypeValue && (
                  <Row>
                    <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>
                          Merchant Name{" "}
                          {sendPayAmountRequest && (
                            <span className="span-star">*</span>
                          )}
                        </Form.Label>

                        <ClientProjectSelect
                          options={merchentOption}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setMerchantValue(newValue)}
                          isSearchable
                          defaultValue={merchantValue}
                          role={role}
                        />
                      </Box>
                      {oldMerchantName && (
                        <Form.Text className="text-muted previous_text">
                          <Box className="previous-text">
                            Previous Merchant Name : {oldMerchantName}
                          </Box>
                        </Form.Text>
                      )}
                      {errors?.merchantValue && !merchantValue && (
                        <p className="form_error">{errors?.merchantValue}</p>
                      )}
                    </Col>
                    <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>
                          Client Name{" "}
                          {sendPayAmountRequest && (
                            <span className="span-star">*</span>
                          )}
                        </Form.Label>

                        <ClientProjectSelect
                          options={clientOPtion}
                          placeHolder={<div>Type or select...</div>}
                          onChange={(newValue) => setClientValue(newValue)}
                          isSearchable
                          defaultValue={clientValue}
                          role={role}
                          isInvalid={errors?.clientValue && !clientValue}
                        />
                      </Box>
                      {errors?.clientValue && !clientValue && (
                        <p className="form_error">{errors?.clientValue}</p>
                      )}
                    </Col>
                  </Row>
                )}

                
                <Row  >
                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>Remarks </Form.Label>

                      <Form.Control
                        as="textarea" // Change input to textarea
                        rows={2}
                        placeholder="Type Here..."
                        name="remarks"
                        value={remarksValue}
                        // defaultValue={remarksValue}
                        onChange={(e) => handleRemarksChange(e)}
                        isInvalid={!!errors?.remarks}

                        // {...formik.getFieldProps("remarks")}
                      />
                    </Box>
                    <p className="form_error">{errors?.remarks}</p>
                  </Col>
                </Row>
              </div>
              <div className="form-card mt-3">
                <h5>Payment Information</h5>
                <Row>
                <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Taxable Amount
                      </Form.Label>

                      <InputGroup className="input-group">
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>
                        <CurrencyFormat
                          customInput={Form.Control}
                          className="amount-input"
                          type="text"
                          onValueChange={(e) => {
                            setTaxableAmount(e.value);
                          }}
                          name="taxableAmount"
                          value={taxableAmount || ""}

                          placeholder="Taxable Amount"
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                       
                      </InputGroup>
                    </Box>
                   
                 </Col>

                  <Col>
                    <Box display="flex" className="input-box">
                      <Form.Label>
                        Requested Amount <span className="span-star">*</span>
                      </Form.Label>

                      <InputGroup className="input-group">
                        <InputGroup.Text>
                          <span className="rupee-symbol">₹</span>{" "}
                        </InputGroup.Text>
                        <CurrencyFormat
                          customInput={Form.Control}
                          className="amount-input"
                          type="text"
                          disabled={
                            sendPayAmountRequest &&
                            !totalPayAmountIsApproved &&
                            (role === "Admin" || role === "Manager") &&
                            currUser.name !== editData?.paymentInfo?.requestedBy
                          }
                          name="approvalAmount"
                          placeholder="Requested Amount"
                          value={approvalAmount || ""}
                          onValueChange={(e) => {
                            setApprovalAmount(e.value);
                          }}
                          isInvalid={
                            (remainingAmount < 0 &&
                              approvalAmount <= totalPaymentAmount) ||
                            (!approvalAmount && errors?.approvalAmount)
                          }
                          thousandSeparator={true}
                          // prefix={"$"}
                          decimalScale={2}
                          fixedDecimalScale={true}
                        />
                        <InputGroup.Text
                          className="quotation-upload"
                          onClick={() => {
                            approvalAmount && setOpenQuotation(!openQuotation);
                          }}
                        >
                          <Tooltip
                            label="Upload Quotation"
                            fontSize="md"
                            borderRadius="2xl"
                          >
                            <div
                              disabled={!approvalAmount}
                              className="position-relative"
                            >
                              <FaFileUpload size={20} />
                              <span className="position-absolute top-0 start-100 translate-middle badge  bg-primary text-white">
                                {quotationData?.files || quotationData?.remarks
                                  ? allQuotation?.length + 1
                                  : allQuotation?.length || 0}
                              </span>
                            </div>
                          </Tooltip>
                        </InputGroup.Text>
                      </InputGroup>
                    </Box>
                    {((remainingAmount < 0 &&
                      approvalAmount <= totalPaymentAmount) ||
                      !approvalAmount) && (
                      <p className="form_error">{errors?.approvalAmount}</p>
                    )}
                  </Col>
                 
                </Row>
                {totalPayAmountIsApproved && (
                  <>
                    
                    <Row>
                    <Col>
                      <Box display="flex" className="input-box">
                        <Form.Label>Approved Amount</Form.Label>
                        <InputGroup>
                          <InputGroup.Text>
                            <span className="rupee-symbol">₹</span>{" "}
                          </InputGroup.Text>
                          <CurrencyFormat
                            customInput={Form.Control}
                            className="amount-input"
                            type="text"
                            disabled
                            name="totalPayAmount"
                            placeholder="Total Amount"
                            value={totalPaymentAmount ? totalPaymentAmount : 0}
                            thousandSeparator={true}
                            // prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                        </InputGroup>
                      </Box>
                    </Col>
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Net Payable Amount</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <span className="rupee-symbol">₹</span>{" "}
                            </InputGroup.Text>
                            <CurrencyFormat
                              customInput={Form.Control}
                              className="amount-input"
                              type="text"
                              disabled
                              name="Net Payable Amount"
                              value={remainingAmount ? remainingAmount : 0}
                              thousandSeparator={true}
                              decimalScale={2}
                              isInvalid={
                                remainingAmount < 0 &&
                                approvalAmount <= totalPaymentAmount
                              }
                              fixedDecimalScale={true}
                            />
                          </InputGroup>
                        </Box>
                        {remainingAmount < 0 &&
                          approvalAmount <= totalPaymentAmount && (
                            <p className="form_error">
                              Net payable amount cannot be negative. Please
                              revise the request with an increased requested
                              amount and resubmit for approval.
                            </p>
                          )}
                      </Col>
                    </Row>
                    <Row>
                    <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Total Spent Amount</Form.Label>
                          <InputGroup>
                            <InputGroup.Text>
                              <span className="rupee-symbol">₹</span>{" "}
                            </InputGroup.Text>
                            <CurrencyFormat
                              customInput={Form.Control}
                              className="amount-input"
                              type="text"
                              disabled
                              name="totalPayAmount"
                              placeholder="Total Amount"
                              value={
                                parseFloat(totalSpentAmount) -
                                parseFloat(totalRefundAmount)
                              }
                              thousandSeparator={true}
                              // prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                          </InputGroup>
                        </Box>
                      </Col>
                      <Col></Col>
                    </Row>
                  </>
                )}
              </div>
             
                <div>
                  <Tabs
                    defaultActiveKey={totalPayAmountIsApproved ? 'spent' : 'gst'}
                    transition={false}
                    id="noanim-tab-example"
                    className="add-form-tabs"
                  >
                     {
                      totalPayAmountIsApproved &&
                    <Tab
                      eventKey="spent"
                      title="Spent Amount"
                      className="add-form-tab mx-0"
                    >
                      <div className="form-card">
                        {totalPayAmountIsApproved &&
                          multipleAddPaymentInfo.map((item, index) => {
                            return (
                              <PaymentInfoComponent
                                key={`paymentInfo-${index}`}
                                getData={(data) => {
                                  handleUpdateMultiplePayData(data, index);
                                }}
                                autoCount={index}
                                paymentData={item}
                                editData={editData}
                                refreshEditData={refreshEditData}
                                isEdit={isEdit}
                                handleInvoiceChange={(item) =>
                                  handleInvoiceChange(item)
                                }
                                multipleInvoiceData={
                                  item?.invoice ? item?.invoice : [""]
                                }
                                handleUpdateInvoiceData={(item, index) =>
                                  handleUpdateInvoiceData(item, index)
                                }
                                handelDeleteInvoice={(index, id) =>
                                  handelDeleteInvoice(index, id)
                                }
                                handleDeleteInvoiceOnEdit={(key, listIndex,spId) =>
                                  handleDeleteInvoiceOnEdit(key, listIndex,spId)
                                }
                                addNewInvoiceOnEdit={(file, index, listIndex) =>
                                  addNewInvoiceOnEdit(file, index, listIndex)
                                }
                                remainingAmount={remainingAmount}

                                addRemarkForInvoice={(
                                  value,
                                  item,
                                  listIndex,
                                  index
                                ) =>
                                  addRemarkForInvoice(
                                    value,
                                    item,
                                    listIndex,
                                    index
                                  )
                                }
                                deleteMultipleListItem={() =>
                                  deleteMultipleListItem(index)
                                }
                                expenseType="internal"
                                role={role}
                              />
                            );
                          })}
                        <Box display="flex" cursor="pointer" alignItems="center" onClick={() => {
                              handleMultiplePayInfo(
                                multipleAddPaymentInfo.length + 1
                              );
                            }}>
                          <h5>Add Spent Amount</h5>
                          <Button
                            className="add-project mt-2 mx-1"
                            minWidth={0}
                            
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                      </div>
                    </Tab>
                    }
                    {hasAccess() && (
                      <Tab
                        eventKey="excel"
                        title="Download Excel"
                        className="add-form-tab"
                      >
                        <DownloadExcelFile
                          merchantName={merchantValue}
                          prNo={generatedNum}
                          payAmount={totalPaymentAmount}
                          role={role}
                        />
                      </Tab>
                    )}
                    <Tab
                        eventKey="gst"
                        title={
                          <OverlayTrigger
                            trigger="hover"
                            placement="top"
                            overlay={popover}
                          >
                            <div className="d-flex align-items-center">
                              GST
                              {editData?.gstApplicable?.isGST && (
                                <FaCheck size={12} className="tab-icon" />
                              )}
                            </div>
                          </OverlayTrigger>
                        }
                        className="add-form-tab"
                      >
                        <div className="form-card">
                          <Box display="flex" alignItems="center">
                            <h5>GST Applicable</h5>
                            <Form.Check
                              className="mx-1 mt-2"
                              type="switch"
                              id="custom-switch"
                              onChange={() => setIsGst(!isGst)}
                              checked={isGst}
                            />
                            
                          </Box>

                          {isGst && (
                            <Box>
                              <div>
                                {multipleAddGstInfo &&
                                  multipleAddGstInfo.map((item, index) => {
                                    return (
                                      <>
                                        <GstInfoComponent
                                          getData={(data) => {
                                            handleUpdateMultipleGstData(
                                              data,
                                              index
                                            );
                                          }}
                                          editData={editData}
                                          autoCount={index}
                                          gstData={item}
                                          refreshEditData={refreshEditData}
                                          isEdit={isEdit}
                                          expenseType="internal"
                                          taxableAmount={taxableAmount}

                                          role={role}
                                          deleteMultipleGstItem={() =>
                                            deleteMultipleGstItem(
                                              item?._id?._id,
                                              index
                                            )
                                          }
                                        />
                                      </>
                                    );
                                  })}
                              </div>
                            </Box>
                          )}
                          {isGst && (
                          <Box display="flex" cursor="pointer" alignItems="center" 
                          onClick={() => {
                            handleMultipleGstInfo(
                              multipleAddGstInfo.length + 1
                            );
                          }}
                          disabled={!isGst}
                            >
                          <h5>Add GST</h5>
                          <Button
                            className="add-project mt-1 mx-1"
                            minWidth={0}
                            
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                          )
                          }
                        </div>
                      </Tab>
                    <Tab
                      eventKey="tds"
                      // title="TDS"
                      className="add-form-tab"
                      title={
                        <OverlayTrigger
                          trigger="hover"
                          placement="bottom"
                          overlay={popover}
                        >
                          <div className="d-flex align-items-center">
                            TDS
                            {editData?.tdsApplicable?.isTDS && (
                              <FaCheck size={12} className="tab-icon" />
                            )}
                          </div>
                        </OverlayTrigger>
                      }
                    >
                      <div className="form-card">
                        <Box display="flex" alignItems="center">
                          <h5>TDS Applicable</h5>
                          <Form.Check
                            className="mx-1 mt-2"
                            type="switch"
                            id="custom-switch"
                            onChange={() => setIsTds(!isTds)}
                            checked={isTds}
                          />
                        </Box>

                        {isTds && (
                          <Box>
                            <Row>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>Total Amount</Form.Label>
                                  <InputGroup className="input-group">
                                    <InputGroup.Text>
                                      <span className="rupee-symbol">₹</span>{" "}
                                    </InputGroup.Text>
                                    <CurrencyFormat
                                      customInput={Form.Control}
                                      type="text"
                                      placeholder="Total Amount "
                                      onValueChange={(e) => {
                                        setAmountTotal(e.value);
                                      }}
                                      name="Total Amount "
                                      value={amountTotal || ""}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                  </InputGroup>
                                </Box>
                              </Col>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>TDS Percent</Form.Label>
                                  <InputGroup className="input-group">
                                    <Form.Control
                                      type="number"
                                      placeholder="TDS Percent"
                                      defaultValue={tdsPercent}
                                      disabled={editPercentNum}
                                      onChange={(e) =>
                                        setTdsPercent(e.target.value)
                                      }
                                    />
                                    <InputGroup.Text>
                                      {editPercentNum ? (
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                                          alt="edit"
                                          className="edit-button"
                                          onClick={handleEditTDSPercentange}
                                        />
                                      ) : (
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                                          alt="edit"
                                          className="edit-button"
                                          onClick={() =>
                                            setEditPercentNum(true)
                                          }
                                        />
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Box>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <Box display="flex" className="input-box">
                                  <Form.Label>TDS Amount</Form.Label>

                                  <InputGroup className="input-group">
                                    <InputGroup.Text>
                                      <span className="rupee-symbol">₹</span>{" "}
                                    </InputGroup.Text>
                                    <CurrencyFormat
                                      customInput={Form.Control}
                                      type="text"
                                      onValueChange={(e) => {
                                        setTdsAmount(e.value);
                                      }}
                                      name="TDS Amount "
                                      value={
                                        tdsAmount
                                          ? Number(tdsAmount).toFixed(2)
                                          : Number(0).toFixed(2)
                                      }
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      disabled={editTDSAmount}
                                      fixedDecimalScale={true}
                                    />
                                    <InputGroup.Text>
                                      {editTDSAmount ? (
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/650/650143.png"
                                          alt="edit"
                                          className="edit-button"
                                          onClick={handleEditTDS}
                                        />
                                      ) : (
                                        <img
                                          src="https://cdn-icons-png.flaticon.com/128/8832/8832108.png"
                                          alt="edit"
                                          className="edit-button"
                                          onClick={handleEditSubmit}
                                        />
                                      )}
                                    </InputGroup.Text>
                                  </InputGroup>
                                </Box>
                              </Col>
                              {totalPayAmountIsApproved ?
                              <Col>
                                <Box display="flex">
                                  <Form.Label>
                                    Do you want to generate TDS PO?
                                  </Form.Label>
                                  <Form.Check
                                    type="switch"
                                    id="custom-switch"
                                    className="mx-1"
                                    onChange={() =>
                                      setIsGenerateTDSPo(!isGenerateTDSPo)
                                    }
                                    checked={isGenerateTDSPo}
                                    label={isGenerateTDSPo && generatedTDSpoNum}
                                  />
                                </Box>
                              </Col>
                              :
                              <Col></Col>
                              }
                            </Row>
                          </Box>
                        )}
                      </div>
                    </Tab>
                    
                    
                    {hasAccess() && (
                    <Tab
                    eventKey="freight_expense"
                    title={
                      <OverlayTrigger
                        trigger="hover"
                        placement="top"
                        overlay={popover}
                      >
                        <div className="d-flex align-items-center">
                          Freight Expense
                          {isFreight && (
                            <FaCheck size={12} className="tab-icon" />
                          )}
                        </div>
                      </OverlayTrigger>
                    }
                    className="add-form-tab"
                    >
                      <div>
                      <Row className="mt-3 mb-0">
                  <Col>
                    <Box display="flex" className="mb-3">
                      <Form.Label>Is it Freight Expense?</Form.Label>
                      <Form.Check
                        className="mx-3 mt-0"
                        type="switch"
                        id="custom-switch"
                        onChange={() => setIsFreight(!isFreight)}
                        checked={isFreight}
                      />
                    </Box>
                  </Col>
                </Row>
                {isFreight && (
                  <>
                    <Row className="mt-0 mb-3">
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Delivery Partner</Form.Label>
                          <CustomSelect
                            options={agencyOption}
                            placeHolder={<div>Type or select...</div>}
                            onChange={(newValue) => setAgencyValue(newValue)}
                            isSearchable
                            onCreateOption={(e) => {
                              createNewFormOption(createOption(e), "agency");
                            }}
                            onDeleteOption={(option) => {
                              deleteFromOption(option, "agency");
                            }}
                            onEditOption={(option, newValue) => {
                              editFromOption(option, newValue, "agency");
                            }}
                            defaultValue={agencyValue}
                            role={role}
                          />
                        </Box>
                      </Col>
                      <Col>
                        <Box display="flex" className="input-box">
                          <Form.Label>Tracking Number</Form.Label>

                          <Form.Control
                            type="text"
                            placeholder="Tracking Number"
                            name="trackingNum"
                            onChange={formik.handleChange}
                            defaultValue={editData?.expense?.trackingNum}
                          />
                        </Box>
                      </Col>
                    </Row>
                  </>
                )}
                      </div>
                    </Tab>
                    )}
                    {hasAccess() && (
                      <Tab
                        eventKey="refund"
                        className="add-form-tab"
                        title={
                          <OverlayTrigger
                            trigger="hover"
                            placement="bottom"
                            overlay={popover}
                          >
                            <div className="d-flex align-items-center">
                              Refund
                              {formik.values.isRefund && (
                                <FaCheck size={12} className="tab-icon" />
                              )}
                            </div>
                          </OverlayTrigger>
                        }
                      >
                        <div className="form-card">
                          <Box
                            display="flex"
                            className="mb-3"
                            alignItems="center"
                          >
                            <h5>Refund Information</h5>
                            <Form.Check
                              className="mx-1 mt-2"
                              type="switch"
                              id="custom-switch"
                              checked={formik.values.isRefund}
                              {...formik.getFieldProps("isRefund")}
                            />
                            
                          </Box>

                          {formik.values.isRefund && (
                            <Box>
                              {multipleAddRefundInfo &&
                                multipleAddRefundInfo?.map((item, index) => {
                                  return (
                                    <RefundInfoListComponent
                                      key={`refundInfo-${index}`}
                                      getData={(data) => {
                                        handleUpdateMultipleRefundData(
                                          data,
                                          index
                                        );
                                      }}
                                      autoCount={index}
                                      paymentData={item}
                                      editData={editData}
                                      refreshEditData={refreshEditData}
                                      deleteMultipleListItem={() =>
                                        deleteMultipleRefundListItem(index)
                                      }
                                      expenseType="internal"
                                      role={role}
                                    />
                                  );
                                })}
                            </Box>
                          )}
                          {formik.values.isRefund && (
                          <Box display="flex" cursor="pointer" alignItems="center" 
                          onClick={() => {
                            handleMultipleRefundInfo(
                              multipleAddRefundInfo.length + 1
                            );
                          }}
                          disabled={!formik.values.isRefund}
                            >
                          <h5>Add Refund</h5>
                          <Button
                            className="add-project mt-1 mx-1"
                            minWidth={0}
                            
                          >
                            <FaPlusCircle
                              className="add-project-icon"
                              size={15}
                            />
                          </Button>
                        </Box>
                          )
                        }
                        </div>
                      </Tab>
                    )}
                    {hasAccess() && (
                      <Tab
                        eventKey="reimbursement"
                        title={
                          <OverlayTrigger
                            trigger="hover"
                            placement="bottom"
                            overlay={popover}
                          >
                            <div className="d-flex align-items-center">
                              Reimbursement
                              {formik.values.isReimburse && (
                                <FaCheck size={12} className="tab-icon" />
                              )}
                            </div>
                          </OverlayTrigger>
                        }
                        className="add-form-tab"
                      >
                        <div className="form-card">
                          <Box display="flex" alignItems="center">
                            <h5>Reimbursement Information</h5>
                            <Form.Check
                              className="mx-1 mt-2"
                              type="switch"
                              id="custom-switch"
                              checked={formik.values.isReimburse}
                              {...formik.getFieldProps("isReimburse")}
                            />
                          </Box>

                          {formik.values.isReimburse && (
                            <Box>
                              <Row>
                                <Col>
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Invoice Number</Form.Label>{" "}
                                    <Form.Control
                                      type="text"
                                      name="invoiceNum"
                                      placeholder="Invoice Number"
                                      defaultValue=""
                                      {...formik.getFieldProps("invoiceNum")}
                                    />
                                  </Box>
                                  <p className="form_error">
                                    {formik.errors.invoiceNum}
                                  </p>
                                </Col>
                                <Col>
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Invoice Date</Form.Label>

                                    <DatePicker
                                      name="invoiceDate"
                                      className="monthDate"
                                      dateFormat="dd-MMM-yyyy"
                                      selected={invoiceDate}
                                      onChange={(date) => setInvoiceDate(date)}
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  </Box>
                                </Col>
                              </Row>
                              <Row>
                                <Col className="switch-col mt-1">
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Payment Date </Form.Label>

                                    <DatePicker
                                      name="reimbursePaymentDate"
                                      className="monthDate"
                                      dateFormat="dd-MMM-yyyy"
                                      selected={reimbursePaymentDate}
                                      onChange={(date) =>
                                        setReimbursePaymentDate(date)
                                      }
                                      showMonthDropdown
                                      showYearDropdown
                                      dropdownMode="select"
                                    />
                                  </Box>
                                </Col>
                                <Col>
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Currency</Form.Label>
                                    <CustomSelect
                                      options={currencyOption}
                                      placeHolder={<div>Type or select...</div>}
                                      onChange={(newValue) =>
                                        setCurrencyValue(newValue)
                                      }
                                      isSearchable
                                      onCreateOption={(e) => {
                                        createNewFormOption(
                                          createOption(e),
                                          "currency"
                                        );
                                      }}
                                      onDeleteOption={(option) => {
                                        deleteFromOption(option, "currency");
                                      }}
                                      onEditOption={(option, newValue) => {
                                        editFromOption(
                                          option,
                                          newValue,
                                          "currency"
                                        );
                                      }}
                                      defaultValue={currencyValue}
                                      role={role}
                                    />
                                  </Box>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Amount</Form.Label>
                                    <CurrencyFormat
                                      customInput={Form.Control}
                                      type="text"
                                      placeholder="0"
                                      onValueChange={(e) => {
                                        setTotalAmount(e.value);
                                      }}
                                      name="reimburseAmount"
                                      defaultValue={totalAmount || ""}
                                      value={totalAmount}
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                  </Box>
                                </Col>
                                <Col>
                                  <Box display="flex" className="input-box">
                                    <Form.Label>Conversion Rate</Form.Label>

                                    <Form.Control
                                      type="number"
                                      name="conversionRate"
                                      placeholder="Coversion Rate"
                                      disabled={
                                        currencyValue &&
                                        currencyValue?.value === "INR"
                                      }
                                      onChange={(e) => {
                                        setINRVal(e.target.value);
                                      }}
                                      defaultValue={INRVal}
                                    />
                                  </Box>
                                </Col>
                              </Row>
                              {currencyValue?.label !== "INR" && (
                                <>
                                  <Row>
                                    <Col>
                                      <Box display="flex" className="input-box">
                                        <Form.Label style={{ width: "70px" }}>
                                          Amount in INR
                                        </Form.Label>
                                        <InputGroup className="input-group">
                                          <InputGroup.Text>
                                            <span className="rupee-symbol">
                                              ₹
                                            </span>{" "}
                                          </InputGroup.Text>
                                          <CurrencyFormat
                                            customInput={Form.Control}
                                            type="text"
                                            placeholder="0"
                                            onValueChange={formik.handleChange}
                                            name="amountInINR"
                                            disabled
                                            value={convertedVal || ""}
                                            thousandSeparator={true}
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                          />
                                        </InputGroup>
                                      </Box>
                                    </Col>
                                    <Col></Col>
                                  </Row>
                                </>
                              )}
                            </Box>
                          )}
                        </div>
                      </Tab>
                    )}
                  </Tabs>
                </div>
              

              {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest && (
                  <>
                    <div className="form-card">
                      <h5>Approve OR Reject Remark</h5>

                      <Row>
                        <Col>
                          <Box display="flex" className="input-box">
                            <Form.Label>Remarks</Form.Label>
                            <Form.Control
                              as="textarea"
                              rows={2 }
                              placeholder="Type Here..."
                              value={approveOrRejectRemarks}
                              onChange={(e) =>
                                setApproveOrRejectRemarks(e.target.value)
                              }
                              isInvalid={
                                !approveOrRejectRemarks &&
                                errors?.approveOrRejectRemarks
                              }
                            />
                          </Box>
                          {!approveOrRejectRemarks && (
                            <p className="form_error">
                              {errors?.approveOrRejectRemarks}
                            </p>
                          )}
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              <Row className="mt-4">
                {(role === "Manager" || role === "Admin") &&
                activeList === "pending" &&
                isEdit &&
                !totalPayAmountIsApproved &&
                sendPayAmountRequest ? (
                  <>
                    <ModalFooter
                      style={{ alignItems: "center", justifyContent: "center" }}
                    >
                      {!totalPayAmountIsApproved && (
                        <div className="d-flex">
                          <Button
                            variant="brand"
                            disabled={
                              totalPaymentAmount === null ? true : false
                            }
                            onClick={() => {
                              handleTotalPayAmountIsApproved(formik.values);
                            }}
                            className="submit-btn me-2"
                          >
                            Approve
                          </Button>

                          <Button
                            className="submit-btn"
                            variant="brand"
                            disabled={
                              totalPaymentAmount === null ? true : false
                            }
                            onClick={() => handleTotalPayAmountIsRejected()}
                            style={{ background: "#191063" }}
                          >
                            Reject
                          </Button>
                        </div>
                      )}
                    </ModalFooter>
                  </>
                ) : (
                  <ModalFooter>
                    {(!totalPayAmountIsApproved && !sendPayAmountRequest) ||
                    remainingAmount < 0 ||
                    parseFloat(approvalAmount) !==
                      parseFloat(totalPaymentAmount) ? (
                      <Button
                        variant="brand"
                        disabled={totalPaymentAmount === null ? true : false}
                        onClick={() => {
                          handleAmountApproveBtn(formik.values);
                        }}
                        className="submit-btn"
                      >
                        Send Request
                      </Button>
                    ) : (
                      <Button
                        className="submit-btn"
                        variant="brand"
                        onClick={() => setCompareDataModal(true)}
                        style={{ background: "#191063" }}
                        type="submit"
                      >
                        Submit
                      </Button>
                    )}
                  </ModalFooter>
                )}
              </Row>
            </Form>
          </ModalBody>
          }
        </ModalContent>
      </Modal>
      {compareDataModal && comparingData && (
        <InternalDataChangeModal
          openModal={compareDataModal}
          closeModal={closeComparedDataModal}
          editData={editData}
          currentData={comparingData}
          isConfirmed={() => {
            submitUpdateData(comparingData, editData);
          }}
          type={"internal"}
          role={role}
        />
      )}
      {openQuotation && (
        <QuotationForm
          isOpen={openQuotation}
          closeModal={() => {
            handleCloseQuotation();
          }}
          requestedAmount={approvalAmount}
          handleData={(data) => handleQuotationData(data)}
          uploadedData={quotationData}
          expenseId={editData?._id || ""}
          allQuotation={allQuotation}
        />
      )}
    </>
  );
};

export default InternalAddNew;
