import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";



export const getVendorTdsExcelData = (vendorList) => {
    let list = JSON.parse(JSON.stringify(vendorList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Date": data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Current Order Month": data.orderMonth && poMonthFormate(data.orderMonth),
        "Total Amount":`₹ ${data.totalBillingAmount  ? formatAmountIndianRupees(data.totalBillingAmount || 0) : Number(0).toFixed(2)}`,

        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
        
    };
   
      

      return content;
    });
    return exportData;
  };