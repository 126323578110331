import React, { useEffect, useState, useCallback, useMemo } from "react";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { useDispatch, useSelector } from "react-redux";
import { Box } from "@chakra-ui/react";
import ClientProjectSelect from "components/ClientProjectSelect";
import DownloadPopover from "./downloadPopover";
import {
    fetchAsyncGetVendor,
    fetchAsyncGetVendorByID
} from "redux/vendorSlice";
import {
    fetchAsyncGetEmbrillProfile,
    fetchAsyncGetEmbrillProfileByID
} from "redux/embrillProfileSlice";
import { clientProjectOptions } from "jsFunctions/clientProjectOptions";
import { fetchAsyncGetExcelFile } from "redux/embrillProfileSlice";
import { useToasts } from "react-toast-notifications";
import ExcelJS from 'exceljs';
import * as XLSX from 'xlsx';

const DownloadExcelFile = ({ payAmount, role , prNo,merchantName}) => {
    const [vendorTypeValue, setVendorTypeValue] = useState(merchantName);
    const [bankTypeValue, setBankTypeValue] = useState({});
    const [embrillData, setEmbrillData] = useState({});
    const [vendorData, setVendorData] = useState({});

    const [excelTypeOption, setExcelTypeOption] = useState([])

    const dispatch = useDispatch(); 
    const vendorList = useSelector((state) => state.vendorData);
    const embrillProfileList = useSelector((state) => state.embrillProfileData);
    const { addToast } = useToasts();

    const vendorTypeOption = useMemo(() => {
        if (vendorList.length > 0) {
            const filteredVendors = vendorList.filter(vendor => vendor.isVendor);
            return clientProjectOptions('companyName', '_id', filteredVendors);
        }
        return [];
    }, [vendorList]);

    const bankTypeOption = useMemo(() => {
        if (embrillProfileList.length > 0) {
            return clientProjectOptions('bankName', '_id', embrillProfileList);
        }
        return [];
    }, [embrillProfileList]);

    const fetchEmbrillProfileByID = useCallback(async (id) => {
        const res = await dispatch(fetchAsyncGetEmbrillProfileByID(id));
        if (res?.payload) {
            const Eprofile = res.payload.embrillProfile;
            setEmbrillData(Eprofile);
            const excelOptions = await clientProjectOptions('excelType', '_id', Eprofile?.excelData || []);
            setExcelTypeOption(excelOptions)
            return excelOptions;
        }
    }, [dispatch]);

    const fetchVendorByID = useCallback(async (id) => {
        const res = await dispatch(fetchAsyncGetVendorByID(id));
        if (res?.payload) {
            setVendorData(res.payload.vendor);
        }
    }, [dispatch]);

    useEffect(() => {
        dispatch(fetchAsyncGetVendor());
        dispatch(fetchAsyncGetEmbrillProfile());
    }, [dispatch]);

    useEffect(() => {
        if (bankTypeValue?.value) {
            fetchEmbrillProfileByID(bankTypeValue.value);
        }
    }, [bankTypeValue, fetchEmbrillProfileByID]);

    useEffect(() => {
        if (vendorTypeValue?.value) {
            fetchVendorByID(vendorTypeValue.value);
        }
    }, [vendorTypeValue, fetchVendorByID]);

    const fetchAndUpdateExcelFile = async (fileKey,spId, newValues) => {
        try {
            const data = {fileKey,spId}
            const response = await dispatch(fetchAsyncGetExcelFile(data)).unwrap();
            const file = new Blob([response.data]);
            const workbook = new ExcelJS.Workbook();
            await workbook.xlsx.load(await file.arrayBuffer());
            const worksheet = workbook.worksheets[0]; 
            
            const secondRow = worksheet.getRow(2);
            secondRow.eachCell({ includeEmpty: true }, (cell, index) => {
            const key = cell.value; 
            if (newValues.hasOwnProperty(key)) {
                cell.value = newValues[key]; 
            } 
        });

            return workbook;
    
        } catch (error) {
            addToast("Failed to fetch and update Excel file", { appearance: "error" });
            throw error; 
        }
    };
    
    const fileNamingConfiguration = ({prNo, vendorName, bankName}) => {
        const currentDate = new Date();
        
        const day = currentDate.getDate().toString().padStart(2, '0'); 
        const month = currentDate.toLocaleString('default', { month: 'short' }).toLowerCase(); 
        const year = currentDate.getFullYear();
    
        const formattedDate = `${day}_${month}_${year}`; 
    
        const fileParts = [prNo];
    
        if (vendorName) {
            fileParts.push(vendorName);
        }
        if (bankName) {
            fileParts.push(bankName);
        }
        
       
    
        fileParts.push(formattedDate);
    
        let filename = `${fileParts.join('_')}.xls`;

        // Check if filename exceeds 74 characters including .xls
        const maxLength = 74;
        const extensionLength = 4; // length of .xls

        if (filename.length > maxLength) {
          // Calculate max length for the filename without extension
          const maxFilenameLength = maxLength - extensionLength;
          filename = filename.slice(0, maxFilenameLength) + ".xls"; 
        }
        return filename;
    };

    const fetchAndDownloadXlsxAsXls = async (fileKey, workbook, fileNameObj) => {
        try {
            const excelBuffer = await workbook.xlsx.writeBuffer();
            const xlsData = XLSX.read(excelBuffer, { type: 'array' });
            const xlsBuffer = XLSX.write(xlsData, { bookType: 'xls', type: 'array' });
            const xlsBlob = new Blob([xlsBuffer], { type: 'application/vnd.ms-excel' });
            // Create a download link for the XLS file
            const xlsUrl = window.URL.createObjectURL(xlsBlob);
            const xlsLink = document.createElement('a');
            xlsLink.href = xlsUrl;
            const filename = fileNamingConfiguration(fileNameObj);

            xlsLink.download = filename; // Adjust the filename as needed
            document.body.appendChild(xlsLink);
            xlsLink.click();
            document.body.removeChild(xlsLink);
    
            addToast("File downloaded successfully!", { appearance: "success" });
        } catch (error) {
            addToast("Failed to download the file", { appearance: "error" });
        }
    };
    


    const createNewExcelValue = async(data)=>{
      const { amount, paymentMode, paymentDate, remarks } =
        data;
      const vendor = vendorData;
      const vendorBank =
        vendorData?.bankDetails && vendorData?.bankDetails?.length > 0
          ? vendorData?.bankDetails[0]
          : {};
      const embrill = embrillData;

      const formatDate = (dateString) => {
        const options = { day: "numeric", month: "short", year: "numeric" };
        return new Date(dateString).toLocaleDateString("en-US", options);
      };


      // Create an object with the relevant values
      return {
        "Embrill Account Number": String(embrill?.accountNumber || ""),
        "Embrill IFSC Code": embrill?.ifscCode,
        "Embrill Bank Name": embrill?.bankName,
        "Embrill Branch Name": embrill?.branchName,
        "Embrill Contact Person": embrill?.contactPerson,
        "Embrill Email": embrill?.email,
        "Embrill Contact Number": embrill?.phone,
        "Net Payable Amount": parseFloat(amount)?.toFixed(2),
        "Payment Mode": paymentMode.label,
        "Payment Date": formatDate(paymentDate),

        "Vendor Name": vendor?.companyName,
        "Vendor Account Number": String(vendorBank?.accountNumber || ""),
        "Vendor IFSC Code": vendorBank?.ifscCode,
        "Vendor Account Type": vendorBank?.accountType,
        "Vendor Bank Name": vendorBank?.bankName,
        "Vendor Branch Name": vendorBank?.branchName,
        "Vendor Contact Number": vendor?.phone,
        "Vendor Email": vendor?.email,
        
        "Remarks": remarks,
    };
    }
    const handleDownload = async(data) => {
        const { excelTypeValue } = data;
       
        if (excelTypeValue?.value) {
            const foundExcelData = embrillData?.excelData?.find(
                (item) => item._id === excelTypeValue.value
            );

            if (foundExcelData && foundExcelData?.Key) {
                const fileKey = foundExcelData?.Key;
                const spId = foundExcelData?.spId

                try {
                    const newValue = await createNewExcelValue(data);
                    const updatedWorkbook = await fetchAndUpdateExcelFile(fileKey,spId, newValue);
                    const fileNameObj = {
                        prNo:prNo,
                        vendorName:newValue['Vendor Name'] || "",
                        bankName:newValue['Embrill Bank Name'] || "",
                    }
                    await fetchAndDownloadXlsxAsXls(fileKey, updatedWorkbook, fileNameObj);
                } catch (error) {
                    addToast("Failed to download the file", { appearance: "error" });
                }
            } else {
                console.log("No matching Excel Data found.");
            }
        }
    };

    const paymentModeValue = useMemo(() => {
      if (vendorData?.bankDetails && vendorData.bankDetails.length > 0) {
          return embrillData.bankName === vendorData.bankDetails[0].bankName ? "Intra Bank" : "NEFT";
      }
      return "NEFT"; 
  }, [embrillData, vendorData]);

    return (
        <div className="form-card mt-3">
            {/* <h5>Download Excel Sheet</h5> */}
            <Row className="mt-1">
                <Col>
                <Box display="flex" className="input-box">
                    <Form.Label>Vendor Name</Form.Label>
                    <ClientProjectSelect
                        options={vendorTypeOption}
                        placeHolder="Type or select..."
                        onChange={(newValue) => setVendorTypeValue(newValue)}
                        isSearchable
                        defaultValue={vendorTypeValue}
                        value={vendorTypeValue}
                        role={role}
                    />
                    </Box>
                </Col>
                <Col>
                <Box display="flex" className="input-box">
                    <Form.Label>Embrill Bank Name</Form.Label>
                    <Box display="flex" width={240} height={8}>
                        <ClientProjectSelect
                            options={bankTypeOption}
                            placeHolder="Type or select..."
                            onChange={(newValue) => setBankTypeValue(newValue)}
                            isSearchable
                            defaultValue={bankTypeValue}
                            role={role}
                        />
                        <DownloadPopover
                            payAmount={payAmount}
                            role={role}
                            onDownload={handleDownload}
                            bankValue={bankTypeValue}
                            vendorValue={vendorTypeValue}
                            excelTypeOption={excelTypeOption || []}
                            paymentModeValue = {paymentModeValue}
                        />
                    </Box>
                    </Box>
                </Col>
            </Row>
        </div>
    );
};

export default DownloadExcelFile;
