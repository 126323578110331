import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";


const categoryMap = {
    ExternalExpense: 'External Expense',
    InternalExpanse: 'Internal Expense',
    ResourceExpense: 'Resource Expense'
  };

export const getVendorGstExcelData = (vendorList) => {
    let list = JSON.parse(JSON.stringify(vendorList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data?.expenseId?.poNo),
        "Current Order Date": data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Current Order Month": data?.expenseId?.orderMonth && poMonthFormate(data?.expenseId?.orderMonth),
        "Category":categoryMap[data.expenseType] || data.expenseType,

        "Base Amount":`₹ ${data?.baseAmount  ? formatAmountIndianRupees(parseFloat(data?.baseAmount)) : Number(0).toFixed(2)}`,
        "IGST Amount" : `₹ ${data?.gstAmount?.igst  ? formatAmountIndianRupees(parseFloat(data?.gstAmount?.igst)) : Number(0).toFixed(2)}`,
        "CGST Amount" : `₹ ${data?.gstAmount?.cgst  ? formatAmountIndianRupees(parseFloat(data?.gstAmount?.cgst)) : Number(0).toFixed(2)}`,
        "SGST Amount" : `₹ ${data?.gstAmount?.sgst  ? formatAmountIndianRupees(parseFloat(data?.gstAmount?.sgct)) : Number(0).toFixed(2)}`


    };
   
      

      return content;
    });
    return exportData;
  };