import React, { useEffect, useState } from "react";
import { Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Table, Thead, Tbody, Tr, Th, Td, Select, Menu, MenuButton, MenuList, MenuItem, Checkbox, Button } from "@chakra-ui/react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncGetVendorExpenseDetails } from "redux/vendorSlice";
import ExpenseTable from "./expenseTable";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { currencyFormate } from "jsFunctions/helperFunctions";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { expenseDataColumns, gstDataColumns, tdsDataColumns } from "./variable/variable";
import { HamburgerIcon } from "@chakra-ui/icons";
import { FaEllipsisV } from "react-icons/fa";
import { CiExport } from "react-icons/ci";
import { useToasts } from "react-toast-notifications";
import * as FileSaver from 'file-saver';
import XLXS from 'sheetjs-style';
import { getVendorExpenseExcelData } from "components/exportData/excelData/vendorExpenseExcelData";
import moment from "moment";
import { getVendorTdsExcelData } from "components/exportData/excelData/vendorTdsExcel";
import { getVendorGstExcelData } from "components/exportData/excelData/vendorGstExcel";
const ExpenseDetails = () => {
  const { id } = useParams(); 
  const dispatch = useDispatch();
  const currUser = useSelector((state) => state.auth.currUser);
  const { addToast } = useToasts();

  const [role, setRole] = useState("");

  const [expenseColData, setExpenseColData] = useState([])
  const [tdsColData, setTdsColData] = useState([])
  const [gstColData, setGstColData] = useState([])
  const [vendor, setVendor] = useState({})


 

  const years = [
    { label: "2022-2023", value: "2022-2023" },
    { label: "2023-2024", value: "2023-2024" },
    { label: "2024-2025", value: "2024-2025" },
    { label: "2025-2026", value: "2025-2026" },
    { label: "2026-2027", value: "2026-2027" }

];
 
const [selectedYear, setSelectedYear] = useState(years[2].value); 

  const [expenseDetails, setExpenseDetails] = useState({
    allExpenses:[],
    tdsData:[],
    gstData:[]
  });

  useEffect(() => {
    if (currUser && currUser?.auth) {
      const auth = currUser?.auth;
      const roleObj =
        auth &&
        auth?.length > 0 &&
        auth.find((item) => item?.module === "Vendor");
      if (roleObj) {
        setRole(roleObj?.access);
      }
    }
  }, [currUser]);


  const fetchExpenseDetails = async (expenseId) => {
    try {
      const res = await dispatch(fetchAsyncGetVendorExpenseDetails({
        id: expenseId,
        financialYear:selectedYear
        })); 
      if (res?.payload) {
        const internal = res?.payload?.expenses?.internalExpenses || [];
        const external = res?.payload?.expenses?.externalExpenses || [];
        const resource = res?.payload?.expenses?.resourceExpenses || [];

        const tds = res?.payload?.expenses?.tdsExpenses || [];
        const gst = res?.payload?.expenses?.gstData || [];

        setVendor(res?.payload?.vendor)

        setExpenseDetails({
          allExpenses:[...internal,...external,...resource],
          tdsData: [...tds],
          gstData: [...gst]
        })

      }
    } catch (error) {
      console.error("Error fetching expense details:", error);
    }
  };

  useEffect(() => {
    if (id) {
      fetchExpenseDetails(id); 
    }
  }, [id,selectedYear]);




  useEffect(() => {
    if (expenseDetails && expenseDetails?.allExpenses?.length > 0) {
        const tableData = expenseDetails?.allExpenses.map((data, index) => {
            return {
              _id:data._id,
              no: index + 1,
              poNo: (
                <span 
                  style={{ cursor: "pointer",color:'blue'}}
                >
                  {data.poNo && poNumberFormate(data.poNo)}
                </span>
              ),
              purchasedate: (
                <span 
                  style={{ cursor: "pointer" }}
                >
                  {dateTimeFormat(data.orderDate)}
                </span>
              ),
              category:data?.category,
              majorType: (
                <span 
                  style={{ cursor: "pointer" }}
                >
                  {data.expense?.majorType}
                </span>
              ),
              minorType: (
                <span 
                  style={{ cursor: "pointer" }}
                >
                  {data.minorType}
                </span>
              ),
             
              approvedAmount: 
          (
            <span 
            style={{ cursor: "pointer" }}
          >
            {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
            </span>
          ),
               
            };
          });
          setExpenseColData(tableData);
    } else {
      setExpenseColData([]);
    }
   
 
  }, [expenseDetails.allExpenses,]);

  useEffect(()=>{
    if (expenseDetails && expenseDetails?.tdsData?.length > 0) {
      const tableData = expenseDetails?.tdsData.map((data, index) => {
          return {
            _id:data._id,
            no: index + 1,
            poNo: (
              <span 
                style={{ cursor: "pointer",color:'blue'}}
              >
                {data.poNo && poNumberFormate(data.poNo)}
              </span>
            ),
            orderdate: dateTimeFormat(data.orderDate),
            resourcename: (data?.resourceId && data?.resourceId?.companyName) ? data?.resourceId?.companyName :data?.resourceName || "",
            totalAmount: data?.totalBillingAmount ? currencyFormate(data?.totalBillingAmount): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />,
          
            approvedAmount: 
            (
              <span 
              style={{ cursor: "pointer" }}
            >
              {data.paymentInfo?.totalPayAmount ? currencyFormate(data.paymentInfo?.totalPayAmount) : <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}` }} />}
              </span>
            ), 

          };
        });
        setTdsColData(tableData);
  } else {
    setTdsColData([]);
  }
  },[expenseDetails.tdsData])


  const categoryMap = {
    ExternalExpense: 'External Expense',
    InternalExpanse: 'Internal Expense',
    ResourceExpense: 'Resource Expense'
  };

 

  useEffect(()=>{
    if (expenseDetails && expenseDetails?.gstData?.length > 0) {
      const tableData = expenseDetails?.gstData.map((data, index) => {
        console.log(data,'data')
          return {
            _id:data._id,
            no: index + 1,
            poNo: (
              <span 
                style={{ cursor: "pointer",color:'blue'}}
              >
                {data?.expenseId?.poNo && poNumberFormate(data?.expenseId?.poNo)}
              </span>
            ),
            category: categoryMap[data.expenseType] || data.expenseType,
            baseAmount:(
              <span>
  
                {data?.baseAmount ? currencyFormate(data?.baseAmount ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
              </span>
            ),
            igstAmt: (
              <span>
  
                {data?.gstAmount?.igst ? currencyFormate(data?.gstAmount?.igst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
              </span>
            ),
            sgstAmt: (
              <span>
  
                {data?.gstAmount?.sgst ? currencyFormate(data?.gstAmount?.sgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
              </span>
            ),
            cgstAmt: (
              <span>
  
                {data?.gstAmount?.cgst ? currencyFormate(data?.gstAmount?.cgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
              </span>
            ),
            totalGst: (
              <span>
  
                {data?.totalGst ? currencyFormate(data?.totalGst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
              </span>
            ),
           
            date: dateTimeFormat(data.orderDate),
  
          };
        });
        setGstColData(tableData);
  } else {
    setGstColData([]);
  }
  },[expenseDetails.gstData])

  const fileType =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formatFilename = (filename) => {

   return `${filename}${moment().format('DD-MMM-YYYY')}`;
 };
  const handleExportExpense = async() => {
    
    if (!expenseDetails || !expenseDetails?.allExpenses?.length > 0){
     addToast(`No Expense data available `, { appearance: 'error' });
     return;
   }
   const expenseData =  expenseDetails?.allExpenses;
   const excelData = await getVendorExpenseExcelData(expenseData);
   const ws = XLXS.utils.json_to_sheet(excelData);
   const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
   const excelBuffer = XLXS.write(wb, { bookType: 'xlsx', type: 'array' });
   const data = new Blob([excelBuffer], { type: fileType });
   
   const filename = `${vendor?.companyName}_expenses_`
   FileSaver.saveAs(data, formatFilename(filename) + fileExtension);
 };

 const handleExportTds = async() => {
    
  if (!expenseDetails || !expenseDetails?.tdsData?.length > 0){
   addToast(`No Expense data available `, { appearance: 'error' });
   return;
 }
 const expenseData =  expenseDetails?.tdsData;
 const excelData = await getVendorTdsExcelData(expenseData);
 const ws = XLXS.utils.json_to_sheet(excelData);
 const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
 const excelBuffer = XLXS.write(wb, { bookType: 'xlsx', type: 'array' });
 const data = new Blob([excelBuffer], { type: fileType });
 
 const filename = `${vendor?.companyName}_TDS_`
 FileSaver.saveAs(data, formatFilename(filename) + fileExtension);
};

const handleExportGst = async() => {
    
  if (!expenseDetails || !expenseDetails?.gstData?.length > 0){
   addToast(`No Expense data available `, { appearance: 'error' });
   return;
 }
 const expenseData =  expenseDetails?.gstData;
 const excelData = await getVendorGstExcelData(expenseData);
 const ws = XLXS.utils.json_to_sheet(excelData);
 const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
 const excelBuffer = XLXS.write(wb, { bookType: 'xlsx', type: 'array' });
 const data = new Blob([excelBuffer], { type: fileType });
 
 const filename = `${vendor?.companyName}_GST_`
 FileSaver.saveAs(data, formatFilename(filename) + fileExtension);
};


  return (
    <Box display="flex" className="mx-0 expense_details">
      <Box className="expense_details_main">
        <Box display="flex" justifyContent="space-between" alignItems="center">
        <Text fontWeight="600" fontSize="20px" mt={5}>Expense Details</Text>
          <Box display="flex">

          <Select
            className="graph-year-select"
            width="auto"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
          >
            {years.map((year) => (
              <option key={year.value} value={year.value}>
                {year.label}
              </option>
            ))}
          </Select>
          <Menu>
            <MenuButton p="0px" className="export_vendor" as={Button} rightIcon={<FaEllipsisV />} >
            </MenuButton>
            <MenuList className="vendor-menu-list">
              <MenuItem isDisabled={!expenseDetails?.allExpenses?.length} onClick={(e)=>handleExportExpense(e)}>
                
                  Export Expense
              </MenuItem>
              <MenuItem  isDisabled={!expenseDetails?.tdsData?.length} onClick={(e)=>handleExportTds(e)}>
               
                  Export TDS
              </MenuItem>
              <MenuItem isDisabled={!expenseDetails?.gstData?.length} onClick={(e)=>handleExportGst(e)}>
                
                  Export GST
              </MenuItem>
             
            </MenuList>
          </Menu>
          </Box>
        </Box>
        
        <Box className="acordian-box mt-1 ">
          <Accordion defaultIndex={[0]}  allowMultiple>
            {/* All Expense Data Accordion */}
            <AccordionItem className="acordian-item" borderTop="none">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="600">Expenses </Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel className="acordian-panal  px-2 p-0 m-0">
                {expenseColData && expenseColData?.length > 0 ? (
                  <ExpenseTable
                    columnsData={expenseDataColumns}
                    tableData={expenseColData}
                    role={role}
                  />
                ) : (
                  <Text className="py-3" fontWeight="600" textAlign="center">There are no Expenses</Text>
                )}
              </AccordionPanel>
            </AccordionItem>

            {/* TDS Data Accordion */}
            <AccordionItem className="acordian-item" borderTop="none">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="600">TDS</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel className="acordian-panal px-2 p-0 m-0">
                {tdsColData && tdsColData?.length > 0 ? (
                  <ExpenseTable
                    columnsData={tdsDataColumns}
                    tableData={tdsColData}
                    role={role}
                  />
                ) : (
                  <Text className="py-3" fontWeight="600" textAlign="center">There are no TDS</Text>
                )}
              </AccordionPanel>
            </AccordionItem>

            {/* GST Data Accordion */}
            <AccordionItem className="acordian-item" borderTop="none">
              <h2>
                <AccordionButton>
                  <Box flex="1" textAlign="left">
                    <Text fontWeight="600">GST</Text>
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel className="acordian-panal px-2 p-0 m-0">
                {expenseDetails.gstData.length > 0 ? (
                  <ExpenseTable
                    columnsData={gstDataColumns}
                    tableData={gstColData}
                    role={role}
                  />
                ) : (
                  <Text className="py-3" fontWeight="600" textAlign="center">There are no GST</Text>
                )}
              </AccordionPanel>
            </AccordionItem>
          </Accordion>
        </Box>
      </Box>
    </Box>
  );
};

export default ExpenseDetails;
