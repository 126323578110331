import {
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Table,
  Td,
  Tr,
  Th,
  Tbody,
  Thead,
  Tfoot,
  Box,
} from "@chakra-ui/react";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { InputGroup, Pagination } from "react-bootstrap";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { useEffect, useState } from "react";

const DataRow = ({ label, value, type }) => {
  return (
    <>
      <Form.Label>{label}</Form.Label>
      {type === 'Amount'
        ?
        <InputGroup>
          <InputGroup.Text style={{ height: '2rem', border: 0, background: 'lightGray' }}><span className="rupee-symbol">₹</span> </InputGroup.Text>
          <Form.Control
            type="text"
            value={value !== "undefined" && value !== "" ? formatAmountIndianRupees(value) : " "}
            disabled
          />
        </InputGroup>
        :
        <Form.Control
          type="text"
          value={value !== "undefined" && value !== "" ? value : " "}
          disabled
        />
      }
    </>
  );
};

const PaymentInfo = ({ selectedItem, remainingAmount }) => {
  const [totalSpentAmount, setTotalSpentAmount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Number of items to display per page

  useEffect(() => {
    if (selectedItem?.paymentInfo?.list && selectedItem?.paymentInfo?.list.length > 0) {
      const listOfPayment = selectedItem?.paymentInfo?.list;
      const calculateTotalAmount = listOfPayment.reduce((acc, current) => {
        if (current && current?.amount && current?.amount !== '') {
          return acc + parseFloat(current.amount);
        } else {
          return acc;
        }
      }, 0);
      setTotalSpentAmount(calculateTotalAmount);
    }
  }, [selectedItem]);

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = selectedItem.paymentInfo.list?.slice(indexOfFirstItem, indexOfLastItem) || [];
  const pageCount = Math.ceil(selectedItem.paymentInfo.list?.length / itemsPerPage);

  return (
    <>
      <Row className="info-row">
        <Col>
          <DataRow
            label="Requested Amount"
            type="Amount"
            value={selectedItem.paymentInfo.approvalAmount}
          />
        </Col>
        <Col>
          <DataRow
            label="Approved Amount"
            type="Amount"
            value={selectedItem.paymentInfo.totalPayAmount || 0}
          />
        </Col>
      </Row>

      <Row className="info-row">
        <Col>
          <DataRow
            label="Net Payable Amount"
            type="Amount"
            value={remainingAmount || 0}
          />
        </Col>
        <Col className="check-cross-mark">
          <Form.Label>
            <span className={
              selectedItem.paymentInfo.totalPayAmountIsRejected ? "checkmark" : "crossmark"
            }></span>{" "}
            {selectedItem.paymentInfo.totalPayAmountIsRejected ? "Is requested amount rejected?" : "Is requested amount approved?"}
          </Form.Label>
        </Col>
      </Row>

      <Row className="info-row">
        <Col>
          <Form.Label>Approve OR Reject Remark</Form.Label>
          <Form.Control
            as="textarea"
            disabled
            rows={4}
            value={selectedItem?.approveRejectRemarks}
          />
        </Col>
      </Row>

      {currentItems.length ? (
        <div className="info-table">
          <Table size="md" className="table-info" variant="striped">
            <Thead>
              <Tr>
                <Th>No.</Th>
                <Th>Payee</Th>
                <Th>Spent Amount</Th>
                <Th>Bank</Th>
                <Th>Reference Number</Th>
                <Th>Date</Th>
              </Tr>
            </Thead>
            <Tbody>
              {currentItems.map((item, index) => (
                <Tr key={index}>
                  <Td>{indexOfFirstItem + index + 1}</Td>
                  <Td>{item?.payeeId?.value}</Td>
                  <Td>
                    <span className="rupee-symbol">₹</span>{" "}
                    {item?.amount ? formatAmountIndianRupees(Number(item?.amount)) : Number(0).toFixed(2)}
                  </Td>
                  <Td>{item?.bankId?.value}</Td>
                  <Td>{item?.refNo}</Td>
                  <Td>{dateTimeFormat(item?.paymentDate)}</Td>
                </Tr>
              ))}
            </Tbody>
            <Tfoot>
              <Tr>
                <Th colSpan={2}>Total Spent Amount</Th>
                <Td colSpan={4} className="total-spent">
                  <span className="rupee-symbol">₹</span>{" "}
                  {formatAmountIndianRupees(Number(totalSpentAmount))}
                </Td>
              </Tr>
            </Tfoot>
          </Table>

          {/* Pagination Component */}
          <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
            <Pagination.Prev 
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} 
              disabled={currentPage === 1} 
            />
            {Array.from({ length: pageCount }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next 
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))} 
              disabled={currentPage === pageCount} 
            />
          </Pagination>
        </div>
      ) : (
        ""
      )}
    </>
  );
}

export default PaymentInfo;
