/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";


const Icon = ({ isOpen }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      width="18"
      height="18"
      stroke="#222"
      strokeWidth="1.5"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      className={isOpen ? "translate" : ""}
    >
      <polyline points="6 9 12 15 18 9"></polyline>
    </svg>
  );
};

const CustomGraphSelect = ({
  placeHolder,
  options,
  isMulti,
  isSearchable,
  onChange,
  align,
  defaultValue,
}) => {
  const [showMenu, setShowMenu] = useState(false); 
  const [selectedValue, setSelectedValue] = useState(isMulti ? [] : null); 
  const [searchValue, setSearchValue] = useState(""); 
  const searchRef = useRef(); 
  const inputRef = useRef(); 

  useEffect(() => {
    setSearchValue("");
    if (showMenu && searchRef.current) {
      searchRef.current.focus();
    }
  }, [showMenu]);

  useEffect(() => {
    if (defaultValue && defaultValue.value) {
      setSelectedValue(defaultValue);
    } else {
      setSelectedValue(null);
    }
  }, [defaultValue]);

  useEffect(() => {
    const handler = (e) => {
      if (inputRef.current && !inputRef.current.contains(e.target)) {
        setShowMenu(false);
      }
    };

    window.addEventListener("mousedown", handler);
    return () => {
      window.removeEventListener("mousedown", handler);
    };
  }, [inputRef]);

  const handleInputClick = (e) => {
    setShowMenu(!showMenu);
  };

  const getDisplay = () => {
    if (!selectedValue || selectedValue.length === 0) {
      return placeHolder;
    }
    return selectedValue.label;
  };

  const removeOption = (option) => {
    return selectedValue.filter((o) => o.value !== option.value);
  };

  const onItemClick = (option) => {
    let newValue;
    if (isMulti) {
      if (selectedValue.findIndex((o) => o.value === option.value) >= 0) {
        newValue = removeOption(option);
      } else {
        newValue = [...selectedValue, option];
      }
    } else {
      newValue = option;
    }
    setSelectedValue(newValue);
    onChange(newValue);
    setShowMenu(false);
  };

  const isSelected = (option) => {
    if (isMulti) {
      return selectedValue.filter((o) => o.value === option.value).length > 0;
    }

    if (!selectedValue) {
      return false;
    }

    return selectedValue.value === option.value;
  };

  const onSearch = (e) => {
    setSearchValue(e.target.value);
  };

  const getOptions = () => {
    if (!searchValue) {
      return options;
    }

    return options.filter(
      (option) =>
        option.label.toLowerCase().indexOf(searchValue.toLowerCase()) >= 0
    );
  };

  



  return (
    <div ref={inputRef} className="custom--dropdown-container graph-dropdown-select">
      <div onClick={handleInputClick} className={`dropdown-input`}>
        <div className={`dropdown-selected-value`}>{getDisplay()}</div>
        <div className="dropdown-tools">
          <div className="dropdown-tool">
            <Icon isOpen={showMenu} />
          </div>
        </div>
      </div>

      {showMenu && (
        <div className={`dropdown-menu alignment--${align || "auto"} `}>
          {isSearchable && (
            <div className="search-box">
              <input
                className="form-control"
                onChange={onSearch}
                value={searchValue}
                ref={searchRef}

              />
            </div>
          )}
          {getOptions().map((option) => (
            <div className="d-flex" key={option.value}>
              
              <div
                onClick={(e) => {
                  e.preventDefault();
                  onItemClick(option);
                }}
                className={`dropdown-item ${isSelected(option) && "selected"}`}
              >
                {option.label}
                
              </div>
            
             
            </div>
          ))}
         
        </div>
      )}
    </div>
  );
};

export default CustomGraphSelect;
