import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { dateCompare } from "jsFunctions/dateTimeFormat";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";

const SpentAmountTable = ({ editSpentInfo, currentSpentInfo, type }) => {
    return (

        type === 'current' ?
            <div className="info-table change-modal-table">

                <Table className="table-info">
                    <Thead>
                        <Tr>
                            <Th>Payee</Th>
                            <Th>Bank</Th>
                            <Th>ReferenceNo</Th>

                            <Th>Amount</Th>
                            <Th>Date</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {editSpentInfo.map((item, index) => {
                            return (
                                <Tr>
                                    <Td className={`${item?.payee !== currentSpentInfo[index]?.payee ? 'data_compare_red' : ''}`}>{item?.payee}</Td>
                                    <Td className={`${item?.bank !== currentSpentInfo[index]?.bank ? 'data_compare_red' : ''}`}>{item?.bank}</Td>
                                    <Td className={`${item?.refNo !== currentSpentInfo[index]?.refNo ? 'data_compare_red' : ''}`}>{item?.refNo}</Td>

                                    <Td className={`${item?.amount !== currentSpentInfo[index]?.amount ? 'data_compare_red' : ''}`}>{formatAmountInINR(parseFloat(item?.amount || 0))}</Td>
                                    <Td className={`${!(dateTimeFormat(item?.paymentDate), dateTimeFormat(currentSpentInfo[index]?.paymentDate)) ? 'data_compare_red' : ''}`}>{dateTimeFormat(item?.paymentDate)}</Td>

                                </Tr>

                            );
                        })}
                    </Tbody>
                </Table>
            </div>
            :
            <div className="info-table change-modal-table">

                <Table size="md" className="table-info" w='100'>
                    <Thead>
                        <Tr>
                            <Th>Payee</Th>
                            <Th>Bank</Th>
                            <Th>ReferenceNo</Th>
                            <Th>Amount</Th>
                            <Th>Date</Th>

                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentSpentInfo?.map((item, index) => {
                            return (
                                <Tr>
                                    <Td className={`${item?.payee !== editSpentInfo[index]?.payee ? 'data_compare_green' : ''}`}>{item?.payee}</Td>
                                    <Td className={`${item?.bank !== editSpentInfo[index]?.bank ? 'data_compare_green' : ''}`}>{item?.bank}</Td>
                                    <Td className={`${item?.refNo !== editSpentInfo[index]?.refNo ? 'data_compare_green' : ''}`}>{item?.refNo}</Td>

                                    <Td className={`${item?.amount !== editSpentInfo[index]?.amount ? 'data_compare_green' : ''}`}>{formatAmountInINR(parseFloat(item?.amount || 0))}</Td>
                                    <Td className={`${!dateCompare(dateTimeFormat(item?.paymentDate), dateTimeFormat(editSpentInfo[index]?.paymentDate)) ? 'data_compare_green' : ''}`}>{dateTimeFormat(item?.paymentDate)}</Td>

                                </Tr>

                            );
                        })}
                    </Tbody>
                </Table>
            </div>
    )


}

export default SpentAmountTable;
