// chakra imports
import { Box, Flex, Stack } from '@chakra-ui/react';
//   Custom components
import Brand from 'components/sidebar/components/Brand';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import React from 'react';
import { Button } from 'react-bootstrap';
import IconLinks from './iconLinks';
import IconBrand from './IconBrand';

// FUNCTIONS

function IconContent(props) {
  const { routes} = props;
  // SIDEBAR
  return (
    <Flex
      direction='column'
      height='100%'
      
      className='sidebar-main'
    >
      <IconBrand />
      <Stack direction='column' mb='auto' mt={5}  height='100%'>
        <Box ps='3px'  height='100%'>
          <IconLinks handleSidebar={props?.handleSidebar} routes={routes} />
        </Box>
      </Stack>

      <Box mt='30px' mb='50px' borderRadius='30px'>
        <SidebarCard />
      </Box>
      
    </Flex>
  );
}

export default IconContent;
