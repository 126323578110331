import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddExternalExpense = createAsyncThunk(
  "/fetchAsyncAddExternalExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/external/create`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetExternalExpense = createAsyncThunk(
  "/fetchAsyncGetExternalExpense",
  async () => {
    try {
      let url = `${API_URL}/external/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetPONumExternalExpense = createAsyncThunk(
  "/fetchAsyncGetPONumExternalExpense",
  async (data) => {
    try {
      let url = `${API_URL}/external/getPoNum`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteExternalExpense = createAsyncThunk(
  "/fetchAsyncDeleteExternalExpense",
  async (id) => {
    try {
      let url = `${API_URL}/external/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteExternalPdf = createAsyncThunk(
  "/fetchAsyncDeleteExternalPdf",
  async (data) => {
    try {
      let url = `${API_URL}/external/delete_pdf/${data.id}`;
      const response = await axiosInstance.post(url,{ key: data.key,listIndex:data?.listIndex,spId:data?.spId });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncUpdateExternalExpense = createAsyncThunk(
  "/fetchAsyncUpdateExternalExpense",
  async (data) => {
    try {
      const response = await axiosInstance.put(`${API_URL}/external/update/${data.id}`,data.data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUploadExternalPdf = createAsyncThunk(
  "/fetchAsyncUploadExternalPdf",
  async (data) => {
    try {
      let url = `${API_URL}/external/upload_pdf/${data.id}`;
      const response = await axiosInstance.post(url,data.formData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncSearchExternalData = createAsyncThunk(
  "/fetchAsyncSearchExternalData",
  async (data) => {
    try {
      let url = `${API_URL}/external/search`;
      if (data.value) {
        url = url + `?search=${data.value}`;
      }
      if (data.startDate && data.endDate && data.value) {
        url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      if (data.startDate && data.endDate && !data.value) {
        url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
      }
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

// Dashboard Api
export const fetchAsyncGetExternalTotalPaymentAmount = createAsyncThunk(
  "/fetchAsyncGetExternalTotalPaymentAmount",
  async () => {
    try {
      let url = `${API_URL}/external/graph-totalAmount`;
      const response = await axiosInstance.get(url);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteExternalGstListItem = createAsyncThunk(
  "/fetchAsyncDeleteExternalGstListItem",
  async (id) => {
    try {
      let url = `${API_URL}/external/delete-gst/${id}`;
      const response = await axiosInstance.delete( url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
export const fetchAsyncApproveExternalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncApproveExternalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/external/approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestApprovalExternalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestApprovalExternalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/external/send-request-approve-total-pay-amount`;
      const response = await axiosInstance.post(url, data);
     
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncSendRequestUpdateApprovalExternalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncSendRequestUpdateApprovalExternalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/external/send-request-update-approve-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncRejectExternalTotalPayAmount = createAsyncThunk(
  "/fetchAsyncRejectExternalTotalPayAmount",
  async (data) => {
    try {
      let url = `${API_URL}/external/reject-total-pay-amount/${data.id}`;
      const response = await axiosInstance.put(url, data.data);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncAddInvoiceRemark = createAsyncThunk(
  "/fetchAsyncAddInvoiceRemark",
  async (data) => {
    try {
      let url = `${API_URL}/external/invoice-remark/${data.id}`;
      const response = await axiosInstance.put(url, data.remarkData);

      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetExternalExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetExternalExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/external/externalFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncDeleteExternalPaymentListItem = createAsyncThunk(
  "/fetchAsyncDeleteExternalPaymentListItem",
  async (data) => {
    try {
      let url = `${API_URL}/external/delete-external-payment-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncExternalXlsxData = createAsyncThunk(
  "/fetchAsyncExternalXlsxData",
  async (data) => {
    try {
      let url = `${API_URL}/external/external-xlsx`;
     
      
      if (data?.role && data.startDate && data.endDate) {
        url = url + `?role=${data.role}&startDate=${data.startDate}&endDate=${data.endDate}`;
      }
    
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);

export const fetchAsyncDeleteExternalRefundListItem = createAsyncThunk(
  "/fetchAsyncDeleteExternalRefundListItem",
  async (data) => {
    try {
      let url = `${API_URL}/external/delete-external-refund-list-item/${data.id}`;
      const response = await axiosInstance.post( url,{listIndex:data?.listIndex });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.msg);
    }
  }
);
const AllExternalSlice = createSlice({
  name: "externalData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetExternalExpense.pending]: (state) => {},
    [fetchAsyncGetExternalExpense.fulfilled]: (state, { payload }) => {
      return payload;
    },
    [fetchAsyncSearchExternalData.pending]: (state) => {},
    [fetchAsyncSearchExternalData.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllExternalExpense = (state) => state.externalData;

export default AllExternalSlice.reducer;
