import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddLegacy = createAsyncThunk(
  "/fetchAsyncAddLegacy",
  async (data) => {
    try {
      const response = await axiosInstance.post('/legacy/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetLegacy = createAsyncThunk(
  "/fetchAsyncGetLegacy",
  async () => {
    try {
      let url = `${API_URL}/legacy/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);


export const fetchAsyncMigrateInternalInvoice = createAsyncThunk(
  "/fetchAsyncMigrateInternalInvoice",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-internal-invoice/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateExternalInvoice = createAsyncThunk(
  "/fetchAsyncMigrateExternalInvoice",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-external-invoice/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateResourceInvoice = createAsyncThunk(
  "/fetchAsyncMigrateResourceInvoice",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-resource-invoice/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncMigrateTdsInvoice = createAsyncThunk(
  "/fetchAsyncMigrateTdsInvoice",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-tds-invoice/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncMigrateDirectTaxInvoice = createAsyncThunk(
  "/fetchAsyncMigrateDirectTaxInvoice",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-direct-tax-invoice/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateQuotations = createAsyncThunk(
  "/fetchAsyncMigrateQuotations",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-quotation/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateExternalFormOptions = createAsyncThunk(
  "/fetchAsyncMigrateExternalFormOptions",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-external-form-options/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateExternalPoOrderMonth = createAsyncThunk(
  "/fetchAsyncMigrateExternalPoOrderMonth",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-external-po-ordermonth/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateInternalFormOptions = createAsyncThunk(
  "/fetchAsyncMigrateInternalFormOptions",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-internal-form-options/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncMigrateResourceFormOptions = createAsyncThunk(
  "/fetchAsyncMigrateResourceFormOptions",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-resource-form-options/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncMigrateTdsFormOptions = createAsyncThunk(
  "/fetchAsyncMigrateTdsFormOptions",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-tds-form-options/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncMigrateDirectTaxFormOptions = createAsyncThunk(
  "/fetchAsyncMigrateDirectTaxFormOptions",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-direct-tax-form-options/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncMigrateGstPoOrderMonth = createAsyncThunk(
  "/fetchAsyncMigrateGstPoOrderMonth",
  async (name) => {
    try {
      let url = `${API_URL}/legacy/migrate-gst-po-ordermonth/${name}`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
  const AllLegacySlice = createSlice({
    name: "legacyData",
    initialState,
    reducers: {},
    extraReducers: {
      [fetchAsyncGetLegacy.pending]: (state) => {},
      [fetchAsyncGetLegacy.fulfilled]: (state, { payload }) => {
        return payload;
      },
      
    },
  });
  

  export const getAllLegacy = (state) => state.legacyData;
  
  export default AllLegacySlice.reducer;




