import { Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { dateCompare } from "jsFunctions/dateTimeFormat";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";

const RefundAmountTable = ({ currentRefundInfo, editRefundInfo, type }) => {
    return (

        type === 'current' ?
        <div className="info-table change-modal-table">
                  <Table size="md" className="table-info" w='100'>
                    <Thead>
                      <Tr>
                        <Th>Payee</Th>
                        <Th>Amount</Th>
                        <Th>Bank</Th>
                        <Th>ReferenceNo</Th>
                        <Th>Date</Th>

                      </Tr>
                    </Thead>
                    <Tbody>
                    {editRefundInfo?.map((item,index) => {
                    return (
                      <Tr>
                        <Td className={`${item?.payee !== currentRefundInfo[index]?.payee  ? 'data_compare_red' : '' }`}>{item?.payee}</Td>
                        <Td className={`${item?.amount !== currentRefundInfo[index]?.amount  ? 'data_compare_red' : '' }`}>{formatAmountInINR(parseFloat(item?.amount || 0))}</Td>
                          <Td className={`${item?.bank !== currentRefundInfo[index]?.bank  ? 'data_compare_red' : '' }`}>{item?.bank}</Td>
                          <Td className={`${item?.refNo !== currentRefundInfo[index]?.refNo  ? 'data_compare_red' : '' }`}>{item?.refNo}</Td>

                        <Td className={`${!dateCompare(dateTimeFormat(item?.paymentDate) ,dateTimeFormat(currentRefundInfo[index]?.paymentDate))  ? 'data_compare_red ' : '' }`}>{dateTimeFormat(item?.paymentDate)}</Td>

                      </Tr>

                    );
                  })}
                    </Tbody>
                  </Table>
                   
                  </div>

            :
            <div className="info-table change-modal-table">
        <Table size="md" className="table-info" w='100'>
          <Thead>
            <Tr>
              <Th>Payee</Th>
              <Th>Amount</Th>
              <Th>Bank</Th>
              <Th>ReferenceNo</Th>
              <Th>Date</Th>

            </Tr>
          </Thead>
          <Tbody>
          {currentRefundInfo?.map((item,index) => {
          return (
            <Tr>
              <Td className={`${item?.payee !== editRefundInfo[index]?.payee  ? 'data_compare_green' : '' }`}>{item?.payee}</Td>
              <Td className={`${item?.amount !== editRefundInfo[index]?.amount  ? 'data_compare_green' : '' }`}>{formatAmountInINR(parseFloat(item?.amount || 0))}</Td>
                <Td className={`${item?.bank !== editRefundInfo[index]?.bank  ? 'data_compare_green' : '' }`}>{item?.bank}</Td>
                <Td className={`${item?.refNo !== editRefundInfo[index]?.refNo  ? 'data_compare_green' : '' }`}>{item?.refNo}</Td>

              <Td className={`${!dateCompare(dateTimeFormat(item?.paymentDate) ,dateTimeFormat(editRefundInfo[index]?.paymentDate))  ? 'data_compare_green' : '' }`}>{dateTimeFormat(item?.paymentDate)}</Td>

            </Tr>

          );
        })}
          </Tbody>
        </Table>
         
        </div>
    )


}

export default RefundAmountTable;
