import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { AiFillDelete } from "react-icons/ai";
import Swal from "sweetalert2";
import { MdOutlineFileDownload } from "react-icons/md";

import InvoiceColumnTable from "views/admin/embrillTable/InvoiceColumnTable";
import { useToasts } from "react-toast-notifications";
import { useDispatch } from "react-redux";
import { fetchAsyncDownloadSharePointFile } from "redux/userSlice";
import { fetchAsyncPdfPreviewData } from "redux/userSlice";
import SpinnerLoader from "components/Spinner/SpinnerLoader";

const PdfListModel = ({
  openModal,
  closeModal,
  pdfData,
  currentId,
  deleteFileFromList,
  role
}) => {
  const [tableData, setTableData] = useState([]);
  
  const { addToast } = useToasts();

  const dispatch = useDispatch();
  const columnData = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Title",
      accessor: "label",
    },
    {
      Header: "Remark",
      accessor: "remark",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];


  const [isLoading,setIsLoading] = useState(false)

  const handleDownload = async (key, spId) => {
    setIsLoading(true)

      try {
        const response = await dispatch(fetchAsyncDownloadSharePointFile({spId,key})).unwrap();
        if (response && response.downloadUrl) {  
          const fileUrl = response.downloadUrl;  
          const link = document.createElement("a");
          link.href = fileUrl;
          link.download = fileUrl.split('/').pop();  
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          setIsLoading(false)

        } else {
          setIsLoading(false)
          addToast(`Error downloading file: The requested file could not be found.`, { appearance: "error" });
          }
      } catch (error) {
        setIsLoading(false)
        addToast(`Error downloading file: The requested file could not be found.`, { appearance: "error" });
      }
        
    
  };
  

  const handleRowClick = async (key, spId) => {
    setIsLoading(true)

    try {
      const data = { key, spId };
      const response = await dispatch(fetchAsyncPdfPreviewData(data)).unwrap();
      const blob = new Blob([response], { type: 'application/pdf' });
      const pdfUrl = window.URL.createObjectURL(blob);
      window.open(pdfUrl, '_blank'); 
      setIsLoading(false)

    } catch (error) {
      setIsLoading(false)
      addToast(`Error fetching PDF preview: The requested file could not be found`, { appearance: "error" });
    }
  };

  useEffect(() => {
    if (pdfData && pdfData.length > 0) {
      const content = pdfData.map((data, index) => {
        let label = "";
        if (data?.Key) {
          let splitKey = data.Key.split("/");
          label = data.Key && splitKey[splitKey.length - 1];
        }
        return {
          no: index + 1,
          Key: data?.Key,
          label: label,
          spId:data?.spId || "",
          remark: data?.remark,
          action: (
            <div className="d-flex">
              <MdOutlineFileDownload
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  handleDownload(data?.Key,data?.spId);
                }}
              />
              {role === "Admin" && (
                <AiFillDelete
                  style={{ cursor: "pointer", color: "red" }}
                  onClick={(e) => {
                    e.preventDefault();
                    deletePdfFile(data?.Key, data?.listIndex,data?.spId);
                  }}
                  className="ms-2"
                />
              )}
            </div>
          ),
        };
      });
      setTableData(content);
    } else {
      setTableData([]);
    }
  }, [pdfData]);

  const deletePdfFile = (key, listIndex,spId) => {
    Swal.fire({
      title: "Are You Sure? Delete File Permanently.",
      icon: "warning",
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Delete",
      showCancelButton: true,
      customClass: {
        container: "sweet_containerImportant",
        confirmButton: "sweet_confirmbuttonImportant",
      },
    }).then((result) => {
      
      if (result.isConfirmed) {
        const sendData = {
          id: currentId,
          key: key,
          listIndex: listIndex,
          spId: spId
        };
        deleteFileFromList(sendData);
      }
    });
  };

  return (
    <div>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        size="xl"
        className="modal-main"
      >
        <ModalOverlay />
        <ModalContent top="0" // Position it at the top
           margin="0 auto" // Center it horizontally
           bottom="0"
           className="info-model">
          <div className="fixed-header">
            <ModalHeader>Attachments</ModalHeader>
            <ModalCloseButton />
          </div>
          <ModalBody>
            {
              isLoading ?
              <SpinnerLoader/>
              :
            <InvoiceColumnTable
              columnsData={columnData}
              tableData={tableData}
              type={""}
              handleRowClick={(e,key,spId)=>handleRowClick(key,spId)}
            />
            }

          </ModalBody>
        </ModalContent>
      </Modal>

     
    </div>
  );
};

export default PdfListModel;
