/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { Box, Button, Spinner, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";
import { MdModeEdit } from "react-icons/md";
import { FaPlus } from "react-icons/fa";
import { legacyItem } from "variables/legacy";
import { legacyDataColumns } from "variables/legacy";
import LegacyNew from "components/allForm/legacyNew";
import { fetchAsyncGetLegacy } from "redux/legacySlice";
import { fetchAsyncMigrateInternalInvoice } from "redux/legacySlice";
import { fetchAsyncMigrateQuotations } from "redux/legacySlice";
import Swal from "sweetalert2";
import { useToasts } from "react-toast-notifications";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { fetchAsyncMigrateExternalInvoice } from "redux/legacySlice";
import { fetchAsyncMigrateResourceInvoice } from "redux/legacySlice";
import { fetchAsyncMigrateTdsInvoice } from "redux/legacySlice";
import { fetchAsyncMigrateDirectTaxInvoice } from "redux/legacySlice";
import { appliedLegacyDataColumns } from "variables/legacy";
import SpinnerLoader from "components/Spinner/SpinnerLoader";
import { fetchAsyncMigrateExternalFormOptions } from "redux/legacySlice";
import { fetchAsyncMigrateExternalPoOrderMonth } from "redux/legacySlice";
import { fetchAsyncMigrateInternalFormOptions } from "redux/legacySlice";
import { fetchAsyncMigrateResourceFormOptions } from "redux/legacySlice";
import { fetchAsyncMigrateTdsFormOptions } from "redux/legacySlice";
import { fetchAsyncMigrateDirectTaxFormOptions } from "redux/legacySlice";
import { fetchAsyncMigrateGstPoOrderMonth } from "redux/legacySlice";


const LegacyList = () => {
 
  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  
  const [openLegacyModal, setOpenLegacyModal] = useState(false);
  const [legacyDataList,setLegacyDataList] = useState([])
  const legacyList = useSelector((state) => state.legacyData);
  const dispatch = useDispatch()
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState(false);
  const [activeDataList,setActiveDataList] = useState('todo')

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Users');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  const handleOpenLegacyModal = () => {
    setOpenLegacyModal(true);

  };


  const handleCloseLegacyModal = () => {
    setOpenLegacyModal(false);
    getLegacyData();

  };
  
  const getLegacyData = ()=>{
    setIsLoading(true); // Start loading
    dispatch(fetchAsyncGetLegacy())
      .then(() => {
        setIsLoading(false); // Stop loading
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setIsLoading(false); // Stop loading on error
      });
  }
  useEffect(() => {
  
    getLegacyData();
  }, []);


  const getAllLegacyData = (listData)=>{
    if (listData && listData.length > 0) {
      const tableData = listData.map((data, index) => {
          return {
            no: index + 1,
            legacyId:data?.legacyId || "",
            name:data.name,
            createdAt: dateTimeFormat(data?.createdAt),
            appliedAt:dateTimeFormat(data?.appliedAt),
            appliedBy:data?.appliedBy,

            action: (
              <Box 
                color="blue" 
                fontWeight="600" 
                className="d-flex"
                onClick={(e) => {
                  e.preventDefault();
                  Swal.fire({
                    title: `Please confirm that you want to apply the migration for <span style="color: #d33">${data.name}</span> ?`,
                    text:'',
                    icon: "warning",
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#d33",
                    confirmButtonText: "Confirm",
                    showCancelButton: true,
                    customClass: {
                      container: "sweet_containerImportant",
                      confirmButton: "sweet_confirmbuttonImportant",
                    },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleApplyMigrations(data);
                    }
                  });
                }}
              >
                Apply
              </Box>
            ),
          };
        });
    setLegacyDataList(tableData);
  } else {
      setLegacyDataList([]);
  }
  }

  const handleLegacyListData = ()=>{

    if (!legacyList || legacyList.length === 0) {
      setLegacyDataList([]);
      return;
    }
  
    const listData = legacyList.filter(item => {
      if (activeDataList === 'todo') {
        return !item.isApply
      }
      if (activeDataList === 'applied') {
        return item.isApply
      }
    });
  
    getAllLegacyData(listData);
  }

  useEffect(()=>{
    handleLegacyListData();
  },[legacyList,activeDataList])
 

  
  const handleApplyMigrations = async (data) => {
    const { name } = data;
    setIsLoading(true); 
    const legacy = legacyItem.find(item => item.name === name);
    
    const migrationFunctions = {
        'internal-invoice': fetchAsyncMigrateInternalInvoice,
        'external-invoice': fetchAsyncMigrateExternalInvoice,
        'resource-invoice': fetchAsyncMigrateResourceInvoice,
        'tds-invoice': fetchAsyncMigrateTdsInvoice,
        'direct-tax-invoice': fetchAsyncMigrateDirectTaxInvoice,
        'quotations': fetchAsyncMigrateQuotations,
        'migrate-external-form-options': fetchAsyncMigrateExternalFormOptions,
        'migrate-external-po-ordermonth':fetchAsyncMigrateExternalPoOrderMonth,
        'migrate-internal-form-options': fetchAsyncMigrateInternalFormOptions,
        'migrate-resource-form-options': fetchAsyncMigrateResourceFormOptions,
        'migrate-tds-form-options': fetchAsyncMigrateTdsFormOptions,
        'migrate-direct-tax-form-options': fetchAsyncMigrateDirectTaxFormOptions,
        'migrate-gst-po-ordermonth': fetchAsyncMigrateGstPoOrderMonth,
      };

    try {
        const migrationFunction = migrationFunctions[legacy.value];
        
        if (migrationFunction) {
            const res = await dispatch(migrationFunction(name));
            if (res?.payload) {
                const updatedDataKey = legacy.value === 'quotations' ? 'updatedQuotations' : 'updatedInvoice';
                const updatedData = res?.payload[updatedDataKey];
                console.log(updatedData, legacy.value); // temp logs files
                getLegacyData();
                addToast("Migrations applied successfully!", { appearance: "success" });
            }
        } else {
            addToast("Invalid migration", { appearance: "error" });
        }
    } catch (err) {
        addToast("Migration failed to apply", { appearance: "error" });
    } finally {
        setIsLoading(false); 
    }
};
   if (isLoading) {
    return (

      <SpinnerLoader/>
    )
  }
  return (
    <>
       <Box display="flex" className="mx-0" justifyContent="space-between">
       <Box display="flex" justifyContent="space-between" className="tab-btn mx-2">
       <Box className="tab-btn-div">
            <div
              className={`div-btn ${
                activeDataList === "todo" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("todo")}
            >
              ToDo
            </div>
            <div
              className={`div-btn ${
                activeDataList === "applied" ? "active-tab-btn" : ""
              }`}
              onClick={() => setActiveDataList("applied")}
            >
              Applied
            </div>
            
          </Box>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={()=>handleOpenLegacyModal()}
          >
            <FaPlus size={12} className="new-plus-icon"/> New Legacy
          </Box>
        </Box>
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={activeDataList === 'todo' ? legacyDataColumns : appliedLegacyDataColumns}
          tableData={legacyDataList}
          type={"Legacy List"}
          role={role}
          expenseType = 'legacy'
        />
      </Box>
      {openLegacyModal && (
        <LegacyNew
          isOpen={openLegacyModal}
          onClose={handleCloseLegacyModal}
          legacyItems={legacyItem}
          role={role}
        />
      )}
    </>
  );
};

export default LegacyList;
