import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";



export const getVendorExpenseExcelData = (vendorList) => {
    let list = JSON.parse(JSON.stringify(vendorList));
    const exportData = list.map((data, index) => {
      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Date": data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Current Order Month": data.orderMonth && poMonthFormate(data.orderMonth),
        "Category": data.category,
        "Major Type": data.expense?.majorType,
        "Minor Type": data?.minorType,
        "Minor Sub Category": data?.minorSubCategory,
        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount  ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,
        
    };
   
      

      return content;
    });
    return exportData;
  };