import React, { useEffect, useState } from "react";
// Chakra imports
import {
  Box,
  Button,
  Flex,
  Icon,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import Card from "components/card/Card.js";
import Form from "react-bootstrap/Form";
// Custom components
import BarChart from "components/charts/BarChart";
import {
  barChartDataConsumption,
  barChartOptionsConsumption,
} from "variables/charts";
import { MdBarChart } from "react-icons/md";
import { getDifferenceFromPrevVal } from "jsFunctions/helperFunctions";

export default function WeeklyRevenue(props) {
  const { ...rest } = props;
  const [option, setOption] = useState([]);
  const [value, setValue] = useState([]);
  const [valueDifference, setValueDifference] = useState(null);
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const iconColor = useColorModeValue("#22b378", "white");
  const bgButton = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const bgHover = useColorModeValue(
    { bg: "secondaryGray.400" },
    { bg: "whiteAlpha.50" }
  );
  const bgFocus = useColorModeValue(
    { bg: "secondaryGray.300" },
    { bg: "whiteAlpha.100" }
  );

  useEffect(() => {
    setOption([]);
    setValueDifference(null);
    setOption(props.optionBarChart);
    setValue(props.valuesBarChart);
  }, [props.valuesBarChart, props.optionBarChart]);

  useEffect(() => {
    if (value.length > 0 && option.length > 0) {
      const diff = getDifferenceFromPrevVal(value);
      let getOption = barChartOptionsConsumption(option, diff);
      setValueDifference(getOption);
    }
  }, [value]);

  return (
    <Card
      align="center"
      direction="column"
      w="110%"
      h="600px"
      p={0}
      // style={{ overflow: "scroll" }}
      {...rest}
      className="weekly-expense-graph"
    >
      <Flex className="weekly-graph-flex" align="center" w="100%" >
        <Text
          me="auto"

        >
          Expenses
        </Text>
        <Form.Select
          size="sm"
          className="dashboardSelect"
          onChange={(e) => props.changeYear(e.target.value)}
          defaultValue="2024-2025"
        >
          <option>Select Financial Year</option>
          <option value="2021-2022">2021-2022</option>
          <option value="2022-2023">2022-2023</option>
          <option value="2023-2024">2023-2024</option>
          <option value="2024-2025">2024-2025</option>
          <option value="2025-2026">2025-2026</option>

        </Form.Select>
        <Form.Select
          size="sm"
          className="dashboardSelect"
          onChange={(e) => props.changeExpanse(e.target.value)}
          defaultValue="external"
        >
          <option>Select expense</option>
          <option value="external">External</option>
          <option value="internal">Internal</option>
          <option value="resource">Resource</option>
          <option value="tds">TDS</option>
          <option value="directTax">Direct-Tax</option>
        </Form.Select>
        <Button
          align="center"
          justifyContent="center"
          bg={bgButton}
          _hover={bgHover}
          _focus={bgFocus}
          _active={bgFocus}
          w="37px"
          h="37px"
          lineHeight="100%"
          borderRadius="10px"
          {...rest}
        >
          <Icon as={MdBarChart} color={iconColor} w="24px" h="24px" />
        </Button>
      </Flex>

      <Box h="500px" mt="20px" 
      >
        {valueDifference !== null && option.length > 0 && (
          <BarChart
            chartData={barChartDataConsumption(value)}
            chartOptions={valueDifference}
            selectExpYear={props?.selectExpYear}
          />
        )}
      </Box>
    </Card>
  );
}
