/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { AiFillDelete, AiFillEdit } from "react-icons/ai";

import { Box, Button, Text, Tooltip, useColorModeValue ,Menu,
  
  MenuButton,
  
  MenuList,Flex } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { fetchAsyncDeleteUser } from "redux/userSlice";
import { fetchAsyncGetUser } from "redux/userSlice";
import moment from "moment";
import UserForm from "components/allForm/UserForm";
import { fetchAsyncGetAllGst } from "redux/slices/gstSlice";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { currencyFormate } from "jsFunctions/helperFunctions";
import ExportDataModel from "components/exportData/ExportDataModel";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import GstInfoModel from "components/models/GstInfoModel";
import { FaEllipsisV,FaPlus } from "react-icons/fa";
import { CiExport, CiViewTable } from "react-icons/ci";
import { TbFileExport } from "react-icons/tb";
import { Spinner } from "react-bootstrap";
import SpinnerLoader from "components/Spinner/SpinnerLoader";
const EmbrillGst = () => {
  

  const [gstListData, setGstListData] = useState([]);
  const [selectedItem, setSelectedItem] = useState({});

  const [openGstInfoModel, setOpenGstInfoModel] = useState(false);
  const [loading, setLoading] = useState(false);

  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  

  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Gst');
      if(roleObj){

        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  // const customSort = (a, b) => {
  //   console.log(a.original,b.original,'ab')
  //   const getDateFromPoNo = (poNo) => {
  //     const [, month, year, day] = poNo.match(/PO-(?:E|T||)-(\d{2})(\d{2})-(\d{2})/);
  //     return new Date(`20${year}-${month}-${day}`);
  //   };
  
  //   return getDateFromPoNo(a.original.poNo) - getDateFromPoNo(b.original.poNo);
  // };
  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "PO No",
      accessor: "poNo",
      // sortType: customSort,

    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Company Name",
      accessor: "companyName",
    },
    {
      Header: "GST Number",
      accessor: "gstNumber",
    },
    {
      Header: "Base Amount",
      accessor: "baseAmount",
    },
    {
      Header: "IGST Amount",
      accessor: "igstAmt",
    },
    {
      Header: "SGST Amount",
      accessor: "sgstAmt",
    },
    {
      Header: "CGST Amount",
      accessor: "cgstAmt",
    },
    {
      Header: "Total GST",
      accessor: "totalGst",
    },
    
    
  ];

  const gstList = useSelector((state) => state.gstData);
  const dispatch = useDispatch();
 
  useEffect(() => {
    setLoading(true); 
    dispatch(fetchAsyncGetAllGst())
      .then(() => {
        setLoading(false); 
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading in case of error
      });
   
    
  }, []);

  const categoryMap = {
    ExternalExpense: 'External Expense',
    InternalExpanse: 'Internal Expense',
    ResourceExpense: 'Resource Expense'
  };
  useEffect(() => {
    if (gstList && gstList.length > 0) {
      const tableData = gstList.map((data, index) => {
        return {
          _id:data._id,
          no: index + 1,
          poNo: (
            <span 
              style={{ cursor: "pointer",color:'blue'}}
            >
              {data.poNo && poNumberFormate(data.poNo)}
            </span>
          ),
          category: categoryMap[data.category] || data.category,
          companyName: data?.gstFields?.companyId?.companyName || data?.gstFields?.companyName,
          gstNumber: data?.gstFields?.gstNumber,
          baseAmount:(
            <span>

              {data?.gstFields?.baseAmount ? currencyFormate(data?.gstFields?.baseAmount ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          igstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.igst ? currencyFormate(data?.gstFields?.gstAmount?.igst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          sgstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.sgst ? currencyFormate(data?.gstFields?.gstAmount?.sgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          cgstAmt: (
            <span>

              {data?.gstFields?.gstAmount?.cgst ? currencyFormate(data?.gstFields?.gstAmount?.cgst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
          totalGst: (
            <span>

              {data?.gstFields?.totalGst ? currencyFormate(data?.gstFields?.totalGst ): <div dangerouslySetInnerHTML={{ __html: `<span style="font-family: sans-serif">₹</span> ${Number(0).toFixed(2)}`}}/>}
            </span>
          ),
         
          date: dateTimeFormat(data.orderDate),
          
        };
      });
      setGstListData(tableData);
    } else {
      setGstListData([]);
    }
  }, [gstList]);

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();

    const data = gstList.filter((item)=> item?._id === _id)
    setSelectedItem(data[0]);
    setOpenGstInfoModel(true);
  }

  const closeGstInfoModel = () => {
    setOpenGstInfoModel(false);
    setSelectedItem({});
  };
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );
  let menuBg = useColorModeValue("white", "navy.800");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const [isOpen, setIsOpen] = useState(false);
  const [activeBtn,setActiveBtn] = useState("")

  const toggleMenu = (btn) => {
    setIsOpen(!isOpen);
    setActiveBtn(btn)
  };
  const handleClose = () => {   
    setActiveBtn("");   
    setIsOpen(false)
  };

  // If loading, return a spinner
  if (loading) {
    return (
      <SpinnerLoader/>
    );
  }
  return (
    <>
       <Box  display="flex" className="mx-3" justifyContent="space-between">
       <Box display="flex">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
        >
          Goods And Services Tax (GST)
        </Text>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
        <Box>
          <Menu>
          <MenuButton p="0px">
          <Box  className='menu-ellipsis-btn'><FaEllipsisV /></Box>

          </MenuButton>
          <MenuList
             boxShadow={shadow}
             p="0px"
             mt="10px"
             borderRadius="10px"
             bg={menuBg}
             border="none"
          >
            <Flex w="100%" mb="0px" flexDirection="column">
            <Box
                ps="20px"
                pt="10px"
                pb="8px"
                className="menu-text"
                borderBottom="1px solid gray"
                borderColor={borderColor}
                onClick={()=>toggleMenu('xlsx')}
                display="flex"
               
              >
                <CiExport size={20} className="mx-1"/> <span>Export XLSX</span>
                
              </Box>
             
            </Flex>
            <Flex w="100%" mb="0px" flexDirection="column">
            
            </Flex>
           <Box marginRight={40}>
           {isOpen && (
            <ExportDataModel
              filename={"Gst_"}
              type="gst"
              role={role}
              isOpen={isOpen}
              handleClose={handleClose}
              activeBtn={activeBtn}
            />
          )}
           </Box>
          </MenuList>
        </Menu>
          </Box>
        
      
        </Box>
     
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={gstListData}
          type={"gst"}
          role={role}
          expenseType = 'gst'
          //  getAllData={(data) => {
          //   handleOpenDirectTaxModal();
          // }}
          openInfoModal = {handleRowClick}
        />
      </Box>
      {openGstInfoModel && (
        <GstInfoModel
          openInfoModal={openGstInfoModel}
          closeInfoModal={closeGstInfoModel}
          selectedData={selectedItem}   
          role={role}
     
        />
      )}
    </>
  );
};

export default EmbrillGst;
