import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import {  useEffect, useState } from "react";

const TdsAmountTable = ({ editTdsInfo, currentTdsInfo, type }) => {

const [editTdsData,setEditTdsData] = useState([]);
const [currentTdsData,setCurrentTdsData] = useState([]);


useEffect(()=>{
    if(editTdsInfo){
        const tds = editTdsInfo?.tdsApplicable;
        setEditTdsData([tds])
        
    }
    if(currentTdsInfo){

      const obj = {
        isTds:currentTdsInfo?.isTDS || 'false',
        isTDSPO:currentTdsInfo?.isTDSPO || 'false',
        TDSPONum:currentTdsInfo?.TDSPONum, 
        totalAmount:currentTdsInfo?.totalAmount,
        tdsPercent:currentTdsInfo?.tdsPercent,
        totalTDS: currentTdsInfo?.totalTDS
      }
      setCurrentTdsData([obj])
      
  }
  },[])

    return (

        type === 'current' ?
        <div className="info-table change-modal-table">

        <Table className="table-info">
          <Thead>
            <Tr>
              <Th>TDS Amount</Th>
              <Th>TDS Percent</Th>
              <Th>TDS Total Amount</Th>
              <Th>TDS PO</Th>


            </Tr>
          </Thead>
          <Tbody>
            {editTdsData?.map((item, index) => {
              return (
                <Tr>
                  <Td className={`${item?.totalAmount != currentTdsData[index]?.totalAmount  ? 'data_compare_red' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.totalAmount || 0))}</Box></Td>
                  <Td className={`${item?.tdsPercent != currentTdsData[index]?.tdsPercent  ? 'data_compare_red' : '' }`}><Box>{item?.tdsPercent || 0} %</Box></Td>
                  <Td className={`${item?.totalTDS != currentTdsData[index]?.totalTDS  ? 'data_compare_red' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.totalTDS || 0))}</Box></Td>
                  <Td className={`${(item?.generateTdsPO?.isTDSPO ? 'true' : 'false') !== currentTdsData[index]?.isTDSPO  ? 'data_compare_red' : '' }`}>
                  {item?.generateTdsPO?.isTDSPO
                              ? "true"
                              : "false"}
                  </Td>

                </Tr> 

              );
            })}
          </Tbody>
        </Table>
      </div>
            :
            <div className="info-table change-modal-table">

                <Table className="table-info">
                    <Thead>
                        <Tr>
                            <Th>TDS Amount</Th>
                            <Th>TDS Percent</Th>
                            <Th>TDS Total Amount</Th>
                            <Th>TDS PO</Th>


                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentTdsData?.map((item, index) => {

                          return (
                            <Tr>
                               <Td className={`${item?.totalAmount != editTdsData[index]?.totalAmount  ? 'data_compare_green' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.totalAmount || 0))}</Box></Td>
                  <Td className={`${item?.tdsPercent != editTdsData[index]?.tdsPercent  ? 'data_compare_green' : '' }`}><Box>{item?.tdsPercent || 0} %</Box></Td>
                  <Td className={`${item?.totalTDS != editTdsData[index]?.totalTDS  ? 'data_compare_green' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.totalTDS || 0))}</Box></Td>
                  <Td className={`${item?.isTDSPO !== (editTdsData[index]?.generateTdsPO?.isTDSPO ? 'true' : 'false')  ? 'data_compare_green' : '' }`}>
                  {item?.isTDSPO
                              ? "true"
                              : "false"}
                  </Td>

                            </Tr> 

                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>

    )


}

export default TdsAmountTable;
