import React, { useEffect, useState } from "react";

// import "antd/dist/antd.css";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { createListForSingleOption, createOption } from "./createableSelect";
import "rsuite/dist/rsuite.min.css";
import DatePicker from "react-datepicker";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { fetchAsyncAddValueToFormOptions } from "redux/formOptions";
import CustomSelect from "components/CustomSelect";
import { fetchAsyncDeleteFormOptions } from "redux/formOptions";
import { fetchAsyncEditFormOptions } from "redux/formOptions";
import { fetchAsyncGetFormOptions } from "redux/formOptions";
import { FaTrash } from "react-icons/fa6";
import { InputGroup } from "react-bootstrap";
import CurrencyFormat from "react-currency-format";
import { currencyFormate } from "jsFunctions/helperFunctions";
import { Box } from "@chakra-ui/react";
import { getOptionDataOnEdit } from "jsFunctions/calculateGst";


const RefundInfoListComponent = ({ getData, autoCount, editData,paymentData , deleteMultipleListItem,expenseType, role}) => {
  const formOptionsList1 = useSelector((state) => state.formOptions);

  const [payeeValue, setPayeeValue] = useState("");
  const [bankValue, setBankValue] = useState("");
  const [refNo, setRefNo] = useState("");
  const [formOptionsList, setFormOptionsList] = useState([])

  const [selectedDate, setSelectedDate] = useState(
    paymentData?.paymentDate ? new Date(paymentData?.paymentDate) : new Date()
  );
  const [paymentAmount, setPaymentAmount] = useState("");
  const [addTogglePaymentIdx, setAddTogglePaymentIdx] = useState(true);
  const [payeeOption, setPayeeOption] = useState([]);
  const [bankOption, setBankOption] = useState([]);
  const dispatch = useDispatch();

  const [preAmount, setPreAmount] = useState(0)
  const onChangeValues = (payValue, bankVal, payAmount, ref, date) => {
    const data = {
      payee: payValue.value,
      payeeId: payValue?.id || null,
      bank: bankVal.value,
      bankId: bankVal?.id || null,
      amount: payAmount,
      refNo: ref,
      paymentDate: date,
    };
    getData(data);
  };

  useEffect(()=>{
    const data = editData?.refundInfo?.list[autoCount]
    const amount = data?.amount ? data?.amount : 0
    setPreAmount(amount)
  },[editData])


  useEffect(()=>{
    if(formOptionsList1 && formOptionsList1?.length > 0){
      const internalFormOptionList = formOptionsList1.filter((item)=> item.expenseType === expenseType)
      setFormOptionsList(internalFormOptionList)
    }
  },[formOptionsList1])

  useEffect(() => {
    if (paymentData && formOptionsList.length > 0) {
      const payeeData = getOptionDataOnEdit(
        formOptionsList,
        paymentData?.payee,
        paymentData?.payeeId?._id, 
        "payee"
      );
      if (payeeData) {
        setPayeeValue({
          label: payeeData.label,
          value: payeeData.value,
          id: payeeData?._id
        });
      }
     
      const bakData = getOptionDataOnEdit(
        formOptionsList,
        paymentData?.bank,
        paymentData?.bankId?._id,    
        "bank"
      );
      if (bakData) {
        setBankValue({
          label: bakData.label,
          value: bakData.value,
          id: bakData?._id
        });
      }
      setRefNo(paymentData.refNo);
      setPaymentAmount(paymentData?.amount);
    }
    else{
      setPaymentAmount(paymentData?.amount);

    }
  }, [formOptionsList, paymentData]);

  // form option list

  useEffect(() => {
    let payeeList = createListForSingleOption(formOptionsList, "payee");
    let bankList = createListForSingleOption(formOptionsList, "bank");
    setPayeeOption(payeeList);
    setBankOption(bankList);
  }, [formOptionsList]);

  const createNewFormOption = (data, type) => {
    dispatch(
      fetchAsyncAddValueToFormOptions({
        label: data.label,
        value: data.value,
        type,
        expenseType:expenseType
      })
    )
      .then((res) => {
        if (res.payload.value) {
          const setData = {
            label: res.payload.label,
            value: res.payload.value,
            id: res.payload._id

          };
          if (type === "payee") {
            setPayeeValue(setData);
          }

          if (type === "bank") {
            setBankValue(setData);
          }
          Swal.fire({
            title: "Successfully Added",

            icon: "success",
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            customClass: {
              container: "sweet_containerImportant",
              confirmButton: "sweet_confirmbuttonImportant",
            },
          }).then((result) => {});
        }
      })
      .catch((err) => {
        Swal.fire({
          title: "Something went wrong",

          icon: "warning",
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Yes",
          customClass: {
            container: "sweet_containerImportant",
            confirmButton: "sweet_confirmbuttonImportant",
          },
        }).then((result) => {});
      });
  };

  const deleteFromOption = (option, type) => {
    const data = formOptionsList.find(
      (item) => item._id === option.id && item.type === type
    );
    dispatch(fetchAsyncDeleteFormOptions(data._id));
  };
  const editFormOption = (option,newValue, type) => {
    const data = formOptionsList.find(
      (item) => item._id === option.id && item.type === type
    );
    
    dispatch(fetchAsyncEditFormOptions({
      id:data._id,
      newData:{
        optionValue:newValue,
        expenseType:expenseType
      }
    })).then((res)=>{
      if (res.payload.value) {
        const setData = {
          label: res.payload.label,
          value: res.payload.value,
          id: res.payload._id
        };
        if (type === "payee") {
          setPayeeValue(setData);
        }

        if (type === "bank") {
          setBankValue(setData);
        }
        dispatch(fetchAsyncGetFormOptions())
      }
    }).catch((err)=>{
      console.log(err)
    })
  };

  return (
    <div
      className={`${addTogglePaymentIdx ? "show" : "hide"}`}
    >
      <div className="payment-list_array mt-3">
        <div className="d-flex">
        <span className="payment-details">Refund Details {autoCount + 1}</span>

        </div>
        <div
          className="d-flex toggleImage-content"
          onClick={() => {
            setAddTogglePaymentIdx(!addTogglePaymentIdx);
          }}
        >
          <span className="toggleImage">
            <img
              src="https://cdn-icons-png.flaticon.com/128/4655/4655143.png"
              alt="minus"
              style={{ width: "20px", height: "35px" }}
              className={addTogglePaymentIdx ? "closed" : "open"}
            />
          </span>
        </div>
        {((role === 'Admin' || role === 'SuperAdmin') && preAmount !== 0) ?
              <div className="d-flex"
                onClick={() => deleteMultipleListItem()}
              >
                 <span className="mt">
                   <FaTrash
                     style={{
                       fontSize: "16px",
                       color: "red",
                       cursor: "pointer",
                     }}
                   />
                 </span>
             </div>
             :
             preAmount === 0 &&
             <div className="d-flex"
                onClick={() => deleteMultipleListItem()}
              >
                 <span className="mt">
                   <FaTrash
                     style={{
                       fontSize: "16px",
                       color: "red",
                       cursor: "pointer",
                     }}
                   />
                 </span>
             </div>
        }
        </div>
       
      <div
        className={`payment-inner_box ${
          addTogglePaymentIdx ? "show-content" : "hide-content"
        }`}
      >
        <Row>
          <Col>
          <Box display="flex" className="input-box">
            <Form.Label>Payee</Form.Label>
            <CustomSelect
              options={payeeOption}
              placeHolder={<div>Type or select...</div>}
              onChange={(newValue) => {
                onChangeValues(
                  newValue,
                  bankValue,
                  paymentAmount,
                  refNo,
                  selectedDate
                );
                setPayeeValue(newValue);
              }}
              isSearchable
              onCreateOption={(e) => {
                createNewFormOption(createOption(e), "payee");
              }}
              onDeleteOption={(option) => {
                deleteFromOption(option, "payee");
              }}
              onEditOption = {(option,newValue)=>{
                editFormOption(option,newValue,"payee")
              }}
              defaultValue={payeeValue}
              role = {role}
            />
            </Box>
          </Col>
          <Col>
          <Box display="flex" className="input-box">
            <Form.Label>Refund Amount <span className="span-star">*</span></Form.Label>
            

            <InputGroup>
            <InputGroup.Text><span className="rupee-symbol">₹</span> </InputGroup.Text>
            <CurrencyFormat
                        customInput={Form.Control}
                        type="text"
                        defaultValue={paymentAmount}                        
                        value={paymentAmount || ""}
                        onValueChange={(e) => {
                          onChangeValues(
                            payeeValue,
                            bankValue,
                            e.value,
                            refNo,
                            selectedDate
                          );
                          setPaymentAmount(e.value);
                        }}
                        thousandSeparator={true}
                        // prefix={"$"}
                        decimalScale={2}
                        fixedDecimalScale={true}

                      />
            
            </InputGroup>
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
          <Box display="flex" className="input-box">
            <Form.Label>Bank</Form.Label>
            <CustomSelect
              options={bankOption}
              placeHolder={<div>Type or select...</div>}
              onChange={(newValue) => {
                onChangeValues(
                  payeeValue,
                  newValue,
                  paymentAmount,
                  refNo,
                  selectedDate
                );
                setBankValue(newValue);
              }}
              isSearchable
              onCreateOption={(e) => {
                createNewFormOption(createOption(e), "bank");
              }}
              onDeleteOption={(option) => {
                deleteFromOption(option, "bank");
              }}
              onEditOption = {(option,newValue)=>{
                editFormOption(option,newValue,"bank")
              }}
              defaultValue={bankValue}
              role = {role}

            />
            </Box>
          </Col>

          <Col>
          <Box display="flex" className="input-box">
            <Form.Label>Reference Number</Form.Label>
            <Form.Control
              type="text"
              name="referenceNo"
              placeholder="Reference Number"
              onChange={(e) => {
                onChangeValues(
                  payeeValue,
                  bankValue,
                  paymentAmount,
                  e.target.value,
                  selectedDate
                );
                setRefNo(e.target.value);
              }}
              defaultValue={refNo}
            />
            </Box>
          </Col>
        </Row>
        <Row>
          <Col>
          <Box display="flex" className="input-box">
            <Form.Label>Date</Form.Label>

            <DatePicker
              name="paymentdate"
              className="monthDate"
              dateFormat="dd-MMM-yyyy"
              selected={selectedDate}
              onChange={(date) => {
                onChangeValues(
                  payeeValue,
                  bankValue,
                  paymentAmount,
                  refNo,
                  date
                );
                setSelectedDate(date);
              }}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />
            </Box>
          </Col>
          
          <Col></Col>
        </Row>
       
      </div>
     
    </div>
  );
};

export default RefundInfoListComponent;
