export const expenseDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Po No",
      accessor: "poNo",
    },
    {
      Header: "Purchase Order Date",
      accessor: "purchasedate",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Major Type",
      accessor: "majorType",
    },
    {
      Header: "Minor Type",
      accessor: "minorType",
    },
    
    {
      Header: "Approved Amount",
      accessor: "approvedAmount",
    },

  ];

  export const tdsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Po No",
      accessor: "poNo",
    },
    {
      Header: "Order Date",
      accessor: "orderdate",
    },
   
    {
      Header: "Total Amount",
      accessor: "totalAmount",
    },
    {
      Header: "Approved Amount",
      accessor: "approvedAmount",
    },
    
  ];


  export const gstDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "PO No",
      accessor: "poNo",
      // sortType: customSort,

    },
    {
      Header: "Date",
      accessor: "date",
    },
    {
      Header: "Category",
      accessor: "category",
    },
   
    {
      Header: "Base Amount",
      accessor: "baseAmount",
    },
    {
      Header: "IGST Amount",
      accessor: "igstAmt",
    },
    {
      Header: "SGST Amount",
      accessor: "sgstAmt",
    },
    {
      Header: "CGST Amount",
      accessor: "cgstAmt",
    },
    {
      Header: "Total GST",
      accessor: "totalGst",
    },
    
    
  ];



