/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";

import { Badge, Box, Button, IconButton, Text, Tooltip, useColorModeValue } from "@chakra-ui/react";

// import noDataFound from "../../assets/img/no_data_found.png";
import ColumnTable from "./embrillTable/ColumnTable";
import { useDispatch, useSelector } from "react-redux";

import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { performSearch } from "components/navbar/searchUtils";
import { useLocation } from "react-router-dom";
import ClientProject from "components/allForm/clientProject";
import { fetchAsyncGetClient } from "redux/clientProjectSlice";
import { AiFillDelete, AiFillPlusCircle } from "react-icons/ai";
import Swal from "sweetalert2";
import { fetchAsyncDeleteClient } from "redux/clientProjectSlice";
import ProjectPopover from "components/models/addProjectModal";
import { FaPlus } from "react-icons/fa";
import { MdModeEdit } from "react-icons/md";
import { Spinner } from "react-bootstrap";
import SpinnerLoader from "components/Spinner/SpinnerLoader";

const EmbrillClientProjects = () => {
  
  const dispatch = useDispatch();

  const [clientListData, setClientListData] = useState([]);

  const dateRange = useSelector((state) => state.dateRange); // Access the dateRange slice
  const { startDate, endDate } = dateRange;

  const location = useLocation();
  const currUser = useSelector((state) => state.auth.currUser);
  const [role,setRole] = useState('')  
  const searchValue = useSelector((state) => state.searchValue); // Access the dateRange slice
  const {searchText} = searchValue;
  
  useEffect(()=>{
    if(currUser && currUser?.auth){

      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === 'Client and Projects');
      if(roleObj){
        setRole(roleObj?.access)
      }
    }
   
  },[currUser])

  
  const columnsDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Client Name",
      accessor: "clientName",
    },
   
    {
      Header: "Last Updated",
      accessor: "lastUpdated",
    },
    {
      Header: "Projects",
      accessor: "projects",
    },
    {
      Header: "Action",
      accessor: "action",
    },
    
  ];
  const [openClientModal, setOpenClientModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [clientEditData, setClientEditData] = useState(false);

  const handleOpenClientModal = () => {
    setOpenClientModal(true);
  };


  const handleCloseClientModal = () => {
    setOpenClientModal(false);
    setOpenEditModal(false);
    setClientEditData(null);
    handlerRefreshData();
  };
  const handlerRefreshData = useCallback(
    () => {
      performSearch(dispatch, location, searchText, "", "");
    },
    [location, dispatch,searchText]
  );

  const [loading, setLoading] = useState(false);

  const clientList = useSelector((state) => state.clientData);

  const deleteClientProject = (data) => {
    dispatch(fetchAsyncDeleteClient(data._id)).then(() => {
      handlerRefreshData();
    });
  };
  
  useEffect(() => {
    if (clientList && clientList.length > 0) {
      const tableData = clientList.map((data, index) => {
        return {
          _id:data._id,
          no: index + 1,
          
          clientName: (
            <span 
              style={{ cursor: "pointer",color:'blue'}}
            >
              {data?.clientName}
            </span>
          ),
          lastUpdated: dateTimeFormat(data.updated_at),
          projects:(
            <>
            
            <ProjectPopover
            clientId={data._id}
            projects={data.projects}
            refreshData={() => dispatch(fetchAsyncGetClient())}
            role={role}
          />

            </>
          ),
          action: (
            <div className="d-flex">
             
              {role === "Admin" ? (
                <>
                <MdModeEdit
                className="me-2"
                color="blue"
                size={16}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  handleRowClick(e,data._id)
                }}
              />
              
                <AiFillDelete
                  style={{ cursor: "pointer",marginLeft:'10px' }}
                  size={16}

                  onClick={(e) => {
                    e.preventDefault();
                    Swal.fire({
                      title: `Are you sure? you want to delete client <span style="color: #d33">Client Name : ${data?.clientName && data?.clientName}</span>.`,
                      text:'This action cannot be undone.',
                      icon: "warning",
                      confirmButtonColor: "#3085d6",
                      cancelButtonColor: "#d33",
                      confirmButtonText: "Yes, delete",
                      showCancelButton: true,
                      customClass: {
                        container: "sweet_containerImportant",
                        confirmButton: "sweet_confirmbuttonImportant",
                      },
                    }).then((result) => {
                      if (result.isConfirmed) {
                        deleteClientProject(data);
                      }
                    });
                  }}
                />
                </>
              ):'No Access'}
            </div>
          ),
        };
      });
      setClientListData(tableData);
    } else {
      setClientListData([]);
    }
  }, [clientList]);

  const handleRowClick = (e,_id)=>{
    e.stopPropagation();
    const data = clientList.filter((item)=> item?._id === _id)
    setOpenEditModal(true);
    setClientEditData(data[0]);
    handleOpenClientModal();
  }

  useEffect(() => {
    setLoading(true); 
    dispatch(fetchAsyncGetClient())
      .then(() => {
        setLoading(false); // Stop loading after fetching is complete
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false); // Stop loading in case of error
      });
   
  }, []);

  const textColor = useColorModeValue("secondaryGray.900", "white");

  // If loading, return a spinner
  if (loading) {
    return (
      <SpinnerLoader/>
    );
  }
  return (
    <>
      <Box display="flex" className="mx-0" justifyContent="space-between">
      <Box display="flex">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
          mx={2}
        >
          Client And Projects
        </Text>
       
        </Box>
        <Box display="flex" mt={2} className="add-new-btn">
          <Box
            display="flex"
            alignItems="center"
            className="new-btn"
            onClick={handleOpenClientModal}
          >
            <FaPlus size={12} className="new-plus-icon"/> New
          </Box>
            
        
         
        </Box>
      
      
      </Box>
      <Box pt={{ base: "40px", md: "10px", xl: "10px" }}>
        <ColumnTable
          columnsData={columnsDataColumns}
          tableData={clientListData}
          type={"Client And Projects"}
          role={role}
          expenseType = 'client-project'
          openInfoModal = {handleRowClick}
        />
      </Box>
      {openClientModal && (
        <ClientProject
          openModal={openClientModal}
          closeModal={handleCloseClientModal}
          isEditForm={openEditModal}
          editItem={clientEditData}
          role={role}
          refreshEditData={handlerRefreshData}

        />
      )}
      
    </>
  );
};

export default EmbrillClientProjects;
