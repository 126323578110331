// Chakra Imports
import {
  Avatar,
  Box,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useColorModeValue,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from "@chakra-ui/react";
// Custom Components
import { ItemContent } from "components/menu/ItemContent";
import { SearchBar } from "components/navbar/searchBar/SearchBar";
import { SidebarResponsive } from "components/sidebar/Sidebar";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useRef, useState } from "react";
// Assets
import { MdNotificationsNone, MdOutlineLogout, MdPerson } from "react-icons/md";
import { FaCalendarAlt, FaEthereum } from "react-icons/fa";
import routes from "routes.js";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import DatePicker from "react-datepicker";
import { clearToken } from "redux/slices/Auth";

import "react-datepicker/dist/react-datepicker.css";

import { getStartDate } from "jsFunctions/helperFunctions";
import { getEndDate } from "jsFunctions/helperFunctions";

import { performSearch } from "./searchUtils";
import { setDateRangeGlobal } from "redux/slices/dateRangeSlice";
import { endOfMonth, startOfMonth } from "rsuite/esm/internals/utils/date";
import { setSearchTextGlobal } from "redux/slices/searchTextSlice";
import moment from "moment";
import { Col, Row } from "react-bootstrap";
const apiURL = process.env.REACT_APP_BASE_URL;

export default function HeaderLinks(props) {
  const { currUser } = useSelector((state) => state.auth);
  const { secondary } = props;
  const now = new Date();

  const month = now.toLocaleString('default', { month: 'short' });
  const year = now.getFullYear();

  const [placeholder, setPlaceholder] = useState(month)
  // const [dateRange, setDateRange] = useState([null, null]);
  const [dateRange, setDateRange] = useState([
    new Date(new Date().getFullYear(), new Date().getMonth(), 1), // Start of current month
    new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1) // End of current month
  ]);
  const [startDate, endDate] = dateRange;
  const dispatch = useDispatch();

  const [searchText, setSearchText] = useState("");
  const [isSearch, setIsSearch] = useState(false);


  const [startMonth, setStartMonth] = useState(null);
  const [endMonth, setEndMonth] = useState(null);

  const [currentLocation, setCurrentLocation] = useState("/dashboard");
  // Chakra Color Mode

  const navbarIcon = useColorModeValue("gray.400", "white");
  let menuBg = useColorModeValue("white", "navy.800");
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const textColorBrand = useColorModeValue("brand.700", "brand.400");
  const ethColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("#E6ECFA", "rgba(135, 140, 189, 0.3)");
  const ethBg = useColorModeValue("secondaryGray.300", "navy.900");
  const ethBox = useColorModeValue("white", "navy.800");
  const shadow = useColorModeValue(
    "14px 17px 40px 4px rgba(112, 144, 176, 0.18)",
    "14px 17px 40px 4px rgba(112, 144, 176, 0.06)"
  );


  const location = useLocation();

  useEffect(() => {
    setCurrentLocation(location.pathname);
    setDateRange([null, null]);
    setSearchText("")
    setIsSearch(false)
    dispatch(setDateRangeGlobal({ startDate:null, endDate:null}));
    dispatch(setSearchTextGlobal({ searchText:""}));
    setStartMonth(null)
    setEndMonth(null)
    const month = now.toLocaleString('default', { month: 'short' });
    setPlaceholder(month)

  }, [location]);

  const search = useCallback(
    (value, startDate, endDate) => {

      performSearch(dispatch, location, value, startDate, endDate);

    },
    [location, dispatch]
  );


  useEffect(() => {
    if(isSearch){
    
      if (dateRange[0] && dateRange[1]) {
        let start = getStartDate(dateRange[0]);
        let end = getEndDate(dateRange[1]);
        dispatch(setDateRangeGlobal({ startDate:start, endDate:end }));
       
        search(searchText.trim(), start, end);

        
      } else {
        dispatch(setDateRangeGlobal({ startDate:null, endDate:null}));
        search(searchText.trim());

       
      }
    }
  }, [searchText,dateRange]);

 

  const logOutHandle = async () => {
    try {
      localStorage.removeItem("userData");
      dispatch(clearToken());

      window.location.href = `${apiURL}/signout`;
    } catch (error) {
      console.log(error);
    }
  };

  const [role, setRole] = useState('')


  const [isAdvance,setIsAdvance] = useState(false);

 
  const handleIsAdvance = ()=>{
    setIsAdvance(!isAdvance)
    openModal();
  }
  useEffect(()=>{
    if(props?.brandText){
      const auth = currUser?.auth;
      const roleObj = auth && auth?.length > 0 && auth.find((item)=> item?.module === props?.brandText);
      if(roleObj){
        setRole(roleObj?.access)
      }
    }
  },[currUser?.auth,props?.brandText])

  const pathArray = ["/dashboard","/all-category","/vendor","/embrill-profile","/client-project","/users","/legacy"]

  const [isModalOpen, setIsModalOpen] = useState(false); // New state for modal

  // Function to handle opening the modal
  const openModal = () => {
    setIsModalOpen(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleSearch = () => {
    if (startMonth && endMonth) {
      const start = moment(startMonth).startOf("month").toDate();
      const end = moment(endMonth).endOf("month").toDate();
      setIsSearch(true)
      setDateRange([start, end]);
      const month = start.toLocaleString('default', { month: 'short' });
      setPlaceholder(month)
      closeModal(); 
    }
    
  };
  
  // Handle search using the selected start and end months
  const handleDateIcon = (date) => {
    if (date) {
      const start = date;
      const end = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      setIsSearch(true)
      setDateRange([start, end]);
      const month = date.toLocaleString('default', { month: 'short' });
      setPlaceholder(month)
      setStartMonth(date)

      dispatch(setSearchTextGlobal({ searchText: "" }));

      setSearchText("");
      closeModal(); 
    }

  };
  return (
    <>
      <Flex
        alignItems="center"
        flexDirection="row"
        flexWrap={secondary ? { base: "wrap", md: "nowrap" } : "unset"}
        borderRadius="30px"
      >
       
      {(currentLocation && !pathArray.includes(currentLocation)) && (
        <Flex align="center">
          <InputGroup>
            <DatePicker
              selected={startMonth}
              onChange={(date) => handleDateIcon(date)}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              className="monthDate date-filter-input"
              placeholderText={placeholder ? placeholder : "Select Start Month"}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
            />

            {/* Calendar Icon */}
            <InputRightElement className="date-right-element" pointerEvents="none">
              <Icon
                as={FaCalendarAlt}
                className="mx-1"
                color="gray.500"
                cursor="pointer"
                onClick={() => handleDateIcon(new Date())}
                w="20px"
                h="20px"
              />
              <span className="month-placeholder">{placeholder}</span>
            </InputRightElement>
          </InputGroup>
        </Flex>
        )}

        {/* Advanced Search Modal */}
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent
            className="modal-content-search"
            top="0" // Position it at the top
            margin="0 auto" // Center it horizontally
            borderRadius="md" // Optional: Rounded corner
          >
            <ModalHeader className="modal-header-search">
              Advanced Search
            </ModalHeader>
            <ModalCloseButton />

            <ModalBody>
              <Row>
                <Col>
                  <Text mb={2}>
                    Start Month :<span className="span-star">*</span>
                  </Text>

                  <InputGroup>
                    <DatePicker
                      selected={startMonth}
                      onChange={(date) => setStartMonth(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      className="monthDate"
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                      placeholderText={
                        placeholder ? placeholder : "Select Start Month"
                      }
                    />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FaCalendarAlt} color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </Col>
                <Col></Col>
              </Row>
              <Row>
                <Col>
                  <Text mt={4} mb={2}>
                    End Month :<span className="span-star">*</span>
                  </Text>
                  <InputGroup>
                    <DatePicker
                      selected={endMonth}
                      onChange={(date) => setEndMonth(date)}
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      className="monthDate"
                      placeholderText={
                        placeholder ? placeholder : "Select End Month"
                      }
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode="select"
                    />
                    <InputRightElement pointerEvents="none">
                      <Icon as={FaCalendarAlt} color="gray.300" />
                    </InputRightElement>
                  </InputGroup>
                </Col>
                <Col></Col>
              </Row>
            </ModalBody>
            <ModalFooter
              className="date-search-footer"
              display="flex"
              justifyContent="center"
            >
              <Button className="btn search-btn" ml={3} onClick={handleSearch}>
                Search
              </Button>
              <Button className="btn cancel-btn" onClick={closeModal}>
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        { !["/dashboard","/all-category","/legacy"].includes(currentLocation) &&
           (
            <SearchBar
              mb={secondary ? { base: "10px", md: "unset" } : "unset"}
              me="10px"
              ms="10px"
              borderRadius="30px"
              onChange={(value) => {
                setIsSearch(true);
                dispatch(setSearchTextGlobal({ searchText: value }));
                setSearchText(value);
              }}
              handleIsAdvance={() => handleIsAdvance()}
              currentLocation={currentLocation}
              value={searchText}
            />
          )}
        <Flex
          bg={ethBg}
          display={secondary ? "flex" : "none"}
          borderRadius="30px"
          ms="auto"
          p="6px"
          align="center"
          me="6px"
        >
          <Flex
            align="center"
            justify="center"
            bg={ethBox}
            h="29px"
            w="29px"
            borderRadius="30px"
            me="7px"
          >
            <Icon color={ethColor} w="9px" h="14px" as={FaEthereum} />
          </Flex>
          <Text
            w="max-content"
            color={ethColor}
            fontSize="sm"
            fontWeight="700"
            me="6px"
          >
            1,924
            <Text as="span" display={{ base: "none", md: "unset" }}>
              {" "}
              ETH
            </Text>
          </Text>
        </Flex>
        <SidebarResponsive routes={routes} />
        <Menu>
          <MenuButton p="0px">
            <Icon
              mt="6px"
              as={MdNotificationsNone}
              color={navbarIcon}
              w="18px"
              h="18px"
              me="10px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="20px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
            mt="22px"
            me={{ base: "30px", md: "unset" }}
            minW={{ base: "unset", md: "400px", xl: "450px" }}
            maxW={{ base: "360px", md: "unset" }}
          >
            <Flex jusitfy="space-between" w="100%" mb="20px">
              <Text fontSize="md" fontWeight="600" color={textColor}>
                Notifications
              </Text>
              <Text
                fontSize="sm"
                fontWeight="500"
                color={textColorBrand}
                ms="auto"
                cursor="pointer"
              >
                Mark all read
              </Text>
            </Flex>
            <Flex flexDirection="column">
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                px="0"
                borderRadius="8px"
                mb="10px"
              >
                <ItemContent info="Test Notification" aName="Alicia" />
              </MenuItem>
            </Flex>
          </MenuList>
        </Menu>

        <Menu>
          <MenuButton p="0px">
            <Avatar
              _hover={{ cursor: "pointer" }}
              color="white"
              name={currUser?.name}
              bg="#11047A"
              size="sm"
              w="40px"
              h="40px"
            />
          </MenuButton>
          <MenuList
            boxShadow={shadow}
            p="0px"
            mt="10px"
            borderRadius="20px"
            bg={menuBg}
            border="none"
          >
            <Flex w="100%" mb="0px" flexDirection="column">
              <Text
                ps="20px"
                pt="16px"
                w="100%"
                // borderBottom="1px solid"
                borderColor={borderColor}
                fontSize="sm"
                fontWeight="700"
                color={textColor}
              >
                👋&nbsp; Hey, {currUser?.name}
              </Text>
              {role && (
                <Text
                  ps="20px"
                  textTransform="uppercase"
                  pb="10px"
                  w="100%"
                  borderBottom="1px solid"
                  borderColor={borderColor}
                  fontSize="sm"
                  fontWeight="600"
                  color={textColor}
                >
                  <Flex w="100%" mb="0px">
                    <MdPerson size={20} color="red" />{" "}
                    <span style={{ color: "#007bff" }}>&nbsp;{role}</span>
                  </Flex>
                </Text>
              )}
            </Flex>
            <Flex flexDirection="column" p="10px">
              {/*              
              <MenuItem
                _hover={{ bg: "none" }}
                _focus={{ bg: "none" }}
                color="red.400"
                borderRadius="8px"
                px="14px"
                onClick={() => {
                  logOutHandle();
                }}
              > */}
              <Box
                onClick={() => {
                  logOutHandle();
                }}
                display="flex"
                justifyContent="center"
                className="logout-btn"
              >
                <MdOutlineLogout size={20} className="mx-1" />{" "}
                <span>Log out</span>
              </Box>

              {/* </MenuItem> */}
            </Flex>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
  onOpen: PropTypes.func,
};
