import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import {
  Table,
  Td,
  Tr,
  Th,
  Tbody,
  Thead,
  Tfoot,
  Box,
} from "@chakra-ui/react";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { useEffect, useState } from "react";
import Pagination from "react-bootstrap/Pagination"; // Import Pagination component
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";

const GstInfo = ({ selectedItem }) => {
  const [totalAmount, setTotalAmount] = useState({
    baseAmount: 0,
    cgst: 0,
    sgst: 0,
    igst: 0,
    gst: 0,
  });
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 3; // Set the number of items per page

  useEffect(() => {
    const gstId = selectedItem?.gstApplicable?.gstId;
    if (gstId && gstId.length > 0) {
      const totals = gstId.reduce(
        (acc, item) => {
          const { baseAmount, gstAmount } = item?._id;
          const { sgst, igst, cgst } = gstAmount;

          acc.baseAmount += parseFloat(baseAmount || 0);
          acc.cgst += parseFloat(cgst || 0);
          acc.sgst += parseFloat(sgst || 0);
          acc.igst += parseFloat(igst || 0);

          return acc;
        },
        {
          baseAmount: 0,
          cgst: 0,
          sgst: 0,
          igst: 0,
        }
      );

      totals.gst = totals.cgst + totals.sgst + totals.igst;
      setTotalAmount(totals);
    }
  }, [selectedItem]);

  // Calculate pagination details
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = selectedItem?.gstApplicable?.gstId?.slice(indexOfFirstItem, indexOfLastItem) || [];
  const pageCount = Math.ceil((selectedItem?.gstApplicable?.gstId?.length || 0) / itemsPerPage);

  return (
    <>
      <Row className="info-row">
        <Col className="check-cross-mark mt-0">
          <Form.Label>
            <span
              className={
                selectedItem?.gstApplicable?.isGST
                  ? "checkmark"
                  : "crossmark"
              }
            ></span>{" "}
            Is GST applicable?
          </Form.Label>
        </Col>
      </Row>
      {currentItems.length ? (
        <div className="info-table">
          <Box overflowX="auto">
            <Table size="md" className="table-info" variant="striped">
              <Thead>
                <Tr>
                  <Th>No.</Th>
                  <Th>Base Amount</Th>
                  <Th>Company Name</Th>
                  <Th>GST Number</Th>
                  <Th>GST Type</Th>
                  <Th>GST Percentage (%)</Th>
                  <Th>IGST</Th>
                  <Th>SGST</Th>
                  <Th>CGST</Th>
                  <Th>Total GST</Th>
                </Tr>
              </Thead>
              <Tbody>
                {currentItems.map((item, index) => {
                  const { baseAmount, companyName, gstNumber,gstType,gstPercentage, gstAmount } = item?._id;
                  const { sgst, igst, cgst } = gstAmount;
                  const totalGst = parseFloat(sgst || 0) + parseFloat(igst || 0) + parseFloat(cgst || 0);
                  return (
                    <Tr key={index}>
                      <Td>{indexOfFirstItem + index + 1}</Td>
                      <Td>
                        <span className="rupee-symbol">₹</span>{" "}
                        {baseAmount ? formatAmountIndianRupees(Number(baseAmount)) : Number(0).toFixed(2)}
                      </Td>
                      <Td>{companyName}</Td>
                      <Td>{gstNumber}</Td>
                      <Td>{gstType === 'SGST' ? 'SGST + CGST' : 'IGST'}</Td>

                      <Td>{gstPercentage || 0} %</Td>

                      <Td>
                        <span className="rupee-symbol">₹</span>{" "}
                        {igst ? formatAmountIndianRupees(Number(igst)) : Number(0).toFixed(2)}
                      </Td>
                      <Td>
                        <span className="rupee-symbol">₹</span>{" "}
                        {sgst ? formatAmountIndianRupees(Number(sgst)) : Number(0).toFixed(2)}
                      </Td>
                      <Td>
                        <span className="rupee-symbol">₹</span>{" "}
                        {cgst ? formatAmountIndianRupees(Number(cgst)) : Number(0).toFixed(2)}
                      </Td>
                      <Td>
                        <span className="rupee-symbol">₹</span>{" "}
                        {totalGst ? formatAmountIndianRupees(Number(totalGst)) : Number(0).toFixed(2)}
                      </Td>
                    </Tr>
                  );
                })}
              </Tbody>
              <Tfoot>
                <Tr>
                  <Th colSpan={1}>Total</Th>
                  <Td colSpan={1}>
                    {/* <span className="rupee-symbol">₹</span>{" "} */}
                    {formatAmountInINR(totalAmount?.baseAmount || 0)}
                  </Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td></Td>
                  <Td colSpan={1}>
                    {/* <span className="rupee-symbol">₹</span>{" "} */}
                    {formatAmountInINR(totalAmount?.igst || 0)}
                  </Td>
                  <Td colSpan={1}>
                    {/* <span className="rupee-symbol">₹</span>{" "} */}
                    {formatAmountInINR(totalAmount?.sgst || 0)}
                  </Td>
                  <Td colSpan={1}>
                    {/* <span className="rupee-symbol">₹</span>{" "} */}
                    {formatAmountInINR(totalAmount?.cgst || 0)}
                  </Td>
                  <Td colSpan={1}>
                    {/* <span className="rupee-symbol">₹</span>{" "} */}
                    {formatAmountInINR(totalAmount?.gst || 0)}
                  </Td>
                </Tr>
              </Tfoot>
            </Table>
          </Box>

          {/* Pagination Component */}
          <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
            <Pagination.Prev
              onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
              disabled={currentPage === 1}
            />
            {Array.from({ length: pageCount }, (_, i) => (
              <Pagination.Item
                key={i + 1}
                active={i + 1 === currentPage}
                onClick={() => setCurrentPage(i + 1)}
              >
                {i + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))}
              disabled={currentPage === pageCount}
            />
          </Pagination>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default GstInfo;
