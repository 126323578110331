import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddUser = createAsyncThunk(
  "/fetchAsyncAddUser",
  async (data) => {
    try {
      const response = await axiosInstance.post('/user/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUpdateUser = createAsyncThunk(
  "/fetchAsyncUpdateUser",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/user/update/${data.id}`, data.userData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDeleteUser = createAsyncThunk(
  "/fetchAsyncDeleteUser",
  async (id) => {
    try {
      let url = `${API_URL}/user/delete/${id}`;
      const response = await axiosInstance.delete(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetUser = createAsyncThunk(
  "/fetchAsyncGetUser",
  async () => {
    try {
      let url = `${API_URL}/user/get`;
      const response = await axiosInstance.get(url);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncGetExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/finencialYearData`
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);
export const fetchAsyncGetInternalExpenseBarChart = createAsyncThunk(
  "/fetchAsyncGetInternalExpenseBarChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/internalFinencialYearData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncGetExpensePiChart = createAsyncThunk(
  "/fetchAsyncGetExpensePiChart",
  async (data) => {
    try {
      let url = `${API_URL}/expense/piChartData`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      if (error.response?.status === 401) {
        throw new Error("Token Expire Please Login again!");
      } else {
        throw new Error(error.response.data.message);
      }
    }
  }
);

export const fetchAsyncDeleteRoleAccess = createAsyncThunk(
  "/fetchAsyncDeleteRoleAccess",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/user/delete-role-access/${data.id}`, data.moduleData);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncDeleteExpense = createAsyncThunk(
  "/fetchAsyncDeleteExpense",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/expense/delete-expense`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDownloadSharePointFile = createAsyncThunk(
  "/fetchAsyncDownloadSharePointFile",
  async (data) => {
    try {
      let url = `${API_URL}/expense/download-share-point-file`;
      const response = await axiosInstance.post(url,data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncPdfPreviewData = createAsyncThunk(
  "/fetchAsyncPdfPreviewData",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/expense/pdf-preview-data`, data,{
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
export const fetchAsyncFilePreviewData = createAsyncThunk(
  "/fetchAsyncFilePreviewData",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/expense/file-preview-data`, data,{
        responseType: 'blob',
      });
      return response;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncDownloadFilesAdZip = createAsyncThunk(
  "/fetchAsyncDownloadFilesAdZip",
  async (data) => {
    try {
      const response = await axiosInstance.post(`${API_URL}/expense/download-files-as-zip`, data,{
        responseType: 'blob',
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);
const AllUserSlice = createSlice({
  name: "userData",
  initialState,
  reducers: {},
  extraReducers: {
    [fetchAsyncGetUser.pending]: (state) => {},
    [fetchAsyncGetUser.fulfilled]: (state, { payload }) => {
      return payload;
    },
  },
});

export const getAllUser = (state) => state.userData;

export default AllUserSlice.reducer;
