import {
    Box,
    Button,
    Flex,
    Heading,
    Input,
    Select,
    Table,
    TableCaption,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    Tr,
    useColorModeValue,
  } from "@chakra-ui/react";
  import React, { useEffect, useMemo, useState } from "react";
  import {
    useGlobalFilter,
    usePagination,
    useSortBy,
    useTable,
  } from "react-table";
  
  // Custom components
  import Card from "components/card/Card";
  import Menu from "components/menu/MainMenu";
  import AddNew from "components/allForm/addNew";
  import InternalAddNew from "components/allForm/internalAddNew";
  import { Col, Row } from "react-bootstrap";
  import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
  import { IoSettingsOutline } from "react-icons/io5";
  import { FaCheckCircle, FaDollarSign, FaHourglass, FaMoneyBill, FaSearch, FaTimesCircle } from "react-icons/fa";
  import { FaMoneyBillTransfer } from "react-icons/fa6";
  
  const ExpenseTable = (props) => {
    
    const { columnsData, tableData, type,expenseType } = props;
    const columns = useMemo(() => columnsData, [columnsData]);
    const data = useMemo(() => tableData, [tableData]);
    const [pageIndex, setPageIndex] = useState(0); 
    const tableInstance = useTable(
      {
        columns,
        data,
        initialState: { pageIndex, pageSize: 15 },
      },
      useGlobalFilter,
      useSortBy,
      usePagination,
    );
    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      initialState,
  
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageSize },
    } = tableInstance;
    initialState.pageSize = 15;
    const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");
  
   
  
 
    useEffect(() => {
      if (data.length === 0) {
        setPageIndex(0); 
      } else if (pageIndex >= pageCount) {
        setPageIndex(pageCount - 1); 
      }
    }, [data, pageCount]); 
  
    return (
      <>
      
  
        <Card
          direction="column"
          w="100%"
          px="0px"
          p={0}
          h="auto"
          justifyContent="space-between"
          overflowX={{ sm: "scroll", lg: "hidden" }}
        >
          <Table {...getTableProps()} variant="simple" className="embrill-table vendor-expense-table" color="gray.500" >
            <Thead>
              
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <Th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                      borderColor={borderColor}
                    >
                      <Flex
                        align="center"
                        
                        
                      >
                        {column.render("Header")}
                        <span className="ms-1" style={{ width: "20px" }}>
                          {column.isSorted
                            ? column.isSortedDesc
                              ? "🔽"
                              : "🔼"
                            : ""}
                        </span>
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <Tr {...row.getRowProps()} key={index}>
                    {row.cells.map((cell, index) => {
                      let data = "";
  
                      data = (
                        <Flex align="center">
                          <Text
                            me="10px"
                            
                          >
                            {cell.value}
                          </Text>
                        </Flex>
                      );
                      return (
                        <Td
                          {...cell.getCellProps()}
                          cursor="pointer"
                          key={index}
                         
                          className="embrill-table-data"
                        >
                          {data}
                        </Td>
                      );
                    })}
                  </Tr>
                );
              })}
            </Tbody>
        
          </Table>
          
        </Card>
      </>
    );
  };
  
  export default ExpenseTable;
  