// SmallPopup.js
import React, { useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'; // Import the CSS for react-datepicker
import { Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverFooter, Button, Input, Flex, Tooltip, Menu, MenuButton,MenuList,MenuItem, Box } from '@chakra-ui/react';

import { FaFileExcel, FaFileArchive, FaEllipsisV } from 'react-icons/fa';
import * as FileSaver from 'file-saver';
import XLXS from 'sheetjs-style';
import moment from 'moment';
import { getInternalExcelData } from './excelData/internalExcelData';
import { useDispatch } from 'react-redux';
import { useToasts } from "react-toast-notifications";
import { getExternalExcelData } from './excelData/externalExcelData';

import { getResourceExcelData } from './excelData/resourceExcelData';
import { getTdsExcelData } from './excelData/tdsExcelData';
import { getDirectTaxExcelData } from './excelData/directTaxExcelData';
import { downloadAndCreateZip } from './handleDownloadInvoiceZip';
import { getGstExcelData } from './excelData/gstExcelData';
import { getStartDate } from "jsFunctions/helperFunctions";
import { getEndDate } from "jsFunctions/helperFunctions";
import { fetchAsyncGstXlsxData } from 'redux/slices/gstSlice';
import { fetchAsyncInternalXlsxData } from 'redux/internalSlice';
import { fetchAsyncExternalXlsxData } from 'redux/externalSlice';
import { fetchAsyncResourceXlsxData } from 'redux/resourceSlice';
import { fetchAsyncTdsXlsxData } from 'redux/tdsSlice';
import { fetchAsyncDirectTaxXlsxData } from 'redux/directTaxSlice';
import { endOfMonth, startOfMonth } from 'rsuite/esm/internals/utils/date';
import { FaDownLong } from 'react-icons/fa6';
import { BiExport } from 'react-icons/bi';
import SpinnerLoader from 'components/Spinner/SpinnerLoader';


const ExportDataModel = ({
  type , 
  role ,
  filename,
  isOpen,
  handleClose,
  activeBtn
}) => {
  const [dates, setDates] = useState([null, null]);

  // const [isOpen, setIsOpen] = useState(false);
  const [expenseData, setExpenseData] = useState([]);
  const [invoiceKeys, setInvoiceKeys] = useState([]);
  const [invoiceSpIds, setInvoiceSpIds] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [isMonth,setIsMonth] = useState(false)

  const { addToast } = useToasts();
  
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const formatFilename = (filename, dates) => {
    if (dates[0] && dates[1]) {
      return `${filename}${moment(dates[0]).format('DD-MMM-YYYY')}_${moment(dates[1]).format('DD-MMM-YYYY')}`;
    }
    return `${filename}${moment().format('DD-MMM-YYYY')}`;
  };

  

  const dispatch = useDispatch();


  const fetchData = useCallback(async () => {
    try {
      let fetchedData = [];
      let startDate = getStartDate(dates[0]);
      let endDate = getEndDate(dates[1]);      

      switch (type) {
        case 'internal':
          const internalRes = await dispatch(fetchAsyncInternalXlsxData({role, startDate, endDate }));
          fetchedData = internalRes?.payload?.internalExpanse || []
          break;
        case 'external':
          const externalRes = await dispatch(fetchAsyncExternalXlsxData({role, startDate, endDate }));
          fetchedData = externalRes?.payload?.externalExpense || []
          break;
        case 'resource':
          const resourceRes = await dispatch(fetchAsyncResourceXlsxData({role, startDate, endDate }));
          fetchedData = resourceRes?.payload?.resourceExpense || []
          break;
        case 'tds':
          const tdsRes = await dispatch(fetchAsyncTdsXlsxData({role, startDate, endDate }));
          fetchedData = tdsRes?.payload?.tdsExpense || []
          break;
        case 'directTax':
          const directTaxRes = await dispatch(fetchAsyncDirectTaxXlsxData({role, startDate, endDate }));
          fetchedData = directTaxRes?.payload?.directTaxExpense || []
          break;
        case 'gst':
          const gstRes = await dispatch(fetchAsyncGstXlsxData({role, startDate, endDate }));
          
          fetchedData = gstRes?.payload?.data || []
          break;
        default:
          return;
      }
      setExpenseData(fetchedData); // Store fetched data in state

    } catch (err) {
      addToast(`Error fetching data`, { appearance: 'error' });
    }
  }, [dates, type, dispatch, addToast]); // Include dependencies

  const getExcelDataByType = useCallback(async (data) => {
    switch (type) {
      case 'internal':
        return await getInternalExcelData(data);
      case 'external':
        return await getExternalExcelData(data);
      case 'resource':
        return await getResourceExcelData(data);
      case 'tds':
        return await getTdsExcelData(data);
      case 'directTax':
        return await getDirectTaxExcelData(data);
      case 'gst':
        return await getGstExcelData(data);
      default:
        return [];
    }
  }, [type]);


  const handleDownloadCSV = async() => {
    setIsLoading(true);
    try {
     if (expenseData.length === 0) {
      addToast(`No data available for the selected date range`, { appearance: 'error' });
      return;
    }
    const excelData = await getExcelDataByType(expenseData);

    const ws = XLXS.utils.json_to_sheet(excelData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLXS.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });

    FileSaver.saveAs(data, formatFilename(filename, dates) + fileExtension);
    handleClose();
  } catch (error) {
    addToast('An error occurred while downloading the CSV.', { appearance: 'error' });
  } finally {
    setIsLoading(false);
  }

  };

  const extractInvoiceKeys = (data) => {
    const keys = [];
    const ids = [];
    data.forEach(expense => {
      if (expense.paymentInfo && expense.paymentInfo?.list) {
        expense.paymentInfo.list.forEach(payment => {
          if (payment?.invoice) {
            payment.invoice.forEach(invoice => {
              if(invoice?.spId){
                ids.push(invoice?.spId)
              } else{
                if (invoice.Key) {
                  keys.push(invoice.Key);
                }
              }
            });
          }
        });
      }
    });
    return {keys,ids};
  };


  const handleDownloadAllInvoices = async () => {
    setIsLoading(true)
    try {
    if (invoiceKeys.length > 0 || invoiceSpIds?.length > 0) {
      const res = await downloadAndCreateZip(invoiceKeys, formatFilename(filename, dates), dispatch, invoiceSpIds);
        if (res.success) {
          addToast('Invoices downloaded successfully.', { appearance: 'success' });
        } else {
          addToast('Error downloading invoices.', { appearance: 'error' });
        }

      handleClose();

    } else {
      addToast('No invoices found for the selected date range.', { appearance: 'warning' });
    }
  } catch (error) {
    addToast('An error occurred while downloading invoices.', { appearance: 'error' });
  } finally {
    setIsLoading(false);
  }

  };

  useEffect(() => {
    if (dates[0] !== null && dates[1] !== null) {
      fetchData();
    }
    else{
      setExpenseData([])
    }
  }, [dates, fetchData]);

  
  useEffect(() => {
    if (expenseData && expenseData?.length) {
      const fetchedInvoice = extractInvoiceKeys(expenseData || []);
      const fetchedInvoiceKeys = fetchedInvoice?.keys;
      const fetchedInvoiceIds = fetchedInvoice?.ids;
      setInvoiceKeys(fetchedInvoiceKeys.filter(url => url)); 
      setInvoiceSpIds(fetchedInvoiceIds); 

    }
    else{
      setInvoiceKeys([])
      setInvoiceSpIds([])
    }
  }, [expenseData]);
  
  // const handleOpen = () => setIsOpen(true);
  // const handleClose = () => setIsOpen(false);

  return (
    <Popover
      isOpen={isOpen}
      onClose={handleClose}      
      placement="bottom-left"
      className="main-export-popover"      
    >
      {/* <PopoverTrigger>  
        <Box onClick={handleOpen}  className='menu-ellipsis-btn'><FaEllipsisV /></Box>
      </PopoverTrigger>
       */}
      <PopoverContent width="250px" height="150px" className='export-popover' >
      {isLoading ?
          <SpinnerLoader />
          :
        <>
        <PopoverBody>
          <DatePicker
            selected={dates[0]}
            onChange={(update) => {

              setDates(update)
              setIsMonth(true)
            }
}
            startDate={dates[0]}
            endDate={dates[1]}
            selectsRange={true}
            isClearable={true}            
            customInput={<Input placeholder="Select Date Range" />}
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            onMonthChange={(date) => {
              if(!isMonth){

                const firstDayOfMonth = startOfMonth(date);
                const lastDayOfMonth = endOfMonth(date);
                setDates([firstDayOfMonth, lastDayOfMonth]);
              }

            }}
      
            placeholderText="Select Date Range"
            className="export-select-date"

          />
        </PopoverBody>
        <PopoverFooter>
          <Flex
            flexDirection={'column'}
          >
          {activeBtn === 'xlsx' &&
          <Button
            colorScheme="blue"
            className='submit-btn'
            m={1}
            leftIcon={<FaFileExcel style={{ color: '#77df77' }} />}
            onClick={handleDownloadCSV}
            disabled={ !expenseData?.length }

          >
            Download XLSX
          </Button>
          }
         {(type !== 'gst' && activeBtn === 'invoice') &&
          <Button
            colorScheme="blue"
            className='submit-btn'
            disabled={!invoiceKeys?.length && !invoiceSpIds?.length}
            m={3}
            leftIcon={<FaFileArchive style={{ color: 'red' }}/>}
            onClick={handleDownloadAllInvoices}
          >
            Download Invoice ZIP
          </Button>
         }
          </Flex>
        </PopoverFooter>
        </>
      }
      </PopoverContent>
    </Popover>
  );
};

export default ExportDataModel;
