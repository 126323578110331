import React, { Component } from "react";
import Chart from "react-apexcharts";

class VendorBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      chartData: [],
      chartOptions: {
       
        yaxis: {
          labels: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} K`,
          }
        },
        tooltip: {
          y: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          },
          style: {
            colors: ['#000000'], // Color of the data labels
            fontSize: '16px', // Font size of the data labels
            fontWeight:'500',
            fontFamily: '"Inter", "Source Sans Pro", Helvetica, Arial, sans-serif',     
          },
        },
        dataLabels: {
          enabled: false,
          formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
          offsetY: -10,
          style: {
            colors: ['#000000'], // Color of the data labels
            fontSize: '11px', // Font size of the data labels
            fontWeight:'500',
            fontFamily: '"Inter", "Source Sans Pro", Helvetica, Arial, sans-serif',     
          },

        },
        // Add other default options as needed
      },
    };
  }

  componentDidMount() {
    this.updateChartData(this.props.chartData, this.props.chartOptions);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.chartData !== this.props.chartData || prevProps.chartOptions !== this.props.chartOptions) {
      this.updateChartData(this.props.chartData, this.props.chartOptions);
    }
  }

  updateChartData(chartData, chartOptions) {
    this.setState({
      chartData,
      chartOptions: {
        ...this.state.chartOptions,
        ...chartOptions,
        plotOptions: {
          bar: {
            columnWidth: '40%', // Adjust this value as needed for the width of the bars
            // distributed: true,   // Ensures bars have different colors
          },
        },
        colors: ['#22b378'],
        
        yaxis: {
          ...this.state.chartOptions.yaxis,
          labels: {
            formatter: (value) => `₹ ${value / 1000} K`,
            style: {
              colors: ['#000000'], // Color of the data labels
              fontSize: '14px', // Font size of the data labels
              fontFamily: '"Inter", "Source Sans Pro", Helvetica, Arial, sans-serif',     
              fontWeight:"500"
            },
          },

          
        },
        tooltip: {
          // ...this.state.chartOptions.tooltip,
          y: {
            formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`
          },
          custom: ({ seriesIndex, dataPointIndex, w }) => {
            // Extract the date or month from the xaxis categories
            const date = w.config.xaxis.categories[dataPointIndex];
            const currentYear = new Date().getFullYear();

            // Extract the value for the tooltip (y-axis value)
            const amount = w.config.series[seriesIndex].data[dataPointIndex];
            let value = date;
            if (date >= 1900 && date <= 9999) {
              value = `${date}-${Number(date) + 1}`;
            }
            else{

              value =  `${date}`; 
            }
            return `<div style="padding: 10px; text-align: center; width:200px ">
                      
                      <p style="font-size:20px"><strong>₹ ${amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</strong> </p>
                      <p>${value}</p><br />
                    </div>`;
          },
          style: {
            colors: ['#000000'], // Color of the data labels
            fontSize: '16px', // Font size of the data labels
            fontWeight:'600',
            fontFamily: '"Inter", "Source Sans Pro", Helvetica, Arial, sans-serif',     
          },

        },
        dataLabels: {
          ...this.state.chartOptions.dataLabels,
          formatter: (value) => `₹ ${value.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
        
          // offsetY: -20,
          chart: {
            ...this.state.chartOptions.chart,
            background: '#f0f0f0' // Ensure background color is applied
          }
        }
      },
    });
  }

  render() {
    return (
      <Chart
        options={this.state.chartOptions}
        series={this.state.chartData}
        type="bar"
        width="100%"
        height="100%"
      />
    );
  }
}

export default VendorBarChart;
