import { Box, Spinner } from "@chakra-ui/react";

const SpinnerLoader = ()=>{
    return (
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner 
            thickness="4px"
            speed="0.65s"
            emptyColor="green.400"
            size="xl" 
            color="blue.600" 
            borderWidth="3px"
            />
      </Box>
    )
}

export default SpinnerLoader;