import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axiosInstance from "redux/axiosInterceptor";

const API_URL = process.env.REACT_APP_BASE_URL;

const initialState = [];

export const fetchAsyncAddVendor = createAsyncThunk(
  "/fetchAsyncAddVendor",
  async (data) => {
    try {
      const response = await axiosInstance.post('/vendor/create', data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }
);

export const fetchAsyncUpdateVendor = createAsyncThunk(
    "/fetchAsyncUpdateVendor",
    async (data) => {
      try {
        const response = await axiosInstance.post(`/vendor/update/${data?.id}`, data?.data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

export const fetchAsyncGetVendor = createAsyncThunk(
    "/fetchAsyncGetVendor",
    async () => {
      try {
        let url = `${API_URL}/vendor/get`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncGetVendorByID = createAsyncThunk(
    "/fetchAsyncGetVendorByID",
    async (id) => {
      try {
        let url = `${API_URL}/vendor/get-vendor/${id}`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncGetVendorByCompanyName = createAsyncThunk(
    "/fetchAsyncGetVendorByCompanyName",
    async (company) => {
      try {
        let url = `${API_URL}/vendor/get_vendor/${company}`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncDeleteVendor = createAsyncThunk(
    "/fetchAsyncDeleteVendor",
    async (id) => {
      try {
        let url = `${API_URL}/vendor/delete/${id}`;
        const response = await axiosInstance.delete(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncDeleteVendorBank = createAsyncThunk(
    "/fetchAsyncDeleteVendorBank",
    async (id,index) => {
      try {
        let url = `${API_URL}/vendor/delete-bank/${id}/${index}`;
        const response = await axiosInstance.delete(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );


  export const fetchAsyncGetTotalPayAmountByPanNumber = createAsyncThunk(
    "/fetchAsyncGetTotalPayAmountByPanNumber",
    async (panNo) => {
      try {
        let url = `${API_URL}/vendor/get-pay-total-amount/${panNo}`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );
  export const fetchAsyncSearchVendorData = createAsyncThunk(
    "/fetchAsyncSearchVendorData",
    async (data) => {
      try {
        let url = `${API_URL}/vendor/search`;
        if (data.value) {
          url = url + `?search=${data.value}`;
        }
        if (data.startDate && data.endDate && data.value) {
          url = url + `&startDate=${data.startDate}&endDate=${data.endDate}`;
        }
        if (data.startDate && data.endDate && !data.value) {
          url = url + `?startDate=${data.startDate}&endDate=${data.endDate}`;
        }
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.msg);
      }
    }
  );
  
  export const fetchAsyncGetAllVendorWithPayAmount = createAsyncThunk(
    "/fetchAsyncGetAllVendorWithPayAmount",
    async () => {
      try {
        let url = `${API_URL}/vendor/get-all-vendors`;
        const response = await axiosInstance.get(url);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncGetVendorExpenseDetails = createAsyncThunk(
    "/fetchAsyncGetVendorExpenseDetails",
    async (data) => {
      try {
        let url = `${API_URL}/vendor/get-vendor-expense-details`;
        const response = await axiosInstance.post(url,data);
        return response.data;
      } catch (error) {
        throw new Error(error.response.data.message);
      }
    }
  );

  export const fetchAsyncGetVendorFinancialExpense = createAsyncThunk(
    "/fetchAsyncGetVendorFinancialExpense",
    async (data) => {
      try {
        let url = `${API_URL}/vendor/get-vendor-financial-expense`;
        const response = await axiosInstance.post(url,data);
        return response.data;
      } catch (error) {
        if (error.response?.status === 401) {
          throw new Error("Token Expire Please Login again!");
        } else {
          throw new Error(error.response.data.message);
        }
      }
    }
  );

  const AllVendorSlice = createSlice({
    name: "vendorData",
    initialState,
    reducers: {},
    extraReducers: {
      [fetchAsyncGetVendor.pending]: (state) => {},
      [fetchAsyncGetVendor.fulfilled]: (state, { payload }) => {
        return payload;
      },
      [fetchAsyncSearchVendorData.pending]: (state) => {},
      [fetchAsyncSearchVendorData.fulfilled]: (state, { payload }) => {
        return payload.vendors;
      },
    },
  });
  

  export const getAllVendor = (state) => state.vendorData;
  
  export default AllVendorSlice.reducer;




