import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import { useCallback } from "react";
import {  useSelector } from "react-redux";

const GstAmountTable = ({ editGstInfo, currentGstInfo, type }) => {
  const vendorList = useSelector((state) => state.vendorData);

  const fetchCompanyName = useCallback((companyId) => {
    const vendor = vendorList.find((vendor) => vendor._id === companyId);
    
    if (vendor) {
      return vendor.companyName; 
    } else {
      return ""; 
    }
  }, [vendorList]);

  

    return (

        type === 'current' ?
        <div className="info-table change-modal-table">

        <Table className="table-info">
          <Thead>
            <Tr>
              <Th>Base Amount</Th>
              <Th>Company Name</Th>
              <Th>GST Number</Th>
              <Th>GST Type</Th>

              <Th>GST Percentage</Th>

            </Tr>
          </Thead>
          <Tbody>
            {editGstInfo?.map((item, index) => {
              return (
                <Tr>
                  <Td className={`${item?.baseAmount !== currentGstInfo?.[index]?.baseAmount ? 'data_compare_red' : ''}`}><Box>{formatAmountInINR( parseFloat(item?.baseAmount || 0))}</Box></Td>
                  <Td className={`${item?.companyId !== currentGstInfo?.[index]?.companyId ? 'data_compare_red' : ''}`}>{fetchCompanyName(item?.companyId)}</Td>
                  <Td className={`${item?.gstNumber !== currentGstInfo?.[index]?.gstNumber ? 'data_compare_red' : ''}`}>{item?.gstNumber}</Td>

                  <Td className={`${item?.gstType !== currentGstInfo?.[index]?.gstType ? 'data_compare_red' : ''}`}>{item?.gstType}</Td>
                  <Td className={`${item?.gstPercentage !== currentGstInfo?.[index]?.gstPercentage ? 'data_compare_red' : ''}`}>{item?.gstPercentage ? `${item?.gstPercentage} %` : ''}</Td>

                </Tr> 

              );
            })}
          </Tbody>
        </Table>
      </div>
            :
            <div className="info-table change-modal-table">

                    <Table className="table-info">
                      <Thead>
                        <Tr>
                          <Th>Base Amount</Th>
                          <Th>Company Name</Th>
                          <Th>GST Number</Th>
                          <Th>GST Type</Th>

                          <Th>GST Percentage</Th>

                        </Tr>
                      </Thead>
                      <Tbody>
                        {currentGstInfo?.map((item, index) => {
                          return (
                            <Tr>
                              <Td className={`${item?.baseAmount !== editGstInfo?.[index]?.baseAmount ? 'data_compare_green' : ''}`}><Box   >{formatAmountInINR(parseFloat(item?.baseAmount || 0))}</Box></Td>
                              <Td className={`${item?.companyId !== editGstInfo?.[index]?.companyId ? 'data_compare_green' : ''}`}>{fetchCompanyName(item?.companyId)}</Td>
                              <Td className={`${item?.gstNumber !== editGstInfo?.[index]?.gstNumber ? 'data_compare_green' : ''}`}>{item?.gstNumber}</Td>
                              <Td className={`${item?.gstType !== editGstInfo?.[index]?.gstType ? 'data_compare_green' : ''}`}>{item?.gstType}</Td>
                              <Td className={`${item?.gstPercentage !== editGstInfo?.[index]?.gstPercentage ? 'data_compare_green' : ''}`}>{item?.gstPercentage ? `${item?.gstPercentage} %` : ''}</Td>

                            </Tr> 

                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>

    )


}

export default GstAmountTable;
