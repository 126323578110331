import moment from "moment";
import { poNumberFormate } from "jsFunctions/helperFunctions";
import { formatAmountIndianRupees } from "jsFunctions/formatAmountInINR";
import { poMonthFormate } from "jsFunctions/helperFunctions";

export const getInternalExcelData = (internalList) => {
  console.log(internalList,'internal')
    let list = JSON.parse(JSON.stringify(internalList));
    const exportData = list.map((data, index) => {

      // const gstArray = data?.gstApplicable?.gstId || [];

      let content = {
        no: index + 1,
        "Order PO Number": data.poNo && poNumberFormate(data.poNo),
        "Current Order Month": data.orderMonth && poMonthFormate(data.orderMonth),
        "Current Order Date":data.orderDate && moment(data.orderDate).format("DD-MMM-YYYY"),
        "Office Type": data?.officeId?.value,
        "Major Type": data?.majorId?.value,
        "Minor Type": data?.minorId?.value,
        "Minor Sub Category": data?.minorSubCategoryId?.value,

        "Client Name": data?.clientName?.clientName,
        "Merchant Name": data?.merchantId?.companyName,
        "Remarks":data.remarks,

        "Freight Expense": data.expense?.isExpense ? "true" : "false",
        "Freight Expense Delivery Partner": data.expense?.agency ? data.expense?.agencyId?.value : '',
        "Freight Expense Tracking Number": data.expense?.trackingNum,

        "Requested Amount":`₹ ${data.paymentInfo?.approvalAmount ? formatAmountIndianRupees(data.paymentInfo?.approvalAmount) : Number(0).toFixed(2)}`,
        "Approved Amount":`₹ ${data.paymentInfo?.totalPayAmount ? formatAmountIndianRupees(data.paymentInfo?.totalPayAmount) : Number(0).toFixed(2)}`,

        "Is It Refund Amount?": data?.refundInfo?.isRefund ? "true" : "false",
        "Refund Payee": data?.refundInfo?.payee || "",
        "Refund Amount": `₹ ${data?.refundInfo?.amount ? formatAmountIndianRupees(data?.refundInfo.amount ) : Number(0).toFixed(2)}`,
        "Refund Bank": data?.refundInfo?.bank || "",
        "Refund Reference Number": data?.refundInfo?.referenceNumber || "",
        "Refund Date": data?.refundInfo?.refundDate && moment(data?.refundInfo?.refundDate).format("DD-MMM-YYYY"),
        
        "Payment Reimbursed?": data?.reimbursementInfo?.isReimbursement?.isReimburs ? "true" : "false",
        "Reimbursement Invoice Number": data?.reimbursementInfo?.invoiceNum,
        "Reimbursement Invoice Date": data?.reimbursementInfo?.invoiceDate && moment(data?.reimbursementInfo?.invoiceDate).format("DD-MMM-YYYY"),
        "Reimbursement Amount": `₹ ${data?.reimbursementInfo?.isReimbursement?.amount ? formatAmountIndianRupees(data?.reimbursementInfo?.isReimbursement?.amount ) : Number(0).toFixed(2)}`,
        "Reimbursement Currency": data?.reimbursementInfo?.isReimbursement?.currencyId?.value,
        "Reimbursement Conversion Rate": data?.reimbursementInfo?.isReimbursement?.conversionRate,
        "Reimbursement Amount in INR":`₹ ${data?.reimbursementInfo?.isReimbursement?.amountInINR ? formatAmountIndianRupees(data?.reimbursementInfo?.isReimbursement?.amountInINR) : Number(0).toFixed(2)}`,

        "TDS Applicable?": data?.tdsApplicable?.isTDS ? "true" : "false",
        "TDS Generated PO Number":
          data?.tdsApplicable?.generateTdsPO?.isTDSPO &&
          poNumberFormate(data?.tdsApplicable?.generateTdsPO?.TDSPONum),
      };
     
    if (data?.paymentInfo?.list?.length > 0) {
      data.paymentInfo.list.forEach((element, index) => {
        content[`Payment ${index + 1} Spent Amount`] = `₹ ${element?.amount ? formatAmountIndianRupees(element?.amount) : Number(0).toFixed(2)}`;
        content[`Payment ${index + 1} Bank`] = element?.bankId?.value;
        content[`Payment ${index + 1} Payee`] = element?.payeeId?.value;
        content[`Payment ${index + 1} Date`] = element?.paymentDate &&
          moment(element?.paymentDate).format("DD-MMM-YYYY");

      });
    }
      
      content["Space Added"] = "--------------";

      content["Created AT"] = data.createdAt ? moment(data.createdAt).format("DD-MMM-YYYY") : '';
      return content;
    });
    return exportData;
  };