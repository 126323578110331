import { saveAs } from 'file-saver';
import { fetchAsyncDownloadFilesAdZip } from 'redux/userSlice';

/**
 * Fetches a ZIP file from the backend API and triggers a download.
 *
 * @param {string[]} keys - An array of S3 object keys to be included in the ZIP file.
 */
const apiURL = process.env.REACT_APP_BASE_URL;



export const downloadAndCreateZip = async (keys, filename, dispatch,ids) => {
    
    if ((!keys || keys.length === 0 ) && (!ids || !ids?.length)) {
      console.error('No Invoices provided for download.');
      return;
    }

    try {
      const blob = await dispatch(fetchAsyncDownloadFilesAdZip({ keys:keys.join(',') ,spIds:ids.join(',')})).unwrap();
      if (!blob) {
        return { success: false, message: 'No content returned in the response.' };
      }
  
      saveAs(blob, `${filename}.zip`); 
      return { success: true, message: 'File downloaded successfully.' };

    } catch (error) {
      return { success: false, message: 'Error fetching or downloading ZIP file.' };
    }
  };

  