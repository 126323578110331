/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Box, Flex, Icon,Text, SimpleGrid, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import React, { useEffect, useState } from "react";
import { SiVirustotal } from "react-icons/si";
import { useDispatch } from "react-redux";
import { fetchAsyncGetDirectTaxTotalPaymentAmount } from "redux/directTaxSlice";
import { fetchAsyncGetExternalTotalPaymentAmount } from "redux/externalSlice";
import { fetchAsyncGetInternalTotalPaymentAmount } from "redux/internalSlice";
import { fetchAsyncGetResourceTotalPaymentAmount } from "redux/resourceSlice";
import { fetchAsyncGetTDSTotalPaymentAmount } from "redux/tdsSlice";
import { fetchAsyncGetInternalExpenseBarChart } from "redux/userSlice";

// icons

import { useToasts } from "react-toast-notifications";
import WeeklyExpense from "./components/WeeklyExpense";
import LineCard from "./components/LineCard";
import { fetchAsyncGetExternalExpenseBarChart } from "redux/externalSlice";
import { fetchAsyncGetResourceExpenseBarChart } from "redux/resourceSlice";
import { fetchAsyncGetTDSExpenseBarChart } from "redux/tdsSlice";
import { fetchAsyncGetDirectTaxExpenseBarChart } from "redux/directTaxSlice";

export default function ExpenseReports({name, type, icon,heading}) {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const textColor = useColorModeValue("secondaryGray.900", "white");

  const dispatch = useDispatch();
  const [expensTotalPayAmount, setExpenseTotalPayAmount] =
    useState(null);
  
  
  const [optionBarChart, setOptionsBarChart] = useState([]);
  const [valuesBarChart, setValuesBarChart] = useState([]);

  const [selectExpYear, setSelectExpYear] = useState("2024-2025");
  const [selectExpType, setSelectExpType] = useState(type);

  const [selectFilterData, setSelectFilterData] = useState({
    viewType:"monthly",
    officeId:"",
    majorId:"",
    minorId:"",
    minorSubCategoryId:"",
    merchantId:"",
    clientId:"",
    resourceId:"",
    referenceResourcePO:"",
    receiverId:"",
    projectId:""
  })

  const handleFilterData = (obj)=>{
    const name = obj?.name;;
    const value = obj?.value;
    const id = obj?.id;

    if(name === "clientId" && id === ""){
      setSelectFilterData({
        ...selectFilterData,
        projectId:""
      })
    }
    if (name === 'majorId' && selectFilterData[name] !== id) {
      setSelectFilterData({
        ...selectFilterData,
        majorId:id,
        minorId:"",
        minorSubCategoryId:""
      });
    } else if (name === 'minorId' && selectFilterData[name] !== id) {
      setSelectFilterData({
        ...selectFilterData,
        minorId:id,
        minorSubCategoryId:""
      });
    } else {
      setSelectFilterData({
        ...selectFilterData,
        [name]: id
      });
    }
   
  }

  const handleFilterType = (e)=>{
    const name = e?.target?.name;
    const value = e?.target?.value;
    setSelectFilterData({
      ...selectFilterData,
      [name]: value
    });
  }

  const { addToast } = useToasts();

  useEffect(() => {
    switch (type) {
      case "internal":
        dispatch(fetchAsyncGetInternalTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "external":
        dispatch(fetchAsyncGetExternalTotalPaymentAmount())
          .then((res) => {
            if (res.payload) {
              setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
            }
          })
          .catch((err) => {
            console.log(err);
          });
        break;
      case "resource":
        dispatch(fetchAsyncGetResourceTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "tds":
        dispatch(fetchAsyncGetTDSTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
      case "directTax":
        dispatch(fetchAsyncGetDirectTaxTotalPaymentAmount()).then((res) => {
          if (res.payload) {
            setExpenseTotalPayAmount(res.payload.totalPaymentAmount);
          }
        });
        break;
        default:
          break;

    }

  }, []);


  const fetchFinancialYearExpense = () => {
    const startYear = selectExpYear.split("-")[0];
    const endYear = selectExpYear.split("-")[1];
  
    const fetchExpenseData = (action) => {
      dispatch(action).then((res) => {
        if (res.payload) {
          // Handle the response based on its structure (monthly or yearly)
          let options = [];
          let values = [];
  
          if (Array.isArray(res.payload)) {
            // Monthly format (array of objects)
            options = res.payload.map((data) => Object.keys(data)[0]);
            values = res.payload.map((data) => Object.values(data)[0]);
          } else if (typeof res.payload === 'object') {
            // Yearly format (single object with year ranges as keys)
            options = Object.keys(res.payload);
            values = Object.values(res.payload);
          } else {
            addToast(res.error?.message, { appearance: "error" });
            return;
          }
  
          // Update the chart data
          setOptionsBarChart(options);
          setValuesBarChart(values);
  
        } else {
          addToast(res.error?.message, { appearance: "error" });
        }
      });
    };
  
    switch (type) {
      case "internal":
        fetchExpenseData(
          fetchAsyncGetInternalExpenseBarChart({
            expanse: selectExpType,
            filterOption: selectFilterData,
            startDate: startYear,
            endDate: endYear,
          })
        );
        break;
  
      case "external":
        fetchExpenseData(
          fetchAsyncGetExternalExpenseBarChart({
            expanse: selectExpType,
            filterOption: selectFilterData,
            startDate: startYear,
            endDate: endYear,
          })
        );
        break;
  
      case "resource":
        fetchExpenseData(
          fetchAsyncGetResourceExpenseBarChart({
            expanse: selectExpType,
            filterOption: selectFilterData,
            startDate: startYear,
            endDate: endYear,
          })
        );
        break;
  
      case "tds":
        fetchExpenseData(
          fetchAsyncGetTDSExpenseBarChart({
            expanse: selectExpType,
            filterOption: selectFilterData,
            startDate: startYear,
            endDate: endYear,
          })
        );
        break;
  
      case "directTax":
        fetchExpenseData(
          fetchAsyncGetDirectTaxExpenseBarChart({
            expanse: selectExpType,
            filterOption: selectFilterData,
            startDate: startYear,
            endDate: endYear,
          })
        );
        break;
  
      default:
        break;
    }
  };
  
  // Function for Bar chart Expanse
  useEffect(() => {
    fetchFinancialYearExpense();
   
  }, [selectExpYear, selectExpType, selectFilterData]);

 
  useEffect(()=>{

  })
 
  

  return (
    <>
    
    <Box className="mx-2" >
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2, "2xl": 3 }}
        gap="20px"
        mb="20px"
        className="grid-amount mt-5"

      
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={icon} color={brandColor} />
              }
            />
          }
          name={name}
          value={expensTotalPayAmount ? formatAmountInINR(expensTotalPayAmount) : formatAmountInINR(0)}
        />
      </SimpleGrid>
    
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <TotalSpent /> */}
        {optionBarChart.length > 0 && valuesBarChart.length > 0 && (
          <>
          <WeeklyExpense
            optionBarChart={optionBarChart}
            valuesBarChart={valuesBarChart}
            type={selectExpType}
            changeYear={(value) => {
              setSelectExpYear(value);
            }}
            handleFilter = {(e)=>{
              handleFilterData(e)
            }}
            handleFilterViewType={(e)=>handleFilterType(e)}
            filterData = {selectFilterData}
            heading = {heading}
            selectExpYear={selectExpYear}
          />
          
          </>
        )}
       
      </SimpleGrid>
    </Box>
    </>
  );
}
