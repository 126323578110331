/* eslint-disable react-hooks/exhaustive-deps */
// Chakra imports
import { Box, Icon, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Assets
// Custom components
import MiniStatistics from "components/card/MiniStatistics";
import IconBox from "components/icons/IconBox";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import React, { useEffect, useState } from "react";
import { SiVirustotal } from "react-icons/si";
import { useDispatch } from "react-redux";
import { fetchAsyncGetDirectTaxTotalPaymentAmount } from "redux/directTaxSlice";
import { fetchAsyncGetExternalTotalPaymentAmount } from "redux/externalSlice";
import { fetchAsyncGetInternalTotalPaymentAmount } from "redux/internalSlice";
import { fetchAsyncGetResourceTotalPaymentAmount } from "redux/resourceSlice";
import { fetchAsyncGetTDSTotalPaymentAmount } from "redux/tdsSlice";
import PieCard from "views/admin/default/components/PieCard";
import WeeklyRevenue from "views/admin/default/components/WeeklyRevenue";
import { fetchAsyncGetExpenseBarChart } from "redux/userSlice";
import { fetchAsyncGetExpensePiChart } from "redux/userSlice";

// icons
import { BsDoorOpen, BsFillHouseHeartFill } from "react-icons/bs";
import { TbReceiptTax } from "react-icons/tb";
import { HiReceiptTax } from "react-icons/hi";
import { GrResources } from "react-icons/gr";
import { useToasts } from "react-toast-notifications";

export default function UserReports() {
  // Chakra Color Mode
  const brandColor = useColorModeValue("brand.500", "white");
  const boxBg = useColorModeValue("secondaryGray.300", "whiteAlpha.100");
  const [totalExpenseAmount, setTotalExpenseAmount] = useState(null);
  const dispatch = useDispatch();
  const [externalExpenseTotalPayAmount, setExternalExpenseTotalPayAmount] =
    useState(null);
  const [internalExpenseTotalPayAmount, setInternalExpenseTotalPayAmount] =
    useState(null);
  const [resourceExpenseTotalPayAmount, setResourceExpenseTotalPayAmount] =
    useState(null);
  const [tdsExpenseTotalPayAmount, setTdsExpenseTotalPayAmount] =
    useState(null);
  const [directTaxExpenseTotalPayAmount, setDirectTaxExpenseTotalPayAmount] =
    useState(null);
  const [totalPaymentAmountExpenseData, setTotalPaymentAmountExpenseData] =
    useState(null);

  const [optionBarChart, setOptionsBarChart] = useState([]);
  const [valuesBarChart, setValuesBarChart] = useState([]);

  const [selectExpYear, setSelectExpYear] = useState("2024-2025");
  const [selectExpType, setSelectExpType] = useState("external");

  const [piOptionType, setPiOptionType] = useState("yearly");
  const [piOptionYear, setPiOptionYear] = useState("2024-2025");
  const [piOptionMonth, setPiOptionMonth] = useState("1");

  const { addToast } = useToasts();

  useEffect(() => {
    dispatch(fetchAsyncGetExternalTotalPaymentAmount())
      .then((res) => {
        if (res.payload) {
          setExternalExpenseTotalPayAmount(res.payload.totalPaymentAmount);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    dispatch(fetchAsyncGetInternalTotalPaymentAmount()).then((res) => {
      if (res.payload) {
        setInternalExpenseTotalPayAmount(res.payload.totalPaymentAmount);
      }
    });
    dispatch(fetchAsyncGetResourceTotalPaymentAmount()).then((res) => {
      if (res.payload) {
        setResourceExpenseTotalPayAmount(res.payload.totalPaymentAmount);
      }
    });
    dispatch(fetchAsyncGetTDSTotalPaymentAmount()).then((res) => {
      if (res.payload) {
        setTdsExpenseTotalPayAmount(res.payload.totalPaymentAmount);
      }
    });
    dispatch(fetchAsyncGetDirectTaxTotalPaymentAmount()).then((res) => {
      if (res.payload) {
        setDirectTaxExpenseTotalPayAmount(res.payload.totalPaymentAmount);
      }
    });
  }, []);

  useEffect(() => {
    let startYear = selectExpYear.split("-")[0];
    let endYear = selectExpYear.split("-")[1];

    dispatch(
      fetchAsyncGetExpenseBarChart({
        expense: selectExpType,
        startYear,
        endYear,
        financialYear:selectExpYear
      })
    ).then((res) => {
      if (res.payload) {
        if (res.payload?.length > 0) {
          let options = res.payload.map((data) => Object.keys(data)[0]);
          let values = res.payload.map((data) => Object.values(data)[0]);
          setOptionsBarChart(options);
          setValuesBarChart(values);
        }
      } else {
        addToast(res.error?.message, { appearance: "error" });
      }
    });
  }, [selectExpYear, selectExpType]);

  useEffect(() => {
    if (piOptionType && piOptionYear) {
      
      dispatch(fetchAsyncGetExpensePiChart({ piOptionYear ,piOptionType,piOptionMonth})).then(
        (response) => {
          if (response.payload) {
            setExternalExpenseTotalPayAmount(
              response.payload.externalRes?.count
            );
            setInternalExpenseTotalPayAmount(
              response.payload.internalRes?.count
            );
            setResourceExpenseTotalPayAmount(
              response.payload.resourceRes?.count
            );
            setTdsExpenseTotalPayAmount(response.payload.tdsRes?.count);
            setDirectTaxExpenseTotalPayAmount(
              response.payload.directTaxRes?.count
            );
          }
        }
      );
    }
  }, [piOptionType, piOptionYear, piOptionMonth]);

  useEffect(() => {
    setTotalPaymentAmountExpenseData({
      externalTotalPayAmount: externalExpenseTotalPayAmount || 0,
      ineternalTotalPayAmount: internalExpenseTotalPayAmount || 0,
      resourceTotalPayAmount: resourceExpenseTotalPayAmount || 0,
      tdsTotalPayAmount: tdsExpenseTotalPayAmount || 0,
      directTaxTotalPayAmount: directTaxExpenseTotalPayAmount || 0,
    });
    const total =
      externalExpenseTotalPayAmount +
      internalExpenseTotalPayAmount +
      resourceExpenseTotalPayAmount +
      tdsExpenseTotalPayAmount +
      directTaxExpenseTotalPayAmount;
    setTotalExpenseAmount(total);
  }, [
    externalExpenseTotalPayAmount,
    internalExpenseTotalPayAmount,
    resourceExpenseTotalPayAmount,
    tdsExpenseTotalPayAmount,
    directTaxExpenseTotalPayAmount,
  ]);
  const textColor = useColorModeValue("secondaryGray.900", "white");

  return (
    <Box className="mx-2">
    <Box display="flex" className="mx-3">
        <Text
          color={textColor}
          fontSize="22px"
          fontWeight="700"
          lineHeight="100%"
          mt={5}
          
        >
          Main Dashboard
        </Text>
       
        </Box>
    <Box pt={{ base: "100px", md: "5px", xl: "5px" }}>
       
      <SimpleGrid
        columns={{ base: 1, md: 1, lg: 2, "2xl": 3 }}
        gap="20px"
        mb="20px"
        mt="40px"
        className="grid-amount"
        
      >
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={SiVirustotal} color={brandColor} />
              }
            />
          }
          name="Total Expense Amount"
          value={formatAmountInINR(totalExpenseAmount)}
        />
      </SimpleGrid>
      <SimpleGrid
        columns={{ base: 1, md: 2, lg: 3, "2xl": 5 }}
        gap="20px"
        mb="20px"
        className="grid-amount"
      >
        <MiniStatistics
          
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={BsDoorOpen} color={brandColor} />
              }
            />
          }
          name="Total External Expense"
          value={formatAmountInINR(externalExpenseTotalPayAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon
                  w="22px"
                  h="22px"
                  as={BsFillHouseHeartFill}
                  color={brandColor}
                />
              }
            />
          }
          name="Internal Total Expense"
          value={formatAmountInINR(internalExpenseTotalPayAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={GrResources} color={brandColor} />
              }
            />
          }
          name="Resource Total Expense"
          value={formatAmountInINR(resourceExpenseTotalPayAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={TbReceiptTax} color={brandColor} />
              }
            />
          }
          name="TDS Total Expense"
          value={formatAmountInINR(tdsExpenseTotalPayAmount)}
        />
        <MiniStatistics
          startContent={
            <IconBox
              w="46px"
              h="46px"
              bg={boxBg}
              icon={
                <Icon w="22px" h="22px" as={HiReceiptTax} color={brandColor} />
              }
            />
          }
          name="DirectTax Total Expense"
          value={formatAmountInINR(directTaxExpenseTotalPayAmount)}
        />

        {/* <MiniStatistics growth="+23%" name="Sales" value="$574.34" /> */}
      </SimpleGrid>
      <SimpleGrid columns={{ base: 1, md: 2, xl: 2 }} gap="20px" mb="20px">
        {/* <TotalSpent /> */}
        {optionBarChart.length > 0 && valuesBarChart.length > 0 && (
          <WeeklyRevenue
            optionBarChart={optionBarChart}
            valuesBarChart={valuesBarChart}
            changeYear={(value) => {
              setSelectExpYear(value);
            }}
            changeExpanse={(value) => {
              setSelectExpType(value);
            }}
            selectExpYear={selectExpYear}
          />
        )}
        {totalPaymentAmountExpenseData !== null && (
          <PieCard
            data={totalPaymentAmountExpenseData}
            changePiType={(value) => {
              setPiOptionType(value);
            }}
            changePiYear={(value) => {
              setPiOptionYear(value); 
            }}
            changePiMonth={(value) => {
              setPiOptionMonth(value);
            }}
          />
        )}
      </SimpleGrid>
    </Box>
    </Box>
  );
}
