import React, { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Select,
  FormControl,
  FormLabel,
  ModalCloseButton,
  Box,
} from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { useToasts } from 'react-toast-notifications';
import { fetchAsyncAddLegacy } from 'redux/legacySlice';



const LegacyNew = ({ isOpen, onClose,legacyItems }) => {
  const [selectedItem, setSelectedItem] = useState('');
  const dispatch = useDispatch();
  const { addToast } = useToasts();
  const legacyList = useSelector((state) => state.legacyData);
  const [legacyItemData,setLegacyItemData] = useState([])

  const handleAddLegacy = ()=>{
    const data = {
        name:selectedItem
    }
    dispatch(fetchAsyncAddLegacy(data))
      .then((res) => {
        if (res?.payload) {
          const legacyDetails = res?.payload?.legacy;
         
          addToast("legacy created successfully!", { appearance: "success" });
          onClose();
          setSelectedItem('')
         }
         else{
          addToast(res?.error?.message, { appearance: "error" });
         }
  
      })
      .catch((error) => {
        addToast("Not Added", { appearance: "error" });
      });
  }

const namesToFilterOut = new Set(
  legacyList.map(item => item.name)       
);

  useEffect(()=>{
    const filteredLegacyItems = legacyItems.filter(item => !namesToFilterOut.has(item.name));
    setLegacyItemData(filteredLegacyItems)
  },[legacyList,legacyItems])

  
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent
      top="0" // Position it at the top
      margin="0 auto" // Center it horizontally
      className="modal-content-legacy">
         <div className="fixed-header">
            <ModalHeader className="expense-modal-header">
              Add New Legacy Item
            </ModalHeader>
            <ModalCloseButton />
          </div>
        <ModalBody className='mt-4'>
        <div className="form-card mt-5">
          <FormControl>
            <FormLabel>Select Legacy Item</FormLabel>
            <Select
              placeholder="Select item"
              value={selectedItem}
              onChange={(e) => setSelectedItem(e.target.value)}
            >
              {legacyItemData.map((item) => (
                <option key={item.name} value={item.name}>
                  {item.name}
                </option>
              ))}
            </Select>
          </FormControl>
          </div>
          <Box display="flex" className='mt-5' justifyContent="center">
          <Button background="#000D55 !important" color="white" onClick={handleAddLegacy}>
            Add
          </Button>
          <Button background="#d33 !important" className='mx-3'  color="white" variant="ghost" onClick={onClose}>
            Cancel
          </Button>
        </Box>
        </ModalBody>
       
      </ModalContent>
    </Modal>
  );
};

export default LegacyNew;