import React, { useEffect, useState } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  Button,
  ModalHeader,
  ModalOverlay,
 
  Box,
} from "@chakra-ui/react";
import { Col, Row } from "react-bootstrap";
import dateTimeFormat from "jsFunctions/dateTimeFormat";

import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import SpentAmountTable from "./dataChangeTable/spentAmount";
import RefundAmountTable from "./dataChangeTable/refundAmountTable";

const DirectTaxDataChangeModal = ({
  openModal,
  closeModal,
  editData,
  currentData,
  isConfirmed,
  type,
  role
}) => {
    let getcurrectData = {};
    for (const pair of currentData?.entries()) {
      if (pair[0] === "payInfo") {
        getcurrectData[pair[0]] = JSON.parse(pair[1]);
      } else {
        getcurrectData[pair[0]] = pair[1];
      }
    }
  

    const [currentSpentInfo, setCurrentSpentInfo] = useState([])
    const [editSpentInfo, setEditSpentInfo] = useState([])

    const [currentRefundInfo, setCurrentRefundInfo] = useState([])
    const [editRefundInfo, setEditRefundInfo] = useState([])

 


  useEffect(()=>{
      
    if(getcurrectData?.payInfo){
      const spentData =  getcurrectData?.payInfo || [];
      const filterOutData = spentData?.map((item) => {
          return item;
      });
      setCurrentSpentInfo(filterOutData);
    }
    if(editData?.paymentInfo?.list){
      const currSpentData = getcurrectData?.payInfo || [];
      const spentData = editData?.paymentInfo?.list || [];
    
      // Calculate the difference and add dummy objects
      const dummObjectCount = currSpentData.length - spentData.length;
      let filterOutData = spentData;
      if(dummObjectCount >= 0){

        filterOutData = [...spentData, ...Array(dummObjectCount).fill({})];
      }
      setEditSpentInfo(filterOutData);
    }

    if(getcurrectData?.refundInfo){
      const refundData =  getcurrectData?.refundInfo ? JSON.parse(getcurrectData?.refundInfo) : []
      const filterOutData = refundData?.map((item) => {
         return item       
     });
     setCurrentRefundInfo(filterOutData)
    }
    if (editData?.refundInfo?.list) {
      const refundData = editData?.refundInfo?.list || [];
      const currrefundData = getcurrectData?.refundInfo ? JSON.parse(getcurrectData?.refundInfo) : [];
    
      const dummObjectCount = currrefundData.length - refundData.length;
    
      const filterOutData = [
        ...refundData,  
        ...Array(dummObjectCount).fill({})  
      ];
    
      setEditRefundInfo(filterOutData);  
    }


  },[])   


  return (
    <>
      <Modal
        isOpen={openModal}
        onClose={closeModal}
        size="xl"
        className="modal-main data-change-model"
        scrollBehavior={"inside"}
        
        
      >
        <ModalOverlay />
        <ModalContent 
         top="0" // Position it at the top
          margin="0 auto" // Center it horizontally
          bottom="0"
        className="data-change-content info-model"
>
          <ModalHeader className="expense-modal-header data-change-modal-header">
            <>
              {/* Compare fields */}
              <Row className="modal-header-row">
                <Col>
                  <h6>
                    Current
                  </h6>
                </Col>
                
                <Col>
                  <h6 >
                    Edited
                  </h6>
                </Col>
              </Row>
            </>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody className="data-change-body">
            <Row className="data-change-body-row">
              <Col className="row-col">
                <div className="row-table">
                  <Row className="rowC">
                        <h4 className="row-heading">
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(editData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>Order Date : {dateTimeFormat(editData.orderDate)}</Col>
                  </Row>
                 
                   
                  <Row className="rowC">
                        <Col className={`${editData?.receiverName !== getcurrectData?.receiverName  ? 'data_compare_red' : '' }`}>Receiver Name : {editData?.receiverName}</Col>
                      </Row>
                     
                      <Row className="rowC">
                        <Col className={`${editData?.totalTax != getcurrectData?.totalTax  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">Total Tax:{formatAmountInINR(parseFloat(editData?.totalTax || 0))}</Box></Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_red' : '' }`}>Remarks : {editData.remarks}</Col>
                      </Row>
                      
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          Payment Information : 
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_red' : '' }`}>
                            <Box display="flex">
                            Requested Amount : {formatAmountInINR(editData?.paymentInfo?.approvalAmount)}
                            </Box>
                        </Col>
                      </Row>

                     <SpentAmountTable editSpentInfo={editSpentInfo} currentSpentInfo={currentSpentInfo} type="current" />
                 
                 
               

                    

                     {(role === "Manager" || role === "Admin") &&
                     <>
                      <Row className="rowC">
                        <h4
                          className="row-heading" 
                        >
                          Refund Information : 
                        </h4>
                      </Row>
                      <RefundAmountTable editRefundInfo={editRefundInfo} currentRefundInfo={currentRefundInfo} type="current"/>
                     </>
}

                  
                     
                      
                  
                  
                   
                </div>
              </Col>
              <Col className="row-col">
                <div className="row-table">
                <Row className="rowC">
                        <h4
                         className="row-heading"
                        >
                          Purchase Order Details :
                        </h4>
                      </Row>
                  <Row className="rowC">
                    <Col>
                      Order Month : {dateTimeFormat(getcurrectData.orderMonth)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                    <Col>
                      Order Date : {dateTimeFormat(getcurrectData.orderDate)}
                    </Col>
                  </Row>
                  <Row className="rowC">
                        <Col className={`${editData?.receiverName !== getcurrectData?.receiverName  ? 'data_compare_green' : '' }`}>Receiver Name: {getcurrectData?.receiverName}</Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.totalTax != getcurrectData?.totalTax  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">Total Tax:{formatAmountInINR(parseFloat(getcurrectData?.totalTax || 0))}</Box>

                        </Col>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData.remarks !== getcurrectData.remarks  ? 'data_compare_green' : '' }`}>Remarks : {getcurrectData.remarks}</Col>
                      </Row>
                      
                     
                  
                     
                      
                     
                      
                      <Row className="rowC">
                        <h4
                          className="row-heading"
                        >
                          Payment Information :
                        </h4>
                      </Row>
                      <Row className="rowC">
                        <Col className={`${editData?.paymentInfo?.approvalAmount != getcurrectData?.approvalAmount  ? 'data_compare_green' : '' }`}>
                        <Box display="flex">

                        Requested Amount : {formatAmountInINR(parseFloat(getcurrectData?.approvalAmount))}
                        </Box>
                        </Col>
                      </Row>
                
                    <SpentAmountTable editSpentInfo={editSpentInfo} currentSpentInfo={currentSpentInfo} type="edited" />
            
                    {(role === "Manager" || role === "Admin") &&
                    <>
                  <Row className="rowC">
                        <h4
                          className="row-heading" 
                        >
                          Refund Information : 
                        </h4>
                      </Row>
                      <RefundAmountTable editRefundInfo={editRefundInfo} currentRefundInfo={currentRefundInfo} type="edited"/>
                    </>
                    }
                </div>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="border-2 p-0 pt-4 pb-2">
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                isConfirmed();
                closeModal();
              }}
            >
              ok
            </Button>
            <Button
              type="submit"
              className="submit-btn"
              variant="brand"
              style={{ background: "#191063", minWidth: "100px" }}
              onClick={() => {
                closeModal();
              }}
            >
              cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default DirectTaxDataChangeModal;
