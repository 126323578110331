import { Box, Table, Tbody, Td, Th, Thead, Tr } from "@chakra-ui/react";
import dateTimeFormat from "jsFunctions/dateTimeFormat";
import { dateCompare } from "jsFunctions/dateTimeFormat";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import {  useEffect, useState } from "react";


const ReimbursementAmountTable = ({ editReimbursementInfo, currentReimbursementInfo, type }) => {

const [editReimbursementData,setEditReimbursementData] = useState([]);
const [currentReimbursementData,setCurrentReimbursementData] = useState([]);


useEffect(()=>{
    if(editReimbursementInfo){
       
      const remb = editReimbursementInfo?.reimbursementInfo;
      const obj = {
        invoiceDate:remb?.invoiceDate || Date?.now(),
        invoiceNumber:remb?.invoiceNum,
        isReimburs : remb?.isReimbursement?.isReimburs ? "true" : 'false',
        amount : remb?.isReimbursement?.amount || 0,
        amountInINR : remb?.isReimbursement?.amountInINR || 0,
        conversionRate: remb?.isReimbursement?.conversionRate,
        paymentDate: remb?.isReimbursement?.paymentDate,
        currency: remb?.isReimbursement?.currency


      }
      setEditReimbursementData([obj])
    }
    if(currentReimbursementInfo){


        const obj = {
          invoiceDate:currentReimbursementInfo?.invoiceDate,
          invoiceNumber:currentReimbursementInfo?.invoiceNum,
          isReimburs : currentReimbursementInfo?.isReimburs ? "true" : 'false',
          amount : currentReimbursementInfo?.reimburseAmount,
          amountInINR : currentReimbursementInfo?.amountInINR,
          conversionRate: currentReimbursementInfo.conversionRate,
          paymentDate: currentReimbursementInfo?.reimbursementPaymentDate,
          currency: currentReimbursementInfo?.currency

  
        }
        setCurrentReimbursementData([obj])
  }
  },[])

    return (

        type === 'current' ?
        <div className="info-table change-modal-table">

        <Table className="table-info">
          <Thead>
            <Tr>
              <Th>Invoice Date</Th>
              <Th>Invoice Number</Th>
              <Th>Amount</Th>
              <Th>Amount In INR</Th>
              <Th>Conversion Rate</Th>
              <Th>Currency</Th>




            </Tr>
          </Thead>
          <Tbody>
            {editReimbursementData?.map((item, index) => {
              return (
                <Tr>
                  <Td className={`${!dateCompare(dateTimeFormat(item?.invoiceDate) ,dateTimeFormat(currentReimbursementData[index]?.invoiceDate))  ? 'data_compare_red' : '' }`}>
                  {dateTimeFormat(
                            item?.invoiceDate
                          )}
                  </Td>
                  <Td className={`${item?.invoiceNumber != currentReimbursementData[index]?.invoiceNumber  ? 'data_compare_red' : '' }`}><Box>{item?.invoiceNumber}</Box></Td>

                  <Td className={`${item?.amount != currentReimbursementData[index]?.amount  ? 'data_compare_red' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.amount || 0))}</Box></Td>
                  <Td className={`${item?.amountInINR != currentReimbursementData[index]?.amountInINR  ? 'data_compare_red' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.amountInINR || 0))}</Box></Td>
                  <Td className={`${item?.conversionRate != currentReimbursementData[index]?.conversionRate  ? 'data_compare_red' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.conversionRate || 0))}</Box></Td>
                  <Td className={`${item?.currency != currentReimbursementData[index]?.currency  ? 'data_compare_red' : '' }`}><Box>{item?.currency }</Box></Td>


                </Tr> 

              );
            })}
          </Tbody>
        </Table>
      </div>
            :
            <div className="info-table change-modal-table">

                <Table className="table-info">
                    <Thead>
                        <Tr>
                        <Th>Invoice Date</Th>
              <Th>Invoice Number</Th>
              <Th>Amount</Th>
              <Th>Amount In INR</Th>
              <Th>Conversion Rate</Th>
              <Th>Currency</Th>


                        </Tr>
                    </Thead>
                    <Tbody>
                        {currentReimbursementData?.map((item, index) => {

                          return (
                            <Tr>
                              <Td className={`${!dateCompare(dateTimeFormat(item?.invoiceDate) ,dateTimeFormat(currentReimbursementData[index]?.invoiceDate))  ? 'data_compare_red' : '' }`}>
                  {dateTimeFormat(
                            item?.invoiceDate
                          )}
                  </Td>
                  <Td className={`${item?.invoiceNumber != editReimbursementData[index]?.invoiceNumber  ? 'data_compare_green' : '' }`}><Box>{item?.invoiceNumber}</Box></Td>

                  <Td className={`${item?.amount != editReimbursementData[index]?.amount  ? 'data_compare_green' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.amount || 0))}</Box></Td>
                  <Td className={`${item?.amountInINR != editReimbursementData[index]?.amountInINR  ? 'data_compare_green' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.amountInINR || 0))}</Box></Td>
                  <Td className={`${item?.conversionRate != editReimbursementData[index]?.conversionRate  ? 'data_compare_green' : '' }`}><Box>{formatAmountInINR( parseFloat(item?.conversionRate || 0))}</Box></Td>
                  <Td className={`${item?.currency != editReimbursementData[index]?.currency  ? 'data_compare_green' : '' }`}><Box>{item?.currency }</Box></Td>

                            </Tr> 

                          );
                        })}
                      </Tbody>
                    </Table>
                  </div>

    )


}

export default ReimbursementAmountTable;
