export const legacyDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Legacy  ID",
      accessor: "legacyId",
    },
    {
      Header: "Legacy  Name",
      accessor: "name",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
    },
    {
      Header: "Action",
      accessor: "action",
    },
  ];

  export const appliedLegacyDataColumns = [
    {
      Header: "No",
      accessor: "no",
    },
    {
      Header: "Legacy  ID",
      accessor: "legacyId",
    },
    {
      Header: "Created At",
      accessor: "createdAt",
    },
    {
      Header: "Legacy  Name",
      accessor: "name",
    },
    {
      Header: "Applied At",
      accessor: "appliedAt",
    },
    {
      Header: "Applied By",
      accessor: "appliedBy",
    },
    
  ];
  export const legacyItem = [
    {name:'Migrate Internal Invoices',value:'internal-invoice'},
    {name:'Migrate External Invoices',value:'external-invoice'},
    {name:'Migrate Resource Invoices',value:'resource-invoice'},
    {name:'Migrate TDS Invoices',value:'tds-invoice'},
    {name:'Migrate Direct Tax Invoices',value:'direct-tax-invoice'},
    {name:'Migrate Quotations',value:'quotations'},
    {name:'Migrate External Form Options',value:'migrate-external-form-options'},
    {name:'Migrate External PO Order Month',value:'migrate-external-po-ordermonth'},
    {name:'Migrate Internal Form Options',value:'migrate-internal-form-options'},
    {name:'Migrate Resource Form Options',value:'migrate-resource-form-options'},
    {name:'Migrate Tds Form Options',value:'migrate-tds-form-options'},
    {name:'Migrate Direct Tax Form Options',value:'migrate-direct-tax-form-options'},
    {name:'Migrate Gst PO Order Month',value:'migrate-gst-po-ordermonth'},

    
  ]