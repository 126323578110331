import React, { useState } from 'react';
import {
    Table,
    Tbody,
    Tr,
    Th,
    Td,
    Thead,
    IconButton,
    Box,
} from '@chakra-ui/react';
import { ViewIcon } from '@chakra-ui/icons';
import { FaEye, FaTrash } from 'react-icons/fa';
import Pagination from 'react-bootstrap/Pagination';
import { Divider } from 'rsuite';
import { formatAmountInINR } from 'jsFunctions/formatAmountInINR';
import moment from 'moment';

const QuotationTable = ({ quotations, handlePreview, handleDelete }) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 3; // Adjust items per page as needed

    // Calculate pagination details
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = quotations.slice(indexOfFirstItem, indexOfLastItem);
    const pageCount = Math.ceil(quotations.length / itemsPerPage);

    return (
        <>
            <div className="info-table">
                <Box overflowX="auto">
                    {currentItems.map((quotation, index) => (
                        <Box key={quotation._id || index} mb={4}>
                            <Table variant="striped">
                                <Thead>
                                    <Tr>
                                        <Th width={5}>No.</Th>
                                        <Th width={150}>Amount</Th>
                                        <Th width={450}>Remarks</Th>
                                        <Th width={160}>Created At</Th>
                                        <Th width={120}>Files</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    <Tr>
                                        <Td>{indexOfFirstItem + index + 1}</Td>
                                        <Td>{formatAmountInINR(quotation.amount || 0)}</Td>
                                        <Td>{quotation.remarks}</Td>
                                        <Td>{moment(quotation?.createdAt).format("DD-MMM-YYYY")}</Td>

                                        <Td>
                                            {quotation.files.length > 0 ? (
                                                quotation.files.map((file, fileIndex) => (
                                                    <Box
                                                        display="flex"
                                                        
                                                        justifyContent="space-between"
                                                        key={fileIndex}
                                                        style={{ display: 'flex', alignItems: 'center' }}
                                                    >
                                                        
                                                        <IconButton
                                                            aria-label="Preview"
                                                            icon={<span className='mx-3'>{fileIndex + 1}</span>}
                                                            onClick={() => handlePreview(file.key,file?.spId)}
                                                            // variant="outline"
                                                            size="sm"
                                                            margin={2}
                                                        />
                                                        <FaTrash
                                                            style={{
                                                                fontSize: '16px',
                                                                color: 'red',
                                                                cursor: 'pointer',
                                                            }}
                                                            onClick={() => handleDelete(file.key, quotation._id,file?.spId)}
                                                        />
                                                    </Box>
                                                ))
                                            ) : (
                                                <Box
                                                display="flex"
                                                
                                                justifyContent="space-between"
                                               
                                                style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                
                                                <IconButton
                                                    aria-label="Preview"
                                                    icon={<span className='mx-3'>{0}</span>}
                                                    size="sm"
                                                    margin={2}
                                                />
                                                <FaTrash
                                                    style={{
                                                        fontSize: '16px',
                                                        color: 'red',
                                                        cursor: 'pointer',
                                                    }}
                                                    onClick={() => handleDelete("", quotation._id)}
                                                />
                                            </Box>
                                            )}
                                        </Td>
                                    </Tr>
                                </Tbody>
                            </Table>
                            <Divider
                      className="info-divider mt-3"
                      orientation="horizontal"
                    />
                        </Box>
                    ))}
                </Box>

                {/* Pagination Component */}
                <Pagination className="justify-content-end" style={{ marginTop: '20px' }}>
                    <Pagination.Prev 
                        onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))} 
                        disabled={currentPage === 1} 
                    />
                    {Array.from({ length: pageCount }, (_, i) => (
                        <Pagination.Item
                            key={i + 1}
                            active={i + 1 === currentPage}
                            onClick={() => setCurrentPage(i + 1)}
                        >
                            {i + 1}
                        </Pagination.Item>
                    ))}
                    <Pagination.Next 
                        onClick={() => setCurrentPage((prev) => Math.min(prev + 1, pageCount))} 
                        disabled={currentPage === pageCount} 
                    />
                </Pagination>
            </div>
        </>
    );
};

export default QuotationTable;
