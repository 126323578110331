/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Box, Text, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Select, Checkbox, Spinner } from "@chakra-ui/react";
import { Avatar } from "rsuite";
import { FaUser, FaPhoneAlt, FaBuilding, FaCreditCard, FaRegBuilding, FaCity, FaRegAddressBook } from "react-icons/fa"; // Icons
import VendorBarChart from "./vendorBarChart";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";
import { fetchAsyncGetVendorFinancialExpense } from "redux/vendorSlice";
import { Form } from "react-bootstrap";

const VendorOverview = () => {

  const { id } = useParams();  
  const dispatch = useDispatch();
  const [vendorDetail, setVendorDetail] = useState({});

  const [revenueData,setRevenueData] = useState([0,0,0,0,0,0,0,0,0,0,0,0])
    
  const [loading, setLoading] = useState(true); // Loading state

  const fetchVendorDetails = async (vendorId) => {
    setLoading(true); 
    try {
      const res = await dispatch(fetchAsyncGetVendorByID(vendorId)); 
      if (res?.payload) {
        setVendorDetail(res.payload.vendor);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } finally {
      setLoading(false); // Stop loading
    }
  };


  const getVendorFinancialYearWiseExpense = (id,financialYear)=>{
    setLoading(true); 
    dispatch(
      fetchAsyncGetVendorFinancialExpense({
        vendorId: id,
        financialYear:financialYear
        }
      )
    ).then((res) => {
      if (res.payload) {
        const data = res.payload || [];
        const amountArray = data.map((item)=> item?.totalAmount)
        setRevenueData(amountArray)
      } else {
        console.log("error")
      }
    }).finally(() => {
      setLoading(false); // Stop loading
    });

  }

 

  const years = [
      { label: "2022-2023", value: "2022-2023" },
      { label: "2023-2024", value: "2023-2024" },
      { label: "2024-2025", value: "2024-2025" },
      { label: "2025-2026", value: "2025-2026" },
      { label: "2026-2027", value: "2026-2027" }

  ];

  const [selectedYear, setSelectedYear] = useState(years[2].value); 

  const getMonthsForYear = (year) => {
      const months = ["Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec", "Jan", "Feb", "Mar"];
      const yearStart = parseInt(year.split("-")[0]); 
      const yearEnd = parseInt(year.split("-")[1]);   

      let startMonthIndex, endMonthIndex, xAxisLabel;

     
      startMonthIndex = 0; 
      endMonthIndex = 11; 
      xAxisLabel = `${year.split("-")[0]} to ${year.split("-")[1]}`;
    
      const monthRange = months.slice(startMonthIndex, endMonthIndex + 1).map((month, index) => {
          const monthYear = index < 9 ? yearStart : yearEnd; 
          return `${month}-${monthYear}`;
      });

      return { monthRange, xAxisLabel };
  };

  const { monthRange, xAxisLabel } = getMonthsForYear(selectedYear);

  useEffect(() => {
    if (id) {
      fetchVendorDetails(id);
      getVendorFinancialYearWiseExpense(id,selectedYear)
    }
  }, [id]); 

  useEffect(()=>{
    if (id) {
      getVendorFinancialYearWiseExpense(id,selectedYear)
    }
  },[selectedYear])

  const chartOptions = {
      xaxis: {
          categories: monthRange, 
      },
  };

  

  // If loading, return a spinner
  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <Spinner size="xl" />
      </Box>
    );
  }
  return (
    <>
      <Box display="flex" className="mx-0 vendor_details">
        <Box className="overview_details_sidebar">
          <Box className="all-vendor mx-2">
            <Text className="all-vendor-text">{vendorDetail?.companyName}</Text>
          </Box>
          <Box display="flex" className="vendor-avtar mt-4 mx-0">
            <Avatar name="Sage Adebayo" shape="rounded" size="22" />
            <Box>

            <Text className="mx-3" fontWeight="600">
              {vendorDetail?.companyName}
            <Text color="#408DFB" fontSize="11px">Email: {vendorDetail?.email} </Text>
            </Text>
            </Box>
            
          </Box>
          <Box className="acordian-box mt-3">
            <Accordion defaultIndex={[0]} allowMultiple>
              <AccordionItem borderTop="none">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="600">Personal Details</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="acordian-panal px-0">
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Text color="#6C7184">Company Name </Text>
                    <Text className="mt-0 text2" fontWeight={500} color="#000">{vendorDetail?.companyName}</Text>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Text color="#6C7184">Contact Person </Text>
                    <Text className="mt-0 text2" fontWeight={500} color="#000000">{vendorDetail?.contactPerson}</Text>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Text color="#6C7184">Phone</Text>
                    <Text className="mt-0 text2" fontWeight={500} color="#000000">{vendorDetail?.phone}</Text>
                  </Box>
                
                </AccordionPanel>
              </AccordionItem>
              <AccordionItem borderTop="none">
                <h2>
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      <Text fontWeight="600">Tax Details</Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel className="acordian-panal px-0">
                  
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Text color="#6C7184">GST Number</Text>
                    <Text className="mt-0 text2" fontWeight={500} color="#000000">{vendorDetail?.gstNo}</Text>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mb={2}>
                    <Text color="#6C7184">PAN Number</Text>
                    <Text className="mt-0 text2" fontWeight={500} color="#000000">{vendorDetail?.panNo}</Text>
                  </Box>
                </AccordionPanel>
              </AccordionItem>

              <Text fontWeight="600" fontSize="13px" mt={3} mb={2}>Vendor Type</Text>
              <Box className="d-flex mt-2">

                      <Form.Check
                        type="checkbox"
                        name="isVendor"
                        label="Vendor"
                        checked={vendorDetail?.isVendor}
                      />
                   
                      <Form.Check
                        type="checkbox"
                        name="isConsultant"
                        className="mx-3"
                        label="Consultant"
                        checked={vendorDetail?.isConsultant}
                      />
                      </Box>

              <Text fontWeight="600" fontSize="14px" mt={8} mb={2}>Vendor Bank Details</Text>
              {vendorDetail?.bankDetails && vendorDetail?.bankDetails?.length > 0 && vendorDetail?.bankDetails?.map((item,i)=>{
                return (
                  <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <Box flex="1" textAlign="left">
                        <Text fontWeight="600">{item?.bankName}  Detail</Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel className="acordian-panal px-0">
                    
                    <Box display="flex" flexDirection="column" mb={3}>
                      
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">Account Holder Name </Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.accountHolder ? item?.accountHolder : vendorDetail?.companyName}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">Account Number </Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.accountNumber}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">IFSC Code </Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.ifscCode}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">Account Type </Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.accountType}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">Bank Name</Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.bankName}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">SWIFT Code</Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.swiftCode}</Text>
                        </Box>
                        <Box display="flex"   justifyContent="space-between" mb={3}>
                          <Text color="#6C7184">Branch Name</Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.branchName}</Text>
                        </Box>
                        <Box flex="1" mb={3} textAlign="left">
                          <Text fontWeight="600" color="#6C7184">Bank Address</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">City</Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.bankAddress?.city}</Text>
                        </Box>
                        <Box display="flex" justifyContent="space-between" mb={2}>
                          <Text color="#6C7184">State</Text>
                          <Text className="mt-0 text2" fontWeight={500} color="#000000">{item?.bankAddress?.state}</Text>
                        </Box>
                    
                    </Box>
  
                   
                    
                  </AccordionPanel>
                </AccordionItem>
                )
              })}
             
             
            </Accordion>
          </Box>
        </Box>

        <Box className="vendor_details_main mt-5 mx-5">
        <Box display="flex" justifyContent="space-between" alignItems="center">
                        <Text>
                            <strong>Amount</strong> - Total Payments Made to Vendors for This Financial Year
                        </Text>

                        <Select
                        className="graph-year-select"
                            width="auto"
                            value={selectedYear}
                            onChange={(e) => setSelectedYear(e.target.value)}
                        >
                            {years.map((year) => (
                                <option key={year.value} value={year.value}>
                                    {year.label}
                                </option>
                            ))}
                        </Select>
                    </Box>
              <Box mt={5} height={400}>
                <VendorBarChart chartData={[{name: "Revenue",data: revenueData,  }]} chartOptions={chartOptions} />
              </Box>
        </Box>
      </Box>
    </>
  );
};

export default VendorOverview;
