/* eslint-disable react-hooks/exhaustive-deps */
import React, {  useEffect, useState } from "react";

import { Box, Table, Tbody, Td, Text, Tr } from "@chakra-ui/react";


import { Tab, Tabs } from "react-bootstrap";
import VendorOverview from "./Overview";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { fetchAsyncGetVendorByID } from "redux/vendorSlice";
import { fetchAsyncGetAllVendorWithPayAmount } from "redux/vendorSlice";
import ExpenseDetails from "./expenseDetails";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";

const VendorDetails = () => {

  const { id } = useParams();  
  const dispatch = useDispatch();
  const [allVendors, setAllVendors] = useState([])

  const [vendorDetail, setVendorDetail] = useState({});

  const fetchVendorDetails = async (vendorId) => {
    try {
      const res = await dispatch(fetchAsyncGetVendorByID(vendorId)); 
      if (res?.payload) {
        setVendorDetail(res.payload.vendor);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } 
  };
  const fetchAllVendors = async () => {
    try {
      const res = await dispatch(fetchAsyncGetAllVendorWithPayAmount()); 
      if (res?.payload) {
        setAllVendors(res.payload);
      }
    } catch (error) {
      console.error("Error fetching vendor details:", error);
    } 
  };

  useEffect(()=>{
    fetchAllVendors();
  },[])

  useEffect(() => {
    if (id) {
      fetchVendorDetails(id);
    }
  }, [id]); 

  const navigate = useNavigate();

  const handleVendorClick = (e,_id)=>{
    e.stopPropagation();
   
    navigate(`/vendor/${_id}`);
  }
  return (
    <>
      <Box display="flex" className="mx-0 vendor_details">
        <Box className="vendor_details_sidebar">
          <Box className="all-vendor">

            <Text className="all-vendor-text">
              All Vendors
            </Text>

          </Box>
          <Box className="vendor-item">

            <Table className="vendor-table">
              <Tbody>

                {allVendors && allVendors?.length > 0 && allVendors?.map((item,i)=>{
                 return (
                    <Tr className="table-row" id={i + 1} onClick={(e)=>{handleVendorClick(e,item?._id)}}>
                    <Td className={`table-data ${item?._id === id ? 'active' : ''}`}>
                      <Box display="flex" >
                        <Box className="mt-0 mx-2">
                          <input type="checkbox" id="checkbox"/>

                        </Box>
                        <Box>
                          <Text fontSize="14px" fontWeight="500" color="#000">{item?.companyName}</Text>
                          <Text color="#4C526C">{formatAmountInINR(item?.totalPayoutAmount)}</Text>
                        </Box>
                      </Box>
                    </Td>
                  </Tr>
                 )
                 
                })}
               
               
              </Tbody>
            </Table>
          </Box>

        </Box>
        <Box className="vendor_details_main">

          <Box className="main_details-header">

            <Text
              color="black"
              fontSize="22px"
              fontWeight="500"
              mt={5}
              mx={3}
            >
              {vendorDetail?.companyName}
            </Text>
            <Box className="sub_tab_header">
              <div>
                <Tabs
                  defaultActiveKey="overview"
                  transition={false}
                  id="noanim-tab-example"
                  className="add-form-tabs px-2"
                >
                  <Tab
                    eventKey="overview"
                    title="Overview"
                    className="add-form-tab mx-0"
                  >
                    <Box>
                      <VendorOverview />
                    </Box>
                  </Tab>
                  <Tab
                    eventKey="transactions"
                    title="Transactions"
                    className="add-form-tab mx-0"
                  >

                    <ExpenseDetails/>
                  </Tab>
                </Tabs>
              </div>
            </Box>
          </Box>

        </Box>
      </Box>



    </>
  );
};

export default VendorDetails;
