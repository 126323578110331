export function calculateTotalGst(gstData) {
    let totalGst = 0;

    gstData.forEach(data => {
        const { gstAmount,gstType } = data;
        const igst = gstAmount?.igst || 0; 
        const cgst = gstAmount?.cgst || 0; 
        const sgst = gstAmount?.sgst || 0; 

        if (gstType === 'IGST') {
            totalGst += parseFloat(igst); 
        } else {
            totalGst += parseFloat(sgst) + parseFloat(cgst); 
        }
        
        
    });

    return totalGst;
}

export const getOptionDataOnEdit = (formOptionsList, editValue,editId, type) => {
    const data = formOptionsList.find(
      (item) => (item?._id === editId || item.value === editValue) && item.type === type
    );
    return data;
  };