import {
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Select,
  Table,
  TableCaption,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useEffect, useMemo, useState } from "react";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

// Custom components
import Card from "components/card/Card";
import Menu from "components/menu/MainMenu";
import AddNew from "components/allForm/addNew";
import InternalAddNew from "components/allForm/internalAddNew";
import { Col, Row } from "react-bootstrap";
import { formatAmountInINR } from "jsFunctions/formatAmountInINR";
import { IoSettingsOutline } from "react-icons/io5";
import { FaCheckCircle, FaDollarSign, FaHourglass, FaMoneyBill, FaSearch, FaTimesCircle } from "react-icons/fa";
import { FaMoneyBillTransfer } from "react-icons/fa6";

const ColumnTable = (props) => {
  const { columnsData, tableData, type,expenseType,chnageDataList } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const [pageIndex, setPageIndex] = useState(0); 
  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    initialState,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageSize },
  } = tableInstance;
  initialState.pageSize = 15;
  const textColor = useColorModeValue("secondaryGray.900", "white");
  const borderColor = useColorModeValue("gray.200", "whiteAlpha.100");

  const handleRowClick =(e,_id)=>{
    const selection = window.getSelection().toString(); // Check if any text is selected
  if (!selection) {
    ['internal', 'external', 'resource', 'tds', 'directTax', 'gst', 'users', 'client-project', 'vendors', 'embrill-profile'].includes(expenseType) && props?.openInfoModal(e, _id);
  }
    
  }
  

  const inputBg = useColorModeValue('gray', 'navy.900');
  const inputText = useColorModeValue('gray.700', 'gray.100');
  useEffect(() => {
    if (data.length === 0) {
      setPageIndex(0); 
    } else if (pageIndex >= pageCount) {
      setPageIndex(pageCount - 1); 
    }
  }, [data, pageCount]); 

  return (
    <>
     {['internal', 'external', 'resource', 'tds', 'directTax'].includes(expenseType) &&
     <Box
      
      variant="search"
      fontSize="sm"
      className="table-top-bar"
    >
    {/* <Text className="mx-3 my-1 table-summary">Expense Summary</Text> */}
    <Box className="mx-3 my-3">

    <Row>
     
      <Col>
        <Box display="flex" className="table-top-bar-item">
          <Box className="top-bar-icon">
          <FaMoneyBill style={{ color: 'blue' }}  />          
          </Box>
          <Box className="top-bar-text" >

           <Text>Total Spent Amount</Text>
           <Text className="Amount">{formatAmountInINR(props?.paymentData?.totalSpentAmount || 0)}</Text>
          </Box>

        </Box>
      </Col>
      <Col>
        <Box display="flex" className="table-top-bar-item">
          <Box className="top-bar-icon">
          <FaCheckCircle style={{ color: 'green' }}/>
          </Box>
          <Box className="top-bar-text" cursor="pointer" onClick={()=>chnageDataList('approved')}>

           <Text>Total Approved PR <span>({props?.paymentData?.totalApprovedPR ? props?.paymentData?.totalApprovedPR : 0})</span></Text>
           <Text className="Amount">{formatAmountInINR(props?.paymentData?.totalApprovalAmount || 0)}</Text>
           {/* <Text>{props?.paymentData?.totalApprovedPR ? props?.paymentData?.totalApprovedPR : 0}</Text> */}
          </Box>

        </Box>
      </Col>
      <Col>
        <Box display="flex" className="table-top-bar-item">
          <Box className="top-bar-icon">
          <FaHourglass style={{ color: '#000D55' }}/>
          </Box>
          <Box className="top-bar-text" cursor="pointer" onClick={()=>chnageDataList('pending')}>

           <Text>Total Pending PR <span>({props?.paymentData?.totalPendingPR ? props?.paymentData?.totalPendingPR :0})</span></Text>
           <Text className="Amount">{formatAmountInINR(props?.paymentData?.totalPendingAmount || 0)}</Text>
          </Box>

        </Box>
      </Col>
      <Col>
        <Box display="flex" className="table-top-bar-item">
          <Box className="top-bar-icon">
          <FaTimesCircle style={{ color: 'red' }}/>
          </Box>
          <Box className="top-bar-text" cursor="pointer"  onClick={()=>chnageDataList('rejected')}>

           <Text>Total Rejected PR <span>({props?.paymentData?.totalRejectedPR ? props?.paymentData?.totalRejectedPR:0})</span></Text>
           <Text className="Amount">{formatAmountInINR(props?.paymentData?.totalRejectedAmount || 0)}</Text>
          </Box>

        </Box>
      </Col>
      
     
    </Row>
    </Box>
    </Box>
     }

      <Card
        direction="column"
        w="100%"
        px="0px"
        p={0}
        mt={3}
        h={['internal', 'external', 'resource', 'tds', 'directTax'].includes(expenseType) ? '73vh' : '90vh'}
        justifyContent="space-between"
        overflowX={{ sm: "scroll", lg: "hidden" }}
      >
        <Table {...getTableProps()} variant="simple" className="embrill-table" color="gray.500" >
          <Thead>
            
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe="8px"
                    key={index}
                    borderColor={borderColor}
                  >
                    <Flex
                      align="center"
                      
                      
                    >
                      {column.render("Header")}
                      <span className="ms-2" style={{ width: "20px" }}>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? "🔽"
                            : "🔼"
                          : ""}
                      </span>
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index) => {
                    let data = "";

                    data = (
                      <Flex align="center">
                        <Text
                          me="10px"
                          
                        >
                          {cell.value}
                        </Text>
                      </Flex>
                    );
                    return (
                      <Td
                        {...cell.getCellProps()}
                        cursor="pointer"
                        key={index}
                        onClick={(e)=> {
                          if(['internal', 'external', 'resource', 'tds', 'directTax'].includes(expenseType)){

                            if(['Po No','Approved Amount','Spent Amount','Requested Amount'].includes(cell.column?.Header)){
                              if(!['Action','Projects','Invoice'].includes(cell.column?.Header)){

                                handleRowClick(e,row?.original?._id)
                              }
                            }
                          }
                          else{

                            if(['gst', 'users', 'client-project', 'vendors', 'embrill-profile'].includes(expenseType)){

                              if(!['Action','Projects','Invoice'].includes(cell.column?.Header)){

                                handleRowClick(e,row?.original?._id)
                              }
                            }
                          }

  
                        }}
                        className="embrill-table-data"
                      >
                        {data}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
          </Tbody>
        </Table>
        
      </Card>
      <Row>
          <Box display="flex" justifyContent="space-between" className="page-box mx-3">
          <Text className="total-count">Total Count : {data?.length}</Text>
          <Flex className="flex-page-item" align="self-start" justify="end">
          <Box className="w-14 select-page" display="flex">
            <IoSettingsOutline size={18} className="setting-icon"/>
              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {[10,15, 20, 25, 30].map((pageSize) => (
                  <option key={pageSize} value={pageSize}>
                  {pageSize} per page
                  </option>
                ))}
              </Select>
            </Box>
            <div className="w-15 page-icon">

            <Button  onClick={() => {setPageIndex(pageIndex - 1);previousPage()}} disabled={!canPreviousPage}>
              {"<"}
            </Button>
            {pageIndex + 1} - {pageOptions.length}
            <Button onClick={() => {setPageIndex(pageIndex + 1);nextPage()}} disabled={!canNextPage}>
              {">"}
            </Button>
            </div>
            
            
           
          </Flex>
          </Box>
        </Row>
    </>
  );
};

export default ColumnTable;
